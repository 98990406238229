import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";

export const getDynamicBarcodeProductAction = createAsyncThunk(
  "DynamicBarcodeProduct/GetAllDynamicBarcodeProduct",
  async (data) => {
    return apiCall("dynamicbarcode?approved=" + data.approved, "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {

            return {
              ...response.data,
            }
          } else {
            //  console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
        else {
          return Promise.reject({
            ...response.data.message,
          });
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const insertUpdateDynamicBarcodeProductAction = createAsyncThunk(
  "DynamicBarcodeProduct/insertUpdateDynamicBarcodeProduct",
  async (data) => {
    console.log("api action insert", data)

    return apiCall("dynamicbarcode/add", "POST", data, false)
      .then((response) => {
        console.log("api response insert update", response);
        if (response.status === 200) {
          if (!response.data.hasError) {
            console.log('response.data ', response.data.message)
            let message = response.data.message
            // console.log("message",message);
            return {
              data: {
                ...response.data,
              },
              message: {
                code: response.data.message.code,
                type: response.data.message.type,
                message: response.data.message.message
              },
              newRecord: data.id ? false : true
            }
          } else {
            console.log("response error", response.data.message);
            if (!response.data.message && response.data.errors && response.data.errors.length) {
              response.data.message = response.data.errors[0];
            }
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
        else {
          return Promise.reject({
            ...response.data,
          });
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getDynamicProductAction = createAsyncThunk(
  "DynamicBarcodeProduct/GetDynamicBarcode",
  async (data) => {
    return apiCall("dynamicbarcode/getbarcode", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const activeInactiveAction = createAsyncThunk(
  "DynamicBarcodeProduct/ActiveInActive",
  async (data) => {
    return apiCall("dynamicbarcode/activeinactive", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {

            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getDynamicBarcodeComparisonAction = createAsyncThunk(
  "DynamicBarcodeProduct/GetProductComparison",
  async (data) => {
    return apiCall("dynamicbarcode/getbarcodecomparison?id=" + data.id, "POST", null, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const dynamicBarcodeApproveAction = createAsyncThunk(
  "DynamicBarcodeProduct/Approve",
  async (data) => {
    return apiCall("dynamicbarcode/approve?id=" + data.id, "POST", null, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const dynamicBarcodeRejectAction = createAsyncThunk(
  "DynamicBarcodeProduct/Reject",
  async (data) => {
    return apiCall("dynamicbarcode/reject?id=" + data.id, "POST", null, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);