import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';


import {
  getAllSupplierAction,
  insertUpdateSupplierAction,
  getSupplierAction,
  activeInactiveAction,
  getSupplierComparisonAction,
  supplierApproveAction,
  supplierRejectAction
} from "../../actions/supplier/supplierActions";

// import { toast } from 'react-toastify';

const INITIAL_STATE = {
  supplier: {
    data: {
      records: [],
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  insertUpdateDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
    newRecord: false
  },
  selectedSupplier: {
    data: null,
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  activeInactiveDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
    newRecord: false
  },
  selectedSupplierComparsion: {
    data: {
      records: null,
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  approveDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    }
  },
  rejectDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    }
  }

};

const supplierReducer = createSlice({
  name: "Supplier",
  initialState: INITIAL_STATE,
  reducers: {
    RESET(state, action) {
      state = INITIAL_STATE;
    },
    ResetSupplierDetailsState(state, action) {
      state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
      state.selectedSupplier = INITIAL_STATE.selectedSupplier;
      state.approveDetails = INITIAL_STATE.approveDetails;
      state.rejectDetails = INITIAL_STATE.rejectDetails;
    },
  },
  extraReducers: (builder) => {

    //  -------- getAll Supplier Action start--------------------

    builder.addCase(getAllSupplierAction.fulfilled, (state, action) => {
      // console.log("getAllSupplierAction fulfilled", action);

      state.supplier.data.records = action.payload.data;
      state.supplier.apiMsg.status = action.meta.requestStatus;
      state.supplier.apiMsg.message = action.payload.message.message;
    });
    builder.addCase(getAllSupplierAction.pending, (state, action) => {
      // console.log("getAllSupplierAction pending", action);
      state.supplier.data.records = null;
      state.supplier.apiMsg.message = action.meta.requestStatus;;
      state.supplier.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getAllSupplierAction.rejected, (state, action) => {
      // console.log("getAllSupplierAction rejected", action);
      state.supplier.apiMsg.message = action.error.message;
      state.supplier.apiMsg.status = action.meta.requestStatus;
    });



    //  -------- getAll Supplier Action end--------------------


    // insert update Supplier Action start

    builder.addCase(
      insertUpdateSupplierAction.fulfilled,
      (state, action) => {
        // console.log("insertUpdateSupplierAction", action);

        state.insertUpdateDetails.data = action.payload.data.data;
        state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
        state.insertUpdateDetails.apiMsg.message = action.payload.message.message;

        if (!action.payload.newRecord) {
          let updatedRecord = state.supplier.data.records.map(x => {
            if (x.id == action.payload.data.data.id) {
              return action.payload.data.data;
            }
            else {
              return x;
            }
          })

          state.supplier.data.records = updatedRecord;
        }
        else {
          if (state.supplier.data.records.length) {
            state.supplier.data.records.unshift(action.payload.data.data);
          }
          else {
            state.supplier.data.records.push(action.payload.data.data);
          }
        }
        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });

      }
    );
    builder.addCase(
      insertUpdateSupplierAction.pending,
      (state, action) => {
        //console.log("insertUpdatesupplierAction pending", action);
        state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      insertUpdateSupplierAction.rejected,
      (state, action) => {
        //   console.log("insertUpdatesupplierAction rejected", action);
        //   console.log(action.error.message);
        state.insertUpdateDetails.apiMsg.message = action.error.message;
        state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });
      }
    );


    //   insert update supplier action end



    //------------------------------- getsupplier start -------------------------

    builder.addCase(getSupplierAction.fulfilled, (state, action) => {
      state.selectedSupplier.data = action.payload.data;
      state.selectedSupplier.apiMsg.status = action.meta.requestStatus;
      state.selectedSupplier.apiMsg.message = action.payload.message.message;
    });

    builder.addCase(getSupplierAction.pending, (state, action) => {
      //console.log("getPosUser pending", action);
      state.selectedSupplier.data = null;
      state.selectedSupplier.apiMsg.message = action.meta.requestStatus;;
      state.selectedSupplier.apiMsg.status = action.meta.requestStatus;
    });

    builder.addCase(getSupplierAction.rejected, (state, action) => {
      // console.log("getPosUser rejected", action);
      state.selectedSupplier.apiMsg.message = action.error.message;
      state.selectedSupplier.apiMsg.status = action.meta.requestStatus;
    });


    //   get supplier end

    //------------------------------- activeInactiveAction start -------------------------

    builder.addCase(
      activeInactiveAction.fulfilled,
      (state, action) => {
        //console.log("activeInactiveAction", action);

        state.activeInactiveDetails.data = action.payload.data.data;
        state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
        state.activeInactiveDetails.apiMsg.message = action.payload.message.message;

        let updatedRecord = state.supplier.data.records.map(x => {
          if (x.id == action.meta.arg.id) {
            x.isActive = action.meta.arg.isActive;
            return x;
          }
          else {
            return x;
          }
        })



        state.supplier.data.records = updatedRecord;

        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "success"
        });

      }
    );
    builder.addCase(
      activeInactiveAction.pending,
      (state, action) => {
        state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      activeInactiveAction.rejected,
      (state, action) => {
        state.activeInactiveDetails.apiMsg.message = action.error.message;
        state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "error"
        });
      }
    );

    //------------------------------- activeInactiveAction end -------------------------

    //------------ getSupplierComparisonAction -----------

    builder.addCase(getSupplierComparisonAction.fulfilled, (state, action) => {
      state.selectedSupplierComparsion.data.records = action.payload.data;
      state.selectedSupplierComparsion.apiMsg.status = action.meta.requestStatus;
      state.selectedSupplierComparsion.apiMsg.message = action.payload.message.message;
    });

    builder.addCase(getSupplierComparisonAction.pending, (state, action) => {
      //console.log("getSupplierComparisonAction pending", action);
      state.selectedSupplierComparsion.data.records = null;
      state.selectedSupplierComparsion.apiMsg.message = action.meta.requestStatus;;
      state.selectedSupplierComparsion.apiMsg.status = action.meta.requestStatus;
    });

    builder.addCase(getSupplierComparisonAction.rejected, (state, action) => {
      // console.log("getManualPriceProductAction rejected", action);
      state.selectedSupplierComparsion.apiMsg.message = action.error.message;
      state.selectedSupplierComparsion.apiMsg.status = action.meta.requestStatus;
    });


    //    getSupplierComparisonAction end



    //------------------------------- supplierApproveAction start -------------------------

    builder.addCase(
      supplierApproveAction.fulfilled,
      (state, action) => {

        state.approveDetails.data = action.payload.data.data;
        state.approveDetails.apiMsg.status = action.meta.requestStatus;
        state.approveDetails.apiMsg.message = action.payload.message.message;

        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "success"
        });

      }
    );
    builder.addCase(
      supplierApproveAction.pending,
      (state, action) => {
        state.approveDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      supplierApproveAction.rejected,
      (state, action) => {
        state.approveDetails.apiMsg.message = action.error.message;
        state.approveDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "error"
        });
      }
    );

    //------------------------------- supplierApproveAction end -------------------------

    //------------------------------- supplierRejectAction start -------------------------

    builder.addCase(
      supplierRejectAction.fulfilled,
      (state, action) => {

        state.rejectDetails.data = action.payload.data.data;
        state.rejectDetails.apiMsg.status = action.meta.requestStatus;
        state.rejectDetails.apiMsg.message = action.payload.message.message;

        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "success"
        });

      }
    );
    builder.addCase(
      supplierRejectAction.pending,
      (state, action) => {
        state.rejectDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      supplierRejectAction.rejected,
      (state, action) => {
        state.rejectDetails.apiMsg.message = action.error.message;
        state.rejectDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "error"
        });
      }
    );

    //------------------------------- supplierRejectAction end -------------------------

  },
});
export const { RESET, ResetSupplierDetailsState } = supplierReducer.actions;
export default supplierReducer.reducer;

