import { createSlice } from "@reduxjs/toolkit";

import {
  getReducedPriceProductAction,
  insertUpdateReducedPriceProductAction,
  getReducedPriceAction,
  deleteAction,
  activeInactiveAction,
  getReducedPriceProductComparisonAction,
  reducedPriceProductApproveAction,
  reducedPriceProductRejectAction
} from "../../actions/inventory/reducedPriceProductActions";

import { toast } from 'react-toastify';

const INITIAL_STATE = {
  reducedPriceProduct: {
    data: {
      records: [],
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  insertUpdateDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
    newRecord: false
  },

  selectedReducedPriceProduct: {
    data: null,
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },

  activeInactiveDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
    newRecord: false
  },
  selectedReducedPriceProductComparsion: {
    data: {
      records: null,
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  approveDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    }
  },
  rejectDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    }
  }

}

const reducedPriceProductReducer = createSlice({
  name: "ReducedPriceProduct",
  initialState: INITIAL_STATE,
  reducers: {
    RESET(state, action) {
      state = INITIAL_STATE;
    },
    ResetReducedPriceProductDetailsState(state, action) {
      state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
      state.selectedReducedPriceProduct = INITIAL_STATE.selectedReducedPriceProduct;
      state.approveDetails = INITIAL_STATE.approveDetails;
      state.rejectDetails = INITIAL_STATE.rejectDetails;

    },
  },
  extraReducers: (builder) => {

    builder.addCase(getReducedPriceProductAction.fulfilled, (state, action) => {
      state.reducedPriceProduct.data.records = action.payload.data;
      state.reducedPriceProduct.apiMsg.status = action.meta.requestStatus;
      state.reducedPriceProduct.apiMsg.message = action.payload.message.message;
    });
    builder.addCase(getReducedPriceProductAction.pending, (state, action) => {
      //console.log("getReducedPriceProductAction pending", action);
      state.reducedPriceProduct.data.records = null;
      state.reducedPriceProduct.apiMsg.message = action.meta.requestStatus;;
      state.reducedPriceProduct.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getReducedPriceProductAction.rejected, (state, action) => {
      //console.log("getReducedPriceProductAction rejected", action);
      state.reducedPriceProduct.apiMsg.message = action.error.message;
      state.reducedPriceProduct.apiMsg.status = action.meta.requestStatus;
    });




    // insert update reducedPriceProduct Action start

    builder.addCase(
      insertUpdateReducedPriceProductAction.fulfilled,
      (state, action) => {
        //console.log("insertUpdateReducedPriceProductAction", action);

        state.insertUpdateDetails.data = action.payload.data.data;
        state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
        state.insertUpdateDetails.apiMsg.message = action.payload.message.message;

        if (!action.payload.newRecord) {
          let updatedRecord = state.reducedPriceProduct.data.records.map(x => {
            if (x.id == action.payload.data.data.id) {
              return action.payload.data.data;
            }
            else {
              return x;
            }
          })

          state.reducedPriceProduct.data.records = updatedRecord;
        }
        else {
          if (state.reducedPriceProduct.data.records.length) {
            state.reducedPriceProduct.data.records.unshift(action.payload.data.data);
          }
          else {
            state.reducedPriceProduct.data.records.push(action.payload.data.data);
          }
        }
        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });

      }
    );
    builder.addCase(
      insertUpdateReducedPriceProductAction.pending,
      (state, action) => {
        //console.log("insertUpdateReducedPriceProductAction pending", action);
        state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      insertUpdateReducedPriceProductAction.rejected,
      (state, action) => {
        //   console.log("insertUpdateReducedPriceProductAction rejected", action);
        //   console.log(action.error.message);
        state.insertUpdateDetails.apiMsg.message = action.error.message;
        state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });
      }
    );


    //   insert update reducedPriceProduct action end

    //------------------------------- getReducedPriceAction start -------------------------

    builder.addCase(getReducedPriceAction.fulfilled, (state, action) => {
      state.selectedReducedPriceProduct.data = action.payload.data;
      state.selectedReducedPriceProduct.apiMsg.status = action.meta.requestStatus;
      state.selectedReducedPriceProduct.apiMsg.message = action.payload.message.message;
    });

    builder.addCase(getReducedPriceAction.pending, (state, action) => {
      //console.log("getReducedPriceAction pending", action);
      state.selectedReducedPriceProduct.data = null;
      state.selectedReducedPriceProduct.apiMsg.message = action.meta.requestStatus;;
      state.selectedReducedPriceProduct.apiMsg.status = action.meta.requestStatus;
    });

    builder.addCase(getReducedPriceAction.rejected, (state, action) => {
      // console.log("getReducedPriceAction rejected", action);
      state.selectedReducedPriceProduct.apiMsg.message = action.error.message;
      state.selectedReducedPriceProduct.apiMsg.status = action.meta.requestStatus;
    });


    //   getReducedPriceAction end



    //------------------------------- deleteAction start -------------------------

    builder.addCase(
      deleteAction.fulfilled,
      (state, action) => {
        //console.log("deleteAction",action);
        state.reducedPriceProduct.data.records = state.reducedPriceProduct.data.records.filter((item) => item.id !== action.meta.arg.id);
        toast.success("Record deleted successfully", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "msg-success"
        });

      }
    );

    builder.addCase(
      deleteAction.rejected,
      (state, action) => {
        toast.error((action.error.message ? action.error.message : "Something went wrong please try again"), {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "msg-error"
        });
      }
    );



    //------------------------------- activeInactiveAction start -------------------------

    builder.addCase(
      activeInactiveAction.fulfilled,
      (state, action) => {
        console.log("activeInactiveAction", action);

        state.activeInactiveDetails.data = action.payload.data.data;
        state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
        state.activeInactiveDetails.apiMsg.message = action.payload.message.message;

        let updatedRecord = state.reducedPriceProduct.data.records.map(x => {
          if (x.id == action.meta.arg.id) {
            x.isActive = action.meta.arg.isActive;
            return x;
          }
          else {
            return x;
          }
        })



        state.reducedPriceProduct.data.records = updatedRecord;

        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "success"
        });

      }
    );
    builder.addCase(
      activeInactiveAction.pending,
      (state, action) => {
        state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      activeInactiveAction.rejected,
      (state, action) => {
        state.activeInactiveDetails.apiMsg.message = action.error.message;
        state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "error"
        });
      }
    );

    //------------------------------- activeInactiveAction end -------------------------

    //------------ getReducedPriceProductComparisonAction -----------

    builder.addCase(getReducedPriceProductComparisonAction.fulfilled, (state, action) => {
      state.selectedReducedPriceProductComparsion.data.records = action.payload.data;
      state.selectedReducedPriceProductComparsion.apiMsg.status = action.meta.requestStatus;
      state.selectedReducedPriceProductComparsion.apiMsg.message = action.payload.message.message;
    });

    builder.addCase(getReducedPriceProductComparisonAction.pending, (state, action) => {
      state.selectedReducedPriceProductComparsion.data.records = null;
      state.selectedReducedPriceProductComparsion.apiMsg.message = action.meta.requestStatus;;
      state.selectedReducedPriceProductComparsion.apiMsg.status = action.meta.requestStatus;
    });

    builder.addCase(getReducedPriceProductComparisonAction.rejected, (state, action) => {
      state.selectedReducedPriceProductComparsion.apiMsg.message = action.error.message;
      state.selectedReducedPriceProductComparsion.apiMsg.status = action.meta.requestStatus;
    });


    //    selectedReducedPriceProductComparsion end



    //------------------------------- reducedPriceProductApproveAction start -------------------------

    builder.addCase(
      reducedPriceProductApproveAction.fulfilled,
      (state, action) => {

        state.approveDetails.data = action.payload.data.data;
        state.approveDetails.apiMsg.status = action.meta.requestStatus;
        state.approveDetails.apiMsg.message = action.payload.message.message;

        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "success"
        });

      }
    );
    builder.addCase(
      reducedPriceProductApproveAction.pending,
      (state, action) => {
        state.approveDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      reducedPriceProductApproveAction.rejected,
      (state, action) => {
        state.approveDetails.apiMsg.message = action.error.message;
        state.approveDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "error"
        });
      }
    );

    //------------------------------- reducedPriceProductApproveAction end -------------------------

    //------------------------------- reducedPriceProductRejectAction start -------------------------

    builder.addCase(
      reducedPriceProductRejectAction.fulfilled,
      (state, action) => {

        state.rejectDetails.data = action.payload.data.data;
        state.rejectDetails.apiMsg.status = action.meta.requestStatus;
        state.rejectDetails.apiMsg.message = action.payload.message.message;

        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "success"
        });

      }
    );
    builder.addCase(
      reducedPriceProductRejectAction.pending,
      (state, action) => {
        state.rejectDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      reducedPriceProductRejectAction.rejected,
      (state, action) => {
        state.rejectDetails.apiMsg.message = action.error.message;
        state.rejectDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "error"
        });
      }
    );

    //------------------------------- reducedPriceProductRejectAction end -------------------------





  },
});
export const { RESET, ResetReducedPriceProductDetailsState } = reducedPriceProductReducer.actions;
export default reducedPriceProductReducer.reducer;

