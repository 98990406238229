import { createSlice } from "@reduxjs/toolkit";
import {
  getAllProductGroupAction,
  insertUpdateProductGroupAction,
  deleteAction
} from "../../actions/inventory/productGroupActions";
import { toast } from 'react-toastify';
const INITIAL_STATE = {
  productGroup: {
    data: {
      records: [],
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  productGroupDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
    newRecord:false
  },
};

const productGroupReducer = createSlice({
  name: "Brand",
  initialState: INITIAL_STATE,
  reducers: {

    RESET(state, action) {
      state = INITIAL_STATE;
    },
    ResetProductGroupDetailsState(state, action) {
      state.productGroupDetails = INITIAL_STATE.productGroupDetails;
    },
  },
  extraReducers: (builder) => {
    //------------------------------- getAllProductGroupAction start -------------------------
    // eslint-disable-next-line
    builder.addCase(getAllProductGroupAction.fulfilled, (state, action) => {
      //console.log("getAllProductGroupAction", action);
      state.productGroup.data.records = action.payload.data;
      state.productGroup.apiMsg.status = action.meta.requestStatus;
      state.productGroup.apiMsg.message = action.payload.message.message;
    });
      builder.addCase(getAllProductGroupAction.pending, (state, action) => {
        //console.log("getAllProductGroupAction pending", action);
        state.productGroup.data.records = null;
        state.productGroup.apiMsg.message = action.meta.requestStatus;;
        state.productGroup.apiMsg.status = action.meta.requestStatus;
      });
      builder.addCase(getAllProductGroupAction.rejected, (state, action) => {
        console.log("getAllProductGroupAction rejected", action);
        state.productGroup.apiMsg.message = action.error.message;
        state.productGroup.apiMsg.status = action.meta.requestStatus;
      });
      //------------------------------- getAllProductGroupAction end -------------------------

      //------------------------------- insertUpdateProductGroupAction start -------------------------

      builder.addCase(
        insertUpdateProductGroupAction.fulfilled,
        (state, action) => {
          //console.log("insertUpdateProductGroupAction", action);

          state.productGroupDetails.data = action.payload.data.data;
          state.productGroupDetails.apiMsg.status = action.meta.requestStatus;
          state.productGroupDetails.apiMsg.message = action.payload.message.message;
          
          if(!action.payload.newRecord){
            let updatedRecord = state.productGroup.data.records.map(x => {
              if(x.id==action.payload.data.data.id){
                return action.payload.data.data;
              }
              else{
                return x;
              }
            })

            state.productGroup.data.records=updatedRecord;
          }
          else{
            if(state.productGroup.data.records.length){
              state.productGroup.data.records.unshift(action.payload.data.data);
            }
            else{
              state.productGroup.data.records.push(action.payload.data.data);
            }
          }
          toast.success(action.payload.message.message, {
            position: toast.POSITION.BOTTOM_CENTER,
            toastId: "insertUpdateProductGroupAction-error"
          });

        }
      );
      builder.addCase(
        insertUpdateProductGroupAction.pending,
        (state, action) => {
          //console.log("insertUpdateProductGroupAction pending", action);
          state.productGroupDetails.apiMsg.status =action.meta.requestStatus;
        }
      );
      builder.addCase(
        insertUpdateProductGroupAction.rejected,
        (state, action) => {
          console.log("insertUpdateProductGroupAction rejected", action);
          console.log(action.error.message);
          state.productGroupDetails.apiMsg.message = action.error.message;
          state.productGroupDetails.apiMsg.status = action.meta.requestStatus;

          toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
            position: toast.POSITION.BOTTOM_CENTER,
            toastId: "insertUpdateProductGroupAction-error"
          });
        }
      );

    //------------------------------- insertUpdateProductGroupAction end -------------------------
     //------------------------------- deleteAction start -------------------------

     builder.addCase(
      deleteAction.fulfilled,
      (state, action) => {
        //console.log("deleteAction",action);
        state.productGroup.data.records = state.productGroup.data.records.filter((item) => item.id !== action.meta.arg.id);
        toast.success("Record deleted successfully", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "msg-success"
        });

      }
    );

    builder.addCase(
      deleteAction.rejected,
      (state, action) => {
        console.log("deleteAction rejected", action);
        console.log(action.error.message);
        toast.error((action.error.message ? action.error.message : "Something went wrong please try again"), {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "msg-error"
        });
      }
    );

  },
});

export const { RESET,ResetProductGroupDetailsState } = productGroupReducer.actions;
export default productGroupReducer.reducer;
