import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';

import { getAllStockReturnToSupplierAction, getAllActiveBusinessData, insertUpdateStockReturnToSupplierAction, getStockReturnTosupplierAction, getStockReturnTosupplierByBarcodeAction } from "../../actions/stock-out-manage/stockReturnToSupplierAction";

const INITIAL_STATE = {

    stockReturnToSupplier: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    businessList: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    insertUpdateDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    selectedStockReturnToSupplier: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    stockReturnToSupplierByBarcode: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

};



const stockReturnToSupplierReducer = createSlice({
    name: "StockReturnToSupplier",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetStockDetailsState(state, action) {
            state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
            state.stockReturnToSupplierByBarcode = INITIAL_STATE.stockReturnToSupplierByBarcode;
        },
    },

    extraReducers: (builder) => {

        // get all getAllStockReturnToSupplierAction action start
        builder.addCase(getAllStockReturnToSupplierAction.fulfilled, (state, action) => {
            //console.log("getAllStockReturnToSupplierAction", action);
            state.stockReturnToSupplier.data.records = action.payload.data;
            state.stockReturnToSupplier.apiMsg.status = action.meta.requestStatus;
            state.stockReturnToSupplier.apiMsg.message = "success";
        });
        builder.addCase(getAllStockReturnToSupplierAction.pending, (state, action) => {
            // console.log("getAllStockReturnToSupplierAction pending", action);
            state.stockReturnToSupplier.data.records = null;
            state.stockReturnToSupplier.apiMsg.message = action.meta.requestStatus;
            state.stockReturnToSupplier.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllStockReturnToSupplierAction.rejected, (state, action) => {
            // console.log("getAllStockReturnToSupplierAction rejected", action);
            state.stockReturnToSupplier.apiMsg.message = action.error.message;
            state.stockReturnToSupplier.apiMsg.status = action.meta.requestStatus;
        });

        // get all getAllStockReturnToSupplierAction action end




        //------------------------------- getbusinesss start -------------------------

        builder.addCase(getAllActiveBusinessData.fulfilled, (state, action) => {
            // console.log("action", action)
            state.businessList.data = action.payload.data;
            // console.log("state.selectedBusiness.data",state.selectedBusiness.data)
            state.businessList.apiMsg.status = action.meta.requestStatus;
            state.businessList.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getAllActiveBusinessData.pending, (state, action) => {
            //console.log("getPosUser pending", action);
            state.businessList.data = null;
            state.businessList.apiMsg.message = action.meta.requestStatus;;
            state.businessList.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getAllActiveBusinessData.rejected, (state, action) => {
            // console.log("getPosUser rejected", action);
            state.businessList.apiMsg.message = action.error.message;
            state.businessList.apiMsg.status = action.meta.requestStatus;
        });


        //   get business end



        // insert update insertUpdateStockReturnToSupplierAction Action start

        builder.addCase(
            insertUpdateStockReturnToSupplierAction.fulfilled,
            (state, action) => {
                // console.log("insertUpdateStockReturnToSupplierAction", action);

                state.insertUpdateDetails.data = action.payload.data.data;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateDetails.apiMsg.message = action.payload.message.message;
                if (!action.payload.data.data.isDraft) {
                    if (state.stockReturnToSupplier.data.records.length) {
                        var deletedIndex = state.stockReturnToSupplier.data.records.findIndex(x => x.id === action.payload.data.data.id);
                        state.stockReturnToSupplier.data.records.splice(deletedIndex, 1);
                    }
                }
                else if (!action.payload.newRecord) {
                    let updatedRecord = state.stockReturnToSupplier.data.records.map(x => {
                        if (x.id == action.payload.data.data.id) {
                            return action.payload.data.data;
                        }
                        else {
                            return x;
                        }
                    })

                    state.stockReturnToSupplier.data.records = updatedRecord;
                }
                else {
                    if (state.stockReturnToSupplier.data.records.length) {
                        state.stockReturnToSupplier.data.records.unshift(action.payload.data.data);
                    }
                    else {
                        state.stockReturnToSupplier.data.records.push(action.payload.data.data);
                    }
                }
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });

            }
        );
        builder.addCase(
            insertUpdateStockReturnToSupplierAction.pending,
            (state, action) => {
                //console.log("insertUpdateStockReturnToSupplierAction pending", action);
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateStockReturnToSupplierAction.rejected,
            (state, action) => {
                //   console.log("insertUpdateStockReturnToSupplierAction rejected", action);
                console.log(action.error.message);
                state.insertUpdateDetails.apiMsg.message = action.error.message;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );


        //   insert update insertUpdateStockReturnToSupplierAction action end





        //------------ getStockReturnTosupplierAction -----------

        builder.addCase(getStockReturnTosupplierAction.fulfilled, (state, action) => {

            state.selectedStockReturnToSupplier.data = action.payload.data;
            state.selectedStockReturnToSupplier.apiMsg.status = action.meta.requestStatus;
            state.selectedStockReturnToSupplier.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getStockReturnTosupplierAction.pending, (state, action) => {
            //console.log("getStockReturnTosupplierAction pending", action);
            state.selectedStockReturnToSupplier.data = null;
            state.selectedStockReturnToSupplier.apiMsg.message = action.meta.requestStatus;;
            state.selectedStockReturnToSupplier.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getStockReturnTosupplierAction.rejected, (state, action) => {
            // console.log("getStockReturnTosupplierAction rejected", action);
            state.selectedStockReturnToSupplier.apiMsg.message = action.error.message;
            state.selectedStockReturnToSupplier.apiMsg.status = action.meta.requestStatus;
        });


        //   get manualPriceProduct end

        //   getStockReturnTosupplierByBarcodeAction start

        builder.addCase(getStockReturnTosupplierByBarcodeAction.fulfilled, (state, action) => {
          
            
            state.stockReturnToSupplierByBarcode.data = action.payload.data;
            state.stockReturnToSupplierByBarcode.apiMsg.status = action.meta.requestStatus;
            state.stockReturnToSupplierByBarcode.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getStockReturnTosupplierByBarcodeAction.pending, (state, action) => {
            //console.log("getStockReturnTosupplierAction pending", action);
            state.stockReturnToSupplierByBarcode.data = null;
            state.stockReturnToSupplierByBarcode.apiMsg.message = action.meta.requestStatus;;
            state.stockReturnToSupplierByBarcode.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getStockReturnTosupplierByBarcodeAction.rejected, (state, action) => {
            // console.log("getStockReturnTosupplierAction rejected", action);
            state.stockReturnToSupplierByBarcode.apiMsg.message = action.error.message;
            state.stockReturnToSupplierByBarcode.apiMsg.status = action.meta.requestStatus;
        });

        //   getStockReturnTosupplierByBarcodeAction end


    },
})
export const { RESET, ResetStockDetailsState } = stockReturnToSupplierReducer.actions;
export default stockReturnToSupplierReducer.reducer;