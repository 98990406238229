import { createSlice } from "@reduxjs/toolkit";

import {
    getCustomerAction,insertUpdateCustomerAction
} from "../../actions/customer/customerActions";

import { toast } from 'react-toastify';

const INITIAL_STATE = {
    customer: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    insertUpdateDetails: {
        data: {},
        apiMsg: {
          message: null,
          status: null,
          type: null,
        },
        newRecord: false
      },
};

const customerReducer = createSlice({
    name: "Customer",
    initialState: INITIAL_STATE,
    reducers: {
          RESET(state, action) {
            state = INITIAL_STATE;
          },
          ResetCustomerDetailsState(state, action) {
            state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
          },
    },
    extraReducers: (builder) => {

        builder.addCase(getCustomerAction.fulfilled, (state, action) => {
            state.customer.data.records = action.payload.data;
            state.customer.apiMsg.status = action.meta.requestStatus;
            state.customer.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getCustomerAction.pending, (state, action) => {
            //console.log("getCustomerAction pending", action);
            state.customer.data.records = null;
            state.customer.apiMsg.message = action.meta.requestStatus;;
            state.customer.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getCustomerAction.rejected, (state, action) => {
            //console.log("getCustomerAction rejected", action);
            state.customer.apiMsg.message = action.error.message;
            state.customer.apiMsg.status = action.meta.requestStatus;
        });


        // insert update insertUpdateCustomerAction Action start

        builder.addCase(
            insertUpdateCustomerAction.fulfilled,
            (state, action) => {
            //console.log("insertUpdateCustomerAction", action);
      
              state.insertUpdateDetails.data = action.payload.data.data;
              state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
              state.insertUpdateDetails.apiMsg.message = action.payload.message.message;
      
              if (!action.payload.newRecord) {
                let updatedRecord = state.customer.data.records.map(x => {
                  if (x.id == action.payload.data.data.id) {
                    return action.payload.data.data;
                  }
                  else {
                    return x;
                  }
                })
      
                state.customer.data.records = updatedRecord;
              }
              else {
                if (state.customer.data.records.length) {
                  state.customer.data.records.unshift(action.payload.data.data);
                }
                else {
                  state.customer.data.records.push(action.payload.data.data);
                }
              }
              toast.success(action.payload.message.message, {
                position: toast.POSITION.BOTTOM_CENTER,
                toastId: "dept-error"
              });
              
            }
          );
          builder.addCase(
            insertUpdateCustomerAction.pending,
            (state, action) => {
              //console.log("insertUpdateCustomerAction pending", action);
              state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
            }
          );
          builder.addCase(
            insertUpdateCustomerAction.rejected,
            (state, action) => {
            //   console.log("insertUpdateVatAction rejected", action);
            //   console.log(action.error.message);
              state.insertUpdateDetails.apiMsg.message = action.error.message;
              state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
      
              toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                position: toast.POSITION.BOTTOM_CENTER,
                toastId: "dept-error"
              });
            }
          );


        //   insert update insertUpdateDetails action end

    },
});
export const {RESET,ResetCustomerDetailsState } = customerReducer.actions;
export default customerReducer.reducer;

