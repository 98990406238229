import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";


export const getAllSupplierProductAction = createAsyncThunk(

    "SupplierProduct/GetAllSupplierProduct",
    async (data) => {
      return apiCall(`${data.id ? "supplierproduct?id="+data.id:"supplierproduct"}`, "GET",data, false)
        .then((response) => {
          // console.log("response",response)
          if (response.status === 200) {
            if (!response.data.hasError) {
            
              return {
                ...response.data,
              }
            } else {
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
        })
        .catch((error) => {
          return Promise.reject({
            ...error,
          });
        });
    }
  );

  export const getAllNotMappedProductAction = createAsyncThunk(

    "SupplierProduct/NotMapped",
    async (data) => {
      return apiCall(`supplierproduct/getnotmapped`, "GET",data, false)
        .then((response) => {
          // console.log("response",response)
          if (response.status === 200) {
            if (!response.data.hasError) {
            
              return {
                ...response.data,
              }
            } else {
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
        })
        .catch((error) => {
          return Promise.reject({
            ...error,
          });
        });
    }
  );
  export const insertUpdateSupplierProductAction = createAsyncThunk(
    "SupplierProduct/insertupdatesupplierproduct",
    async (data) => {
      return apiCall("supplierproduct/add", "POST", data, false)
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.hasError) {
              // console.log('response.data ',response.data.message)
              let message = response.data.message
              // console.log("message",message);
              return {
               data:{
                ...response.data,
              },
              message:{
                code:response.data.message.code,
                type:response.data.message.type,
                message:response.data.message.message
              },
              newRecord:data.id ? false : true
              }
            } else {
              // console.log("response error", response.data.message);
              if(!response.data.message && response.data.errors && response.data.errors.length){
                response.data.message=response.data.errors[0];
              }
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
          else{
            return Promise.reject({
              ...response.data,
            });
          }
        })
        .catch((error) => {
          return Promise.reject({
            ...error,
          });
        });
    }
  );
  export const mappedSupplierProductAction = createAsyncThunk(
    "SupplierProduct/Mapp",
    async (data) => {
      return apiCall("supplierproduct/mapp", "POST", data, false)
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.hasError) {
              // console.log('response.data ',response.data.message)
              let message = response.data.message
              // console.log("message",message);
              return {
               data:{
                ...response.data,
              },
              message:{
                code:response.data.message.code,
                type:response.data.message.type,
                message:response.data.message.message
              },
              newRecord:data.id ? false : true
              }
            } else {
              // console.log("response error", response.data.message);
              if(!response.data.message && response.data.errors && response.data.errors.length){
                response.data.message=response.data.errors[0];
              }
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
          else{
            return Promise.reject({
              ...response.data,
            });
          }
        })
        .catch((error) => {
          return Promise.reject({
            ...error,
          });
        });
    }
  );
  