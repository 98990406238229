import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";



export const getAllBrandsAction = createAsyncThunk(
  "Brand/getall",
  async (data) => {
    return apiCall("brand", "GET", {}, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log('response.data ',response.data)
            return {
              ...response.data,
            }
          } else {
            console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);


export const insertUpdateBrandAction = createAsyncThunk(
    "Brand/insertupdatebrand",
    async (data) => {
      return apiCall("brand/add", "POST", data, false)
        .then((response) => {
           // console.log(response);
          if (response.status === 200) {
            if (!response.data.hasError) {
              //console.log('response.data ',response.data.message)
              let message = response.data.message
              return {
               data:{
                ...response.data,
              },
              message:{
                code:response.data.message.code,
                type:response.data.message.type,
                message:response.data.message.message
              },
              newRecord:data.id ? false : true
              }
            } else {
              console.log("response error", response.data.message);
              if(!response.data.message && response.data.errors && response.data.errors.length){
                response.data.message=response.data.errors[0];
              }
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
          else{
            return Promise.reject({
              ...response.data,
            });
          }
        })
        .catch((error) => {
          return Promise.reject({
            ...error,
          });
        });
    }
  );
  export const checkInActiveOrDeleteAction = createAsyncThunk(
    'Brand/CheckInActiveOrDelete',
    async (data) => {
      return apiCall("brand/checkinactiveordelete", "POST", data, false)
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.hasError) {
              return {
                ...response.data,
              }
            } else {
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
        })
        .catch((error) => {
          return Promise.reject({
            ...error,
          });
        });
    }
  )
  export const deleteAction = createAsyncThunk(
    'Brand/Delete',
    async (data) => {
      return apiCall("brand/delete", "POST", data, false)
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.hasError) {
              return {
                ...response.data,
              }
            } else {
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
        })
        .catch((error) => {
          return Promise.reject({
            ...error,
          });
        });
    }
  )