import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';

import { getAllStockCountAction, getStockCountAction, startStockCountAction, getProductBarcodeAction, stockCountAction, resetStockCountAction, completedStockCountAction } from "../../actions/stock-out-manage/stockCountAction";

const INITIAL_STATE = {
    stockCount: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedStockCount: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    productBarcode: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    startStockCountDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    updatedStockCount: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    resetStock: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    completedStockCount: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
};

const stockCountReducer = createSlice({
    name: "StockCount",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetStockCountDetailsState(state, action) {
            state.startStockCountDetails = INITIAL_STATE.startStockCountDetails;
            state.selectedStockCount = INITIAL_STATE.selectedStockCount
        },
    },
    extraReducers: (builder) => {

        //  --------getAllStockCountAction start--------------------

        builder.addCase(getAllStockCountAction.fulfilled, (state, action) => {

            state.stockCount.data.records = action.payload.data;
            state.stockCount.apiMsg.status = action.meta.requestStatus;
            state.stockCount.apiMsg.message = action.payload.message.message;

        });
        builder.addCase(getAllStockCountAction.pending, (state, action) => {
            // console.log("getAllStockCountAction pending", action);
            state.stockCount.data.records = null;
            state.stockCount.apiMsg.message = action.meta.requestStatus;;
            state.stockCount.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllStockCountAction.rejected, (state, action) => {
            // console.log("getAllStockCountAction rejected", action);
            state.stockCount.apiMsg.message = action.error.message;
            state.stockCount.apiMsg.status = action.meta.requestStatus;
        });

        //  -------- getAllStockCountAction end--------------------



        //------------------------------- getStockCountAction start -------------------------

        builder.addCase(getStockCountAction.fulfilled, (state, action) => {
            state.selectedStockCount.data = action.payload.data
            state.selectedStockCount.apiMsg.status = action.meta.requestStatus;
            state.selectedStockCount.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getStockCountAction.pending, (state, action) => {
            //console.log("getStockCountAction pending", action);
            state.selectedStockCount.data = null;
            state.selectedStockCount.apiMsg.message = action.meta.requestStatus;;
            state.selectedStockCount.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getStockCountAction.rejected, (state, action) => {
            // console.log("getStockCountAction rejected", action);
            state.selectedStockCount.apiMsg.message = action.error.message;
            state.selectedStockCount.apiMsg.status = action.meta.requestStatus;
        });


        //   getStockCountAction end


        //------------------------------- getProductBarcodeAction start -------------------------

        builder.addCase(getProductBarcodeAction.fulfilled, (state, action) => {
            state.productBarcode.data = action.payload.data
            state.productBarcode.apiMsg.status = action.meta.requestStatus;
            state.productBarcode.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getProductBarcodeAction.pending, (state, action) => {
            //console.log("getProductBarcodeAction pending", action);
            state.productBarcode.data = null;
            state.productBarcode.apiMsg.message = action.meta.requestStatus;;
            state.productBarcode.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getProductBarcodeAction.rejected, (state, action) => {
            // console.log("getProductBarcodeAction rejected", action);
            state.productBarcode.apiMsg.message = action.error.message;
            state.productBarcode.apiMsg.status = action.meta.requestStatus;
        });


        //   getProductBarcodeAction end


        // startStockCountAction add Action start

        builder.addCase(
            startStockCountAction.fulfilled,
            (state, action) => {
                // console.log("startStockCountAction", action);

                state.startStockCountDetails.data = action.payload.data.data;
                state.startStockCountDetails.apiMsg.status = action.meta.requestStatus;
                state.startStockCountDetails.apiMsg.message = action.payload.message.message;

                if (!action.payload.newRecord) {
                    let updatedRecord = state.stockCount.data.records.map(x => {
                        if (x.id == action.payload.data.data.id) {
                            return action.payload.data.data;
                        }
                        else {
                            return x;
                        }
                    })

                    state.stockCount.data.records = updatedRecord;
                }
                else {
                    if (state.stockCount.data.records.length) {
                        state.stockCount.data.records.unshift(action.payload.data.data);
                    }
                    else {
                        state.stockCount.data.records.push(action.payload.data.data);
                    }
                }
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });

            }
        );
        builder.addCase(
            startStockCountAction.pending,
            (state, action) => {
                //console.log("startStockCountAction pending", action);
                state.startStockCountDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            startStockCountAction.rejected,
            (state, action) => {
                //   console.log("startStockCountAction rejected", action);
                //   console.log(action.error.message);
                state.startStockCountDetails.apiMsg.message = action.error.message;
                state.startStockCountDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );


        //   startStockCountAction action end



        // stockCountAction edit  start

        builder.addCase(
            stockCountAction.fulfilled,
            (state, action) => {
                // console.log("stockCountAction", action);

                state.updatedStockCount.data = action.payload.data.data;
                state.updatedStockCount.apiMsg.status = action.meta.requestStatus;
                state.updatedStockCount.apiMsg.message = action.payload.message.message;


                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );
        builder.addCase(
            stockCountAction.pending,
            (state, action) => {
                state.updatedStockCount.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            stockCountAction.rejected,
            (state, action) => {
                state.updatedStockCount.apiMsg.message = action.error.message;
                state.updatedStockCount.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );


        //   stockCountAction  end

        //------------------------------- resetStockCountAction start -------------------------

        builder.addCase(resetStockCountAction.fulfilled, (state, action) => {
            state.resetStock.data = action.payload.data
            state.resetStock.apiMsg.status = action.meta.requestStatus;
            state.resetStock.apiMsg.message = action.payload.message.message;
            toast.success(action.payload.message.message, {
                position: toast.POSITION.BOTTOM_CENTER,
                toastId: "dept-error"
            });
        });

        builder.addCase(resetStockCountAction.pending, (state, action) => {
            state.resetStock.data = null;
            state.resetStock.apiMsg.message = action.meta.requestStatus;;
            state.resetStock.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(resetStockCountAction.rejected, (state, action) => {
            state.resetStock.apiMsg.message = action.error.message;
            state.resetStock.apiMsg.status = action.meta.requestStatus;
            toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                position: toast.POSITION.BOTTOM_CENTER,
                toastId: "dept-error"
            });
        });
        //   resetStockCountAction end


        //------------------------------- completedStockCountAction start -------------------------

        builder.addCase(completedStockCountAction.fulfilled, (state, action) => {
            // state.completedStockCount.data = action.payload.data
            // state.completedStockCount.apiMsg.status = action.meta.requestStatus;
            // state.completedStockCount.apiMsg.message = action.payload.message.message;
            state.stockCount.data.records = state.stockCount.data.records.filter((item) => item.id !== action.meta.arg.id);
            toast.success(action.payload.message.message, {
                position: toast.POSITION.BOTTOM_CENTER,
                toastId: "dept-error"
            });
        });

        builder.addCase(completedStockCountAction.pending, (state, action) => {
            state.completedStockCount.data = null;
            state.completedStockCount.apiMsg.message = action.meta.requestStatus;;
            state.completedStockCount.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(completedStockCountAction.rejected, (state, action) => {
            state.completedStockCount.apiMsg.message = action.error.message;
            state.completedStockCount.apiMsg.status = action.meta.requestStatus;
            toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                position: toast.POSITION.BOTTOM_CENTER,
                toastId: "dept-error"
            });
        });
        //   completedStockCountAction end

    },
});

export const { RESET, ResetStockCountDetailsState } = stockCountReducer.actions;
export default stockCountReducer.reducer;