import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";

export const getPayInOutAction = createAsyncThunk(
  "PayInOut/GetAllPayInOut",
  async (data) => {
    console.log("data.approved", data.approved)
    return apiCall("payinout?approved=" + data.approved, "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {

            return {
              ...response.data,
            }
          } else {
            //  console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const insertUpdatePayInOutAction = createAsyncThunk(
  "PayInOut/insertUpdatePayInOut",
  async (data) => {

    return apiCall("payinout/add", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            let message = response.data.message
            return {
              data: {
                ...response.data,
              },
              message: {
                code: response.data.message.code,
                type: response.data.message.type,
                message: response.data.message.message
              },
              newRecord: data.id ? false : true
            }
          } else {
            console.log("response error", response.data.message);
            if (!response.data.message && response.data.errors && response.data.errors.length) {
              response.data.message = response.data.errors[0];
            }
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
        else {
          return Promise.reject({
            ...response.data,
          });
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getPayAction = createAsyncThunk(
  "PayInOut/GetPayInOut",
  async (data) => {
    return apiCall("payinout/getpayinout", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getPriceGroupAction = createAsyncThunk(
  "PayInOut/GetPricePayInOut",
  async (data) => {
    return apiCall("productgroup/getactive", "GET", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const activeInactiveAction = createAsyncThunk(
  'PayInOut/activeinactive',
  async (data) => {
    return apiCall("payinout/activeinactive", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
)

export const getPayInOutComparisonAction = createAsyncThunk(
  "PayInOut/GetProductComparison",
  async (data) => {
    return apiCall("payinout/getpayinoutcomparison?id=" + data.id, "POST", null, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const PayInOutApproveAction = createAsyncThunk(
  "PayInOut/Approve",
  async (data) => {
    return apiCall("payinout/approve?id=" + data.id, "POST", null, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const PayInOutRejectAction = createAsyncThunk(
  "PayInOut/Reject",
  async (data) => {
    return apiCall("payinout/reject?id=" + data.id, "POST", null, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);