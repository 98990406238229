import { createSlice } from "@reduxjs/toolkit";

import {
    getAllNewsPapersAction,
    getNewsPapersAction,
    getPriceGroupProductAction,
    getFrequencyTypeAction,
    insertUpdateNewsPaperAction,
    activeInactiveAction,
    getNewspaperComparisonAction,
    newspaperApproveAction,
    newspaperRejectAction,
    syncNewspaperAction
} from "../../actions/inventory/newspapersReducerActions";

import { toast } from 'react-toastify';

const INITIAL_STATE = {
    newsList: {
        data: {
            records: null,
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedNewsPaper: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedPriceGroup: {
        data: {
            records: null,
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedFrequencyType: {
        data: {
            records: null,
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    activeInactiveDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    selectedNewspaperComparsion: {
        data: {
            records: null,
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    approveDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        }
    },
    rejectDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        }
    },
    syncDetails :{
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    }

}

const newspapersReducer = createSlice({
    name: "NewspapersReducer",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetNewspapersDetailsState(state, action) {
            state.selectedNewsPaper = INITIAL_STATE.selectedNewsPaper;
            state.insertUpdateDetails = INITIAL_STATE.selectedNewsPaper;
            state.approveDetails = INITIAL_STATE.approveDetails;
            state.rejectDetails = INITIAL_STATE.rejectDetails;
        },
    },
    extraReducers: (builder) => {

        //------------ getNewsPapersAction -----------

        builder.addCase(getNewsPapersAction.fulfilled, (state, action) => {
            console.log("action", action)
            state.selectedNewsPaper.data = action.payload.data;
            state.selectedNewsPaper.apiMsg.status = action.meta.requestStatus;
            state.selectedNewsPaper.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getNewsPapersAction.pending, (state, action) => {
            //console.log("getNewsPapersAction pending", action);
            state.selectedNewsPaper.data = null;
            state.selectedNewsPaper.apiMsg.message = action.meta.requestStatus;;
            state.selectedNewsPaper.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getNewsPapersAction.rejected, (state, action) => {
            // console.log("getNewsPapersAction rejected", action);
            state.selectedNewsPaper.apiMsg.message = action.error.message;
            state.selectedNewsPaper.apiMsg.status = action.meta.requestStatus;
        });
        //    getNewsPapersAction end

        //------------ getAllNewsPapersAction -----------

        builder.addCase(getAllNewsPapersAction.fulfilled, (state, action) => {
            state.newsList.data = action.payload.data;
            state.newsList.apiMsg.status = action.meta.requestStatus;
            // state.newsList.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getAllNewsPapersAction.pending, (state, action) => {
            //console.log("getAllNewsPapersAction pending", action);
            state.newsList.data = null;
            state.newsList.apiMsg.message = action.meta.requestStatus;;
            state.newsList.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getAllNewsPapersAction.rejected, (state, action) => {
            // console.log("getAllNewsPapersAction rejected", action);
            state.newsList.apiMsg.message = action.error.message;
            state.newsList.apiMsg.status = action.meta.requestStatus;
        });
        //------------ getPriceGroupProductAction -----------

        builder.addCase(getPriceGroupProductAction.fulfilled, (state, action) => {
            state.selectedPriceGroup.data.records = action.payload.data;
            state.selectedPriceGroup.apiMsg.status = action.meta.requestStatus;
            state.selectedPriceGroup.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getPriceGroupProductAction.pending, (state, action) => {
            //console.log("getPriceGroupProductAction pending", action);
            state.selectedPriceGroup.data.records = null;
            state.selectedPriceGroup.apiMsg.message = action.meta.requestStatus;;
            state.selectedPriceGroup.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getPriceGroupProductAction.rejected, (state, action) => {
            // console.log("getPriceGroupProductAction rejected", action);
            state.selectedPriceGroup.apiMsg.message = action.error.message;
            state.selectedPriceGroup.apiMsg.status = action.meta.requestStatus;
        });


        //   get pricegroup end

        //------------ getFrequencyTypeAction -----------

        builder.addCase(getFrequencyTypeAction.fulfilled, (state, action) => {
            state.selectedFrequencyType.data.records = action.payload.data;
            state.selectedFrequencyType.apiMsg.status = action.meta.requestStatus;
            state.selectedFrequencyType.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getFrequencyTypeAction.pending, (state, action) => {
            //console.log("getFrequencyTypeAction pending", action);
            state.selectedFrequencyType.data.records = null;
            state.selectedFrequencyType.apiMsg.message = action.meta.requestStatus;;
            state.selectedFrequencyType.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getFrequencyTypeAction.rejected, (state, action) => {
            // console.log("getFrequencyTypeAction rejected", action);
            state.selectedFrequencyType.apiMsg.message = action.error.message;
            state.selectedFrequencyType.apiMsg.status = action.meta.requestStatus;
        });

        //   getFrequencyTypeAction end

        // insertUpdateNewsPaperAction start

        builder.addCase(
            insertUpdateNewsPaperAction.fulfilled,
            (state, action) => {
                state.insertUpdateDetails.data = action.payload.data.data;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateDetails.apiMsg.message = action.payload.message.message;

                //   if (!action.payload.newRecord) {
                //     let updatedRecord = state.manualPriceProduct.data.records.map(x => {
                //       if (x.id == action.payload.data.data.id) {
                //         return action.payload.data.data;
                //       }
                //       else {
                //         return x;
                //       }
                //     })

                //     state.manualPriceProduct.data.records = updatedRecord;
                //   }
                //   else {
                //     if (state.manualPriceProduct.data.records.length) {
                //       state.manualPriceProduct.data.records.unshift(action.payload.data.data);
                //     }
                //     else {
                //       state.manualPriceProduct.data.records.push(action.payload.data.data);
                //     }
                //   }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );
        builder.addCase(
            insertUpdateNewsPaperAction.pending,
            (state, action) => {
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateNewsPaperAction.rejected,
            (state, action) => {
                state.insertUpdateDetails.apiMsg.message = action.error.message;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );


        //   insertUpdateNewsPaperAction  end


        //------------------------------- activeInactiveAction start -------------------------

        builder.addCase(
            activeInactiveAction.fulfilled,
            (state, action) => {
                console.log("activeInactiveAction", action);

                state.activeInactiveDetails.data = action.payload.data.data;
                state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
                state.activeInactiveDetails.apiMsg.message = action.payload.message.message;

                // let updatedRecord = state.newspaper.data.records.map(x => {
                //     if (x.id == action.meta.arg.id) {
                //         x.isActive = action.meta.arg.isActive;
                //         return x;
                //     }
                //     else {
                //         return x;
                //     }
                // })



                // state.newspaper.data.records = updatedRecord;

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            activeInactiveAction.pending,
            (state, action) => {
                state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            activeInactiveAction.rejected,
            (state, action) => {
                state.activeInactiveDetails.apiMsg.message = action.error.message;
                state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "error"
                });
            }
        );

        //------------------------------- activeInactiveAction end -------------------------



        //------------ getNewspaperComparisonAction -----------

        builder.addCase(getNewspaperComparisonAction.fulfilled, (state, action) => {
            state.selectedNewspaperComparsion.data.records = action.payload.data;
            state.selectedNewspaperComparsion.apiMsg.status = action.meta.requestStatus;
            state.selectedNewspaperComparsion.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getNewspaperComparisonAction.pending, (state, action) => {
            state.selectedNewspaperComparsion.data.records = null;
            state.selectedNewspaperComparsion.apiMsg.message = action.meta.requestStatus;;
            state.selectedNewspaperComparsion.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getNewspaperComparisonAction.rejected, (state, action) => {
            state.selectedNewspaperComparsion.apiMsg.message = action.error.message;
            state.selectedNewspaperComparsion.apiMsg.status = action.meta.requestStatus;
        });


        //    getNewspaperComparisonAction end



        //------------------------------- newspaperApproveAction start -------------------------

        builder.addCase(
            newspaperApproveAction.fulfilled,
            (state, action) => {

                state.approveDetails.data = action.payload.data.data;
                state.approveDetails.apiMsg.status = action.meta.requestStatus;
                state.approveDetails.apiMsg.message = action.payload.message.message;

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            newspaperApproveAction.pending,
            (state, action) => {
                state.approveDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            newspaperApproveAction.rejected,
            (state, action) => {
                state.approveDetails.apiMsg.message = action.error.message;
                state.approveDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "error"
                });
            }
        );

        //------------------------------- newspaperApproveAction end -------------------------

        //------------------------------- newspaperRejectAction start -------------------------

        builder.addCase(
            newspaperRejectAction.fulfilled,
            (state, action) => {

                state.rejectDetails.data = action.payload.data.data;
                state.rejectDetails.apiMsg.status = action.meta.requestStatus;
                state.rejectDetails.apiMsg.message = action.payload.message.message;

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            newspaperRejectAction.pending,
            (state, action) => {
                state.rejectDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            newspaperRejectAction.rejected,
            (state, action) => {
                state.rejectDetails.apiMsg.message = action.error.message;
                state.rejectDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "error"
                });
            }
        );

        //------------------------------- newspaperRejectAction end -------------------------

        //-------------------------------  syncNewspaperAction start -------------------------

        builder.addCase(
            syncNewspaperAction.fulfilled,
            (state, action) => {

                
                state.syncDetails.apiMsg.status = action.meta.requestStatus;
                state.syncDetails.apiMsg.message = action.payload.message.message;

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            syncNewspaperAction.pending,
            (state, action) => {
                state.syncDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            syncNewspaperAction.rejected,
            (state, action) => {
                state.syncDetails.apiMsg.message = action.error.message;
                state.syncDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "error"
                });
            }
        );

        //-------------------------------  syncNewspaperAction end -------------------------

        

    },
});
export const { RESET, ResetNewspapersDetailsState } = newspapersReducer.actions;
export default newspapersReducer.reducer;

