import { createSlice } from "@reduxjs/toolkit";

import {
  getManualPriceProductGroupAction,
  getManualGroupAction, insertUpdateManualPriceProductGroupAction,
  activeInactiveAction,
  getProductGroupComparisonAction,
  manualPriceProductGroupApproveAction,
  manualPriceProductGroupRejectAction
} from "../../actions/inventory/manualPriceProductGroupActions";

import { toast } from 'react-toastify';

const INITIAL_STATE = {
  manualPriceProductGroup: {
    data: {
      records: [],
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  insertUpdateDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
    newRecord: false
  },

  selectedManualPriceProductGroup: {
    data: null,
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  activeInactiveDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
    newRecord: false
  },
  selectedManualPriceComparsionProduct: {
    data: {
      records: null,
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  approveDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    }
  },
  rejectDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    }
  }
}
const manualPriceProductGroupReducer = createSlice({
  name: "ManualPriceProductGroup",
  initialState: INITIAL_STATE,
  reducers: {
    RESET(state, action) {
      state = INITIAL_STATE;
    },
    ResetManualPriceProductGroupDetailsState(state, action) {
      state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
      state.selectedManualPriceProductGroup = INITIAL_STATE.selectedManualPriceProductGroup;
      state.approveDetails = INITIAL_STATE.approveDetails;
      state.rejectDetails = INITIAL_STATE.rejectDetails;
    },
  },
  extraReducers: (builder) => {

    builder.addCase(getManualPriceProductGroupAction.fulfilled, (state, action) => {
      state.manualPriceProductGroup.data.records = action.payload.data;
      state.manualPriceProductGroup.apiMsg.status = action.meta.requestStatus;
      state.manualPriceProductGroup.apiMsg.message = action.payload.message.message;
    });
    builder.addCase(getManualPriceProductGroupAction.pending, (state, action) => {
      state.manualPriceProductGroup.data.records = null;
      state.manualPriceProductGroup.apiMsg.message = action.meta.requestStatus;;
      state.manualPriceProductGroup.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getManualPriceProductGroupAction.rejected, (state, action) => {
      state.manualPriceProductGroup.apiMsg.message = action.error.message;
      state.manualPriceProductGroup.apiMsg.status = action.meta.requestStatus;
    });






    // insert update Supplier Action start

    builder.addCase(
      insertUpdateManualPriceProductGroupAction.fulfilled,
      (state, action) => {
        // console.log("insertUpdateManualPriceProductGroupAction", action);

        state.insertUpdateDetails.data = action.payload.data.data;
        state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
        state.insertUpdateDetails.apiMsg.message = action.payload.message.message;

        if (!action.payload.newRecord) {
          let updatedRecord = state.manualPriceProductGroup.data.records.map(x => {
            if (x.id == action.payload.data.data.id) {
              return action.payload.data.data;
            }
            else {
              return x;
            }
          })

          state.manualPriceProductGroup.data.records = updatedRecord;
        }
        else {
          if (state.manualPriceProductGroup.data.records.length) {
            state.manualPriceProductGroup.data.records.unshift(action.payload.data.data);
          }
          else {
            state.manualPriceProductGroup.data.records.push(action.payload.data.data);
          }
        }
        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });

      }
    );
    builder.addCase(
      insertUpdateManualPriceProductGroupAction.pending,
      (state, action) => {
        state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      insertUpdateManualPriceProductGroupAction.rejected,
      (state, action) => {

        state.insertUpdateDetails.apiMsg.message = action.error.message;
        state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });
      }
    );



    //------------------------------- getManualGroupAction start -------------------------

    builder.addCase(getManualGroupAction.fulfilled, (state, action) => {
      state.selectedManualPriceProductGroup.data = action.payload.data;
      state.selectedManualPriceProductGroup.apiMsg.status = action.meta.requestStatus;
      state.selectedManualPriceProductGroup.apiMsg.message = action.payload.message.message;
    });

    builder.addCase(getManualGroupAction.pending, (state, action) => {
      //console.log("getManualGroupAction pending", action);
      state.selectedManualPriceProductGroup.data = null;
      state.selectedManualPriceProductGroup.apiMsg.message = action.meta.requestStatus;;
      state.selectedManualPriceProductGroup.apiMsg.status = action.meta.requestStatus;
    });

    builder.addCase(getManualGroupAction.rejected, (state, action) => {
      // console.log("getManualGroupAction rejected", action);
      state.selectedManualPriceProductGroup.apiMsg.message = action.error.message;
      state.selectedManualPriceProductGroup.apiMsg.status = action.meta.requestStatus;
    });


    //   getManualGroupAction end


    //------------------------------- activeInactiveAction start -------------------------

    builder.addCase(
      activeInactiveAction.fulfilled,
      (state, action) => {

        state.activeInactiveDetails.data = action.payload.data.data;
        state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
        state.activeInactiveDetails.apiMsg.message = action.payload.message.message;

        let updatedRecord = state.manualPriceProductGroup.data.records.map(x => {
          if (x.id == action.meta.arg.id) {
            x.isActive = action.meta.arg.isActive;
            return x;
          }
          else {
            return x;
          }
        })



        state.manualPriceProductGroup.data.records = updatedRecord;

        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "success"
        });

      }
    );
    builder.addCase(
      activeInactiveAction.pending,
      (state, action) => {
        state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      activeInactiveAction.rejected,
      (state, action) => {
        state.activeInactiveDetails.apiMsg.message = action.error.message;
        state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "error"
        });
      }
    );

    //------------------------------- activeInactiveAction end -------------------------


    //------------ getProductGroupComparisonAction -----------

    builder.addCase(getProductGroupComparisonAction.fulfilled, (state, action) => {
      state.selectedManualPriceComparsionProduct.data.records = action.payload.data;
      state.selectedManualPriceComparsionProduct.apiMsg.status = action.meta.requestStatus;
      state.selectedManualPriceComparsionProduct.apiMsg.message = action.payload.message.message;
    });

    builder.addCase(getProductGroupComparisonAction.pending, (state, action) => {
      //console.log("getProductGroupComparisonAction pending", action);
      state.selectedManualPriceComparsionProduct.data.records = null;
      state.selectedManualPriceComparsionProduct.apiMsg.message = action.meta.requestStatus;;
      state.selectedManualPriceComparsionProduct.apiMsg.status = action.meta.requestStatus;
    });

    builder.addCase(getProductGroupComparisonAction.rejected, (state, action) => {
      // console.log("getManualPriceProductAction rejected", action);
      state.selectedManualPriceComparsionProduct.apiMsg.message = action.error.message;
      state.selectedManualPriceComparsionProduct.apiMsg.status = action.meta.requestStatus;
    });


    //   get getProductGroupComparisonAction end



    //------------------------------- manualPriceProductGroupApproveAction start -------------------------

    builder.addCase(
      manualPriceProductGroupApproveAction.fulfilled,
      (state, action) => {
        console.log("manualPriceProductGroupApproveAction", action);

        state.approveDetails.data = action.payload.data.data;
        state.approveDetails.apiMsg.status = action.meta.requestStatus;
        state.approveDetails.apiMsg.message = action.payload.message.message;

        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "success"
        });

      }
    );
    builder.addCase(
      manualPriceProductGroupApproveAction.pending,
      (state, action) => {
        state.approveDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      manualPriceProductGroupApproveAction.rejected,
      (state, action) => {
        state.approveDetails.apiMsg.message = action.error.message;
        state.approveDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "error"
        });
      }
    );

    //------------------------------- manualPriceProductGroupApproveAction end -------------------------

    //------------------------------- manualPriceProductGroupRejectAction start -------------------------

    builder.addCase(
      manualPriceProductGroupRejectAction.fulfilled,
      (state, action) => {
        console.log("manualPriceProductGroupRejectAction", action);

        state.rejectDetails.data = action.payload.data.data;
        state.rejectDetails.apiMsg.status = action.meta.requestStatus;
        state.rejectDetails.apiMsg.message = action.payload.message.message;

        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "success"
        });

      }
    );
    builder.addCase(
      manualPriceProductGroupRejectAction.pending,
      (state, action) => {
        state.rejectDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      manualPriceProductGroupRejectAction.rejected,
      (state, action) => {
        state.rejectDetails.apiMsg.message = action.error.message;
        state.rejectDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "error"
        });
      }
    );

    //------------------------------- manualPriceProductGroupRejectAction end -------------------------
  },
});
export const { RESET, ResetManualPriceProductGroupDetailsState } = manualPriceProductGroupReducer.actions;
export default manualPriceProductGroupReducer.reducer;

