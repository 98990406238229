import { createSlice } from "@reduxjs/toolkit";


import { getAllDiscountAction, insertUpdateDiscountAction, getDiscountAction, getBarcodeAction, getQRCodeAction, insertUpdateQRCodeAction, activeInactiveAction, getDiscountComparisonAction, discountApproveAction, discountRejectAction } from "../../actions/promotion/discountActions";

import { toast } from 'react-toastify';

const INITIAL_STATE = {

  discount: {
    data: {
      records: [],
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },

  insertUpdateDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
    newRecord: false
  },

  selectedDiscount: {
    data: null,
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },

  barcode: {
    data: null,
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },

  qrCode: {
    data: {
      records: [],
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },

  insertUpdateQRCodeDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
    newRecord: false
  },
  activeInactiveDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
    newRecord: false
  },

  selectedDiscountComparsion: {
    data: {
      records: null,
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  approveDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    }
  },
  rejectDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    }
  }

}
const discountReducer = createSlice({
  name: "Discount",
  initialState: INITIAL_STATE,
  reducers: {



    RESET(state, action) {
      state = INITIAL_STATE;
    },
    ResetBarcodeDetails(state, action) {
      state.barcode = INITIAL_STATE.barcode
    },
    ResetDiscountDetailsState(state, action) {
      state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
      state.selectedDiscount = INITIAL_STATE.selectedDiscount;
      state.barcode.barcode = INITIAL_STATE.barcode.barcode;
      state.insertUpdateQRCodeDetails = INITIAL_STATE.insertUpdateQRCodeDetails;
      state.approveDetails = INITIAL_STATE.approveDetails;
      state.rejectDetails = INITIAL_STATE.rejectDetails;
    },
  },
  extraReducers: (builder) => {


    builder.addCase(getAllDiscountAction.fulfilled, (state, action) => {
      state.discount.data.records = action.payload.data;
      state.discount.apiMsg.status = action.meta.requestStatus;
      state.discount.apiMsg.message = action.payload.message.message;
    });
    builder.addCase(getAllDiscountAction.pending, (state, action) => {
      state.discount.data.records = null;
      state.discount.apiMsg.message = action.meta.requestStatus;;
      state.discount.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getAllDiscountAction.rejected, (state, action) => {
      state.discount.apiMsg.message = action.error.message;
      state.discount.apiMsg.status = action.meta.requestStatus;
      toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
        position: toast.POSITION.BOTTOM_CENTER,
        toastId: "dept-error"
      });
    });

    // insert update Discount Action start

    builder.addCase(
      insertUpdateDiscountAction.fulfilled,
      (state, action) => {
        // console.log("insertUpdateDiscountAction", action);

        state.insertUpdateDetails.data = action.payload.data.data;
        state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
        state.insertUpdateDetails.apiMsg.message = action.payload.message.message;

        if (!action.payload.newRecord) {
          let updatedRecord = state.discount.data.records.map(x => {
            if (x.id == action.payload.data.data.id) {
              return action.payload.data.data;
            }
            else {
              return x;
            }
          })

          state.discount.data.records = updatedRecord;
        }
        else {
          if (state.discount.data.records.length) {
            state.discount.data.records.unshift(action.payload.data.data);
          }
          else {
            state.discount.data.records.push(action.payload.data.data);
          }
        }
        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });

      }
    );
    builder.addCase(
      insertUpdateDiscountAction.pending,
      (state, action) => {
        //console.log("insertUpdateDiscountAction pending", action);
        state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      insertUpdateDiscountAction.rejected,
      (state, action) => {
        //   console.log("insertUpdateDiscountAction rejected", action);
        //   console.log(action.error.message);
        state.insertUpdateDetails.apiMsg.message = action.error.message;
        state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });
      }
    );


    //   insert update Discount action end



    //------------------------------- getdiscount start -------------------------

    builder.addCase(getDiscountAction.fulfilled, (state, action) => {
      state.selectedDiscount.data = action.payload.data;
      state.selectedDiscount.apiMsg.status = action.meta.requestStatus;
      state.selectedDiscount.apiMsg.message = action.payload.message.message;
    });

    builder.addCase(getDiscountAction.pending, (state, action) => {
      //console.log("getDiscountAction pending", action);
      state.selectedDiscount.data = null;
      state.selectedDiscount.apiMsg.message = action.meta.requestStatus;;
      state.selectedDiscount.apiMsg.status = action.meta.requestStatus;
    });

    builder.addCase(getDiscountAction.rejected, (state, action) => {
      // console.log("getDiscountAction rejected", action);
      state.selectedDiscount.apiMsg.message = action.error.message;
      state.selectedDiscount.apiMsg.status = action.meta.requestStatus;
    });


    //   get discount end






    //------------------------------- getbarcode start -------------------------

    builder.addCase(getBarcodeAction.fulfilled, (state, action) => {
      state.barcode.data = action.payload.data;
      state.barcode.apiMsg.status = action.meta.requestStatus;
      state.barcode.apiMsg.message = action.payload.message.message;
    });

    builder.addCase(getBarcodeAction.pending, (state, action) => {
      state.barcode.data = null;
      state.barcode.apiMsg.message = action.meta.requestStatus;;
      state.barcode.apiMsg.status = action.meta.requestStatus;
    });

    builder.addCase(getBarcodeAction.rejected, (state, action) => {
      state.barcode.apiMsg.message = action.error.message;
      state.barcode.apiMsg.status = action.meta.requestStatus;
    });


    //   get barcode end

    // qrcode start

    builder.addCase(getQRCodeAction.fulfilled, (state, action) => {
      state.qrCode.data.records = action.payload.data;
      state.qrCode.apiMsg.status = action.meta.requestStatus;
      state.qrCode.apiMsg.message = action.payload.message.message;
    });
    builder.addCase(getQRCodeAction.pending, (state, action) => {
      state.qrCode.data.records = null;
      state.qrCode.apiMsg.message = action.meta.requestStatus;;
      state.qrCode.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getQRCodeAction.rejected, (state, action) => {
      state.qrCode.apiMsg.message = action.error.message;
      state.qrCode.apiMsg.status = action.meta.requestStatus;
    });

    // qrcode Discount Action end










    // insert update QRCode Action start

    builder.addCase(
      insertUpdateQRCodeAction.fulfilled,
      (state, action) => {

        state.insertUpdateQRCodeDetails.data = action.payload.data.data;
        state.insertUpdateQRCodeDetails.apiMsg.status = action.meta.requestStatus;
        state.insertUpdateQRCodeDetails.apiMsg.message = action.payload.message.message;

        if (!action.payload.newRecord) {
          let updatedRecord = state.discount.data.records.map(x => {
            if (x.id == action.payload.data.data.id) {
              return action.payload.data.data;
            }
            else {
              return x;
            }
          })

          state.discount.data.records = updatedRecord;
        }
        else {
          if (state.discount.data.records.length) {
            state.discount.data.records.unshift(action.payload.data.data);
          }
          else {
            state.discount.data.records.push(action.payload.data.data);
          }
        }
        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });

      }
    );
    builder.addCase(
      insertUpdateQRCodeAction.pending,
      (state, action) => {
        state.insertUpdateQRCodeDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      insertUpdateQRCodeAction.rejected,
      (state, action) => {
        state.insertUpdateQRCodeDetails.apiMsg.message = action.error.message;
        state.insertUpdateQRCodeDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });
      }
    );


    //   insert update Discount action end

    //------------------------------- activeInactiveAction start -------------------------

    builder.addCase(
      activeInactiveAction.fulfilled,
      (state, action) => {
        //console.log("activeInactiveAction", action);

        state.activeInactiveDetails.data = action.payload.data.data;
        state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
        state.activeInactiveDetails.apiMsg.message = action.payload.message.message;

        let updatedRecord = state.discount.data.records.map(x => {
          if (x.id == action.meta.arg.id) {
            x.isActive = action.meta.arg.isActive;
            return x;
          }
          else {
            return x;
          }
        })



        state.discount.data.records = updatedRecord;

        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "success"
        });

      }
    );
    builder.addCase(
      activeInactiveAction.pending,
      (state, action) => {
        state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      activeInactiveAction.rejected,
      (state, action) => {
        state.activeInactiveDetails.apiMsg.message = action.error.message;
        state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "error"
        });
      }
    );

    //------------------------------- activeInactiveAction end -------------------------


    //------------ getDiscountComparisonAction -----------

    builder.addCase(getDiscountComparisonAction.fulfilled, (state, action) => {
      state.selectedDiscountComparsion.data.records = action.payload.data;
      state.selectedDiscountComparsion.apiMsg.status = action.meta.requestStatus;
      state.selectedDiscountComparsion.apiMsg.message = action.payload.message.message;
    });

    builder.addCase(getDiscountComparisonAction.pending, (state, action) => {
      state.selectedDiscountComparsion.data.records = null;
      state.selectedDiscountComparsion.apiMsg.message = action.meta.requestStatus;;
      state.selectedDiscountComparsion.apiMsg.status = action.meta.requestStatus;
    });

    builder.addCase(getDiscountComparisonAction.rejected, (state, action) => {
      state.selectedDiscountComparsion.apiMsg.message = action.error.message;
      state.selectedDiscountComparsion.apiMsg.status = action.meta.requestStatus;
    });


    //    getDiscountComparisonAction end



    //------------------------------- discountApproveAction start -------------------------

    builder.addCase(
      discountApproveAction.fulfilled,
      (state, action) => {

        state.approveDetails.data = action.payload.data.data;
        state.approveDetails.apiMsg.status = action.meta.requestStatus;
        state.approveDetails.apiMsg.message = action.payload.message.message;

        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "success"
        });

      }
    );
    builder.addCase(
      discountApproveAction.pending,
      (state, action) => {
        state.approveDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      discountApproveAction.rejected,
      (state, action) => {
        state.approveDetails.apiMsg.message = action.error.message;
        state.approveDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "error"
        });
      }
    );

    //------------------------------- discountApproveAction end -------------------------

    //------------------------------- discountRejectAction start -------------------------

    builder.addCase(
      discountRejectAction.fulfilled,
      (state, action) => {

        state.rejectDetails.data = action.payload.data.data;
        state.rejectDetails.apiMsg.status = action.meta.requestStatus;
        state.rejectDetails.apiMsg.message = action.payload.message.message;

        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "success"
        });
      }
    );
    builder.addCase(
      discountRejectAction.pending,
      (state, action) => {
        state.rejectDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      discountRejectAction.rejected,
      (state, action) => {
        state.rejectDetails.apiMsg.message = action.error.message;
        state.rejectDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "error"
        });
      }
    );

    //------------------------------- discountRejectAction end -------------------------

  },
});
export const { RESET, ResetDiscountDetailsState, ResetBarcodeDetails } = discountReducer.actions;
export default discountReducer.reducer;

