import { createSlice } from "@reduxjs/toolkit";
import {

  getAllPromotionAction,
  getPromotion,
  insertUpdatePromotionAction,
  getAllPromotionTypes,
  deleteAction,
  activeInactiveAction,
  getPromotionComparisonAction,
  promotionApproveAction,
  promotionRejectAction
} from "../../actions/promotion/promotionActions";
import { toast } from 'react-toastify';
const INITIAL_STATE = {
  promotion: {
    data: {
      records: [],
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  selectedPromotion: {
    data: {
      records: [],
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  insertUpdatePromotionDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
    newRecord: false
  },
  promotionTypeList: {
    data: {
      records: [],
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },

  activeInactiveDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
    newRecord: false
  },

  selectedPromotionComparsion: {
    data: {
      records: null,
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  approveDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    }
  },
  rejectDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    }
  }
};

const promotionReducer = createSlice({
  name: "Promotion",
  initialState: INITIAL_STATE,
  reducers: {

    SelectPromotionAction(state, action) {
      state.promotion.data.records = action.payload;
    },

    RESET(state, action) {
      state = INITIAL_STATE;
    },
    ResetInsertUpdatePromotionDetailsState(state, action) {
      state.insertUpdatePromotionDetails = INITIAL_STATE.insertUpdatePromotionDetails;
      state.selectedPromotion = INITIAL_STATE.selectedPromotion;
      state.approveDetails = INITIAL_STATE.approveDetails;
      state.rejectDetails = INITIAL_STATE.rejectDetails
    },
  },
  extraReducers: (builder) => {

    //------------------------------- getAllPromotionAction start -------------------------
    // eslint-disable-next-line
    builder.addCase(getAllPromotionAction.fulfilled, (state, action) => {
      //console.log("getAllPromotionAction", action);
      state.promotion.data.records = action.payload.data;
      state.promotion.apiMsg.status = action.meta.requestStatus;
      state.promotion.apiMsg.message = action.payload.message.message;
    });
    builder.addCase(getAllPromotionAction.pending, (state, action) => {
      //console.log("getAllPromotionAction pending", action);
      state.promotion.data.records = null;
      state.promotion.apiMsg.message = action.meta.requestStatus;;
      state.promotion.apiMsg.status = action.meta.requestStatus;
      state.selectedPromotion = INITIAL_STATE.selectedPromotion;
    });
    builder.addCase(getAllPromotionAction.rejected, (state, action) => {
      state.promotion.apiMsg.message = action.error.message;
      state.promotion.apiMsg.status = action.meta.requestStatus;
    });

    //------------------------------- getPromotion start -------------------------
    // eslint-disable-next-line
    builder.addCase(getPromotion.fulfilled, (state, action) => {
      //console.log("getPromotion", action);
      state.selectedPromotion.data.records = action.payload.data;
      state.selectedPromotion.apiMsg.status = action.meta.requestStatus;
      state.selectedPromotion.apiMsg.message = action.payload.message.message;
    });
    builder.addCase(getPromotion.pending, (state, action) => {
      //console.log("getPromotion pending", action);
      state.selectedPromotion.data.records = null;
      state.selectedPromotion.apiMsg.message = action.meta.requestStatus;;
      state.selectedPromotion.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getPromotion.rejected, (state, action) => {
      // console.log("getPromotion rejected", action);
      state.selectedPromotion.apiMsg.message = action.error.message;
      state.selectedPromotion.apiMsg.status = action.meta.requestStatus;
    });

    //------------------------------- insertUpdatePromotionAction start -------------------------

    builder.addCase(
      insertUpdatePromotionAction.fulfilled,
      (state, action) => {


        state.insertUpdatePromotionDetails.data = action.payload.data.data;
        state.insertUpdatePromotionDetails.data.expectedVatName = action.meta.arg.expectedVatName;
        state.insertUpdatePromotionDetails.apiMsg.status = action.meta.requestStatus;
        state.insertUpdatePromotionDetails.apiMsg.message = action.payload.message.message;

        if (!action.payload.newRecordCreated) {
          let updatedRecord = state.promotion.data.records.map(x => {
            if (x.id == action.payload.data.data.id) {
              return action.payload.data.data;
            }
            else {
              return x;
            }
          });


          state.promotion.data.records = updatedRecord;
          //console.log("state.promotion.data.records",state.promotion.data.records);
        }
        else {
          if (state.promotion.data.records.length) {
            state.promotion.data.records.unshift(action.payload.data.data);
          }
          else {
            state.promotion.data.records.push(action.payload.data.data);
          }
        }

        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });

      }
    );
    builder.addCase(
      insertUpdatePromotionAction.pending,
      (state, action) => {
        //console.log("insertUpdatePromotionAction pending", action);
        state.insertUpdatePromotionDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      insertUpdatePromotionAction.rejected,
      (state, action) => {
        state.insertUpdatePromotionDetails.apiMsg.message = action.error.message;
        state.insertUpdatePromotionDetails.apiMsg.status = action.meta.requestStatus;

        toast.error((action.error.message ? action.error.message : "Something went wrong please try again"), {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });
      }
    );

    //------------------------------- insertUpdatePromotionAction end -------------------------

    //------------------------------- getAllPromotionTypes start -------------------------

    builder.addCase(getAllPromotionTypes.fulfilled, (state, action) => {
      //console.log("getAllPromotionTypes", action);
      state.promotionTypeList.data.records = action.payload.data;
      state.promotionTypeList.apiMsg.status = action.meta.requestStatus;
      state.promotionTypeList.apiMsg.message = action.payload.message.message;
    });
    builder.addCase(getAllPromotionTypes.pending, (state, action) => {
      //console.log("getAllPromotionTypes pending", action);
      state.promotionTypeList.data.records = null;
      state.promotionTypeList.apiMsg.message = action.meta.requestStatus;;
      state.promotionTypeList.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getAllPromotionTypes.rejected, (state, action) => {
      console.log("getAllPromotionTypes rejected", action);
      state.promotionTypeList.apiMsg.message = action.error.message;
      state.promotionTypeList.apiMsg.status = action.meta.requestStatus;
    });

    //------------------------------- deleteAction start -------------------------

    builder.addCase(
      deleteAction.fulfilled,
      (state, action) => {
        //console.log("deleteAction",action);
        state.promotion.data.records = state.promotion.data.records.filter((item) => item.id !== action.meta.arg.id);
        toast.success("Record deleted successfully", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "msg-success"
        });

      }
    );

    builder.addCase(
      deleteAction.rejected,
      (state, action) => {
        toast.error((action.error.message ? action.error.message : "Something went wrong please try again"), {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "msg-error"
        });
      }
    );

    //------------------------------- activeInactiveAction start -------------------------

    builder.addCase(
      activeInactiveAction.fulfilled,
      (state, action) => {
        console.log("activeInactiveAction", action);

        state.activeInactiveDetails.data = action.payload.data.data;
        state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
        state.activeInactiveDetails.apiMsg.message = action.payload.message.message;

        let updatedRecord = state.promotion.data.records.map(x => {
          if (x.id == action.meta.arg.id) {
            x.isActive = action.meta.arg.isActive;
            return x;
          }
          else {
            return x;
          }
        })



        state.promotion.data.records = updatedRecord;

        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "success"
        });

      }
    );
    builder.addCase(
      activeInactiveAction.pending,
      (state, action) => {
        state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      activeInactiveAction.rejected,
      (state, action) => {
        state.activeInactiveDetails.apiMsg.message = action.error.message;
        state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "error"
        });
      }
    );

    //------------------------------- activeInactiveAction end -------------------------

    //------------ getPromotionComparisonAction -----------

    builder.addCase(getPromotionComparisonAction.fulfilled, (state, action) => {
      state.selectedPromotionComparsion.data.records = action.payload.data;
      state.selectedPromotionComparsion.apiMsg.status = action.meta.requestStatus;
      state.selectedPromotionComparsion.apiMsg.message = action.payload.message.message;
    });

    builder.addCase(getPromotionComparisonAction.pending, (state, action) => {
      //console.log("getPromotionComparisonAction pending", action);
      state.selectedPromotionComparsion.data.records = null;
      state.selectedPromotionComparsion.apiMsg.message = action.meta.requestStatus;;
      state.selectedPromotionComparsion.apiMsg.status = action.meta.requestStatus;
    });

    builder.addCase(getPromotionComparisonAction.rejected, (state, action) => {
      // console.log("getPromotionComparisonAction rejected", action);
      state.selectedPromotionComparsion.apiMsg.message = action.error.message;
      state.selectedPromotionComparsion.apiMsg.status = action.meta.requestStatus;
    });


    //    getPromotionComparisonAction end



    //------------------------------- promotionApproveAction start -------------------------

    builder.addCase(
      promotionApproveAction.fulfilled,
      (state, action) => {

        state.approveDetails.data = action.payload.data.data;
        state.approveDetails.apiMsg.status = action.meta.requestStatus;
        state.approveDetails.apiMsg.message = action.payload.message.message;

        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "success"
        });

      }
    );
    builder.addCase(
      promotionApproveAction.pending,
      (state, action) => {
        state.approveDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      promotionApproveAction.rejected,
      (state, action) => {
        state.approveDetails.apiMsg.message = action.error.message;
        state.approveDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "error"
        });
      }
    );

    //------------------------------- promotionApproveAction end -------------------------

    //------------------------------- promotionRejectAction start -------------------------

    builder.addCase(
      promotionRejectAction.fulfilled,
      (state, action) => {
        state.rejectDetails.data = action.payload.data.data;
        state.rejectDetails.apiMsg.status = action.meta.requestStatus;
        state.rejectDetails.apiMsg.message = action.payload.message.message;

        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "success"
        });

      }
    );
    builder.addCase(
      promotionRejectAction.pending,
      (state, action) => {
        state.rejectDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      promotionRejectAction.rejected,
      (state, action) => {
        state.rejectDetails.apiMsg.message = action.error.message;
        state.rejectDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "error"
        });
      }
    );

    //------------------------------- promotionRejectAction end -------------------------

  },
});

export const { SelectPromotionAction, RESET, ResetInsertUpdatePromotionDetailsState } = promotionReducer.actions;
export default promotionReducer.reducer;
