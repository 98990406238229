import { createSlice } from "@reduxjs/toolkit";
import {
  getAllSubDepartmentsAction,
  insertUpdateSubDepartmentAction,
  deleteAction
} from "../../actions/inventory/subDepartmentActions";
import { toast } from 'react-toastify';
const INITIAL_STATE = {
  subDepartment: {
    data: {
      records: [],
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  insertUpdateSubDepartmentDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
    newRecord:false
  },
};

const subDepartmentReducer = createSlice({
  name: "SubDepartment",
  initialState: INITIAL_STATE,
  reducers: {

    SelectSubDepartmentAction(state, action) {
      console.log("SelectSubDepartmentAction ", action);
      state.subDepartment.data.records = action.payload;
    },

    RESET(state, action) {
      state = INITIAL_STATE;
    },
    ResetInsertUpdateSubDepartmentDetailsState(state, action) {
      state.insertUpdateSubDepartmentDetails = INITIAL_STATE.insertUpdateSubDepartmentDetails;
    },
  },
  extraReducers: (builder) => {
    //------------------------------- getAllSubDepartmentsAction start -------------------------
    // eslint-disable-next-line
    builder.addCase(getAllSubDepartmentsAction.fulfilled, (state, action) => {
      //console.log("getAllSubDepartmentsAction", action);
      state.subDepartment.data.records = action.payload.data;
      state.subDepartment.apiMsg.status = action.meta.requestStatus;
      state.subDepartment.apiMsg.message = action.payload.message.message;
    });
      builder.addCase(getAllSubDepartmentsAction.pending, (state, action) => {
        //console.log("getAllSubDepartmentsAction pending", action);
        state.subDepartment.data.records = null;
        state.subDepartment.apiMsg.message = action.meta.requestStatus;;
        state.subDepartment.apiMsg.status = action.meta.requestStatus;
      });
      builder.addCase(getAllSubDepartmentsAction.rejected, (state, action) => {
        console.log("getAllSubDepartmentsAction rejected", action);
        state.subDepartment.apiMsg.message = action.error.message;
        state.subDepartment.apiMsg.status = action.meta.requestStatus;
      });
      //------------------------------- getRestaurantRecordsACTION end -------------------------

      //------------------------------- insertUpdateSubDepartmentAction start -------------------------

      builder.addCase(
        insertUpdateSubDepartmentAction.fulfilled,
        (state, action) => {
        

          state.insertUpdateSubDepartmentDetails.data = action.payload.data.data;
          state.insertUpdateSubDepartmentDetails.data.expectedVatName = action.meta.arg.expectedVatName;
          state.insertUpdateSubDepartmentDetails.apiMsg.status = action.meta.requestStatus;
          state.insertUpdateSubDepartmentDetails.apiMsg.message = action.payload.message.message;
          
          if(!action.payload.newRecord){
            let updatedRecord = state.subDepartment.data.records.map(x => {
              if(x.id==action.payload.data.data.id){
                return action.payload.data.data;
              }
              else{
                return x;
              }
            })

            state.subDepartment.data.records=updatedRecord;
          }
          else{
            if(state.subDepartment.data.records.length){
              state.subDepartment.data.records.unshift(action.payload.data.data);
            }
            else{
              state.subDepartment.data.records.push(action.payload.data.data);
            }
          }

          toast.success(action.payload.message.message, {
            position: toast.POSITION.BOTTOM_CENTER,
            toastId: "dept-error"
          });

        }
      );
      builder.addCase(
        insertUpdateSubDepartmentAction.pending,
        (state, action) => {
          //console.log("insertUpdateSubDepartmentAction pending", action);
          state.insertUpdateSubDepartmentDetails.apiMsg.status =action.meta.requestStatus;
        }
      );
      builder.addCase(
        insertUpdateSubDepartmentAction.rejected,
        (state, action) => {
          console.log("insertUpdateSubDepartmentAction rejected", action);
          console.log(action.error.message);
          state.insertUpdateSubDepartmentDetails.apiMsg.message = action.error.message;
          state.insertUpdateSubDepartmentDetails.apiMsg.status = action.meta.requestStatus;

          toast.error((action.error.message ? action.error.message : "Something went wrong please try again"), {
            position: toast.POSITION.BOTTOM_CENTER,
            toastId: "dept-error"
          });
        }
      );

    //------------------------------- insertUpdateSubDepartmentAction end -------------------------

     //------------------------------- deleteAction start -------------------------

     builder.addCase(
      deleteAction.fulfilled,
      (state, action) => {
        //console.log("deleteAction",action);
        state.subDepartment.data.records = state.subDepartment.data.records.filter((item) => item.id !== action.meta.arg.id);
        toast.success("Record deleted successfully", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "msg-success"
        });

      }
    );

    builder.addCase(
      deleteAction.rejected,
      (state, action) => {
        console.log("deleteAction rejected", action);
        console.log(action.error.message);
        toast.error((action.error.message ? action.error.message : "Something went wrong please try again"), {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "msg-error"
        });
      }
    );


  },
});

export const { SelectSubDepartmentAction, RESET,ResetInsertUpdateSubDepartmentDetailsState } = subDepartmentReducer.actions;
export default subDepartmentReducer.reducer;
