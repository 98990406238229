import { createSlice } from "@reduxjs/toolkit";
import {
  getAllHeadOfficeSettingsAction,
  insertUpdateHeadofficeSettingsAction
} from "../../actions/manage-settings/headofficeSettingsActions";
import { toast } from 'react-toastify';
const INITIAL_STATE = {
  headofficeSettingsList: {
    data: {
      records: [],
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },

  insertUpdateHeadofficeSettings: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
    newRecord: false
  },
};

const headofficeSettingsReducer = createSlice({
  name: "HeadofficeSettings",
  initialState: INITIAL_STATE,
  reducers: {

    RESET(state, action) {
      state = INITIAL_STATE;
    },
    ResetHeadofficeSettingsState(state, action) {
      state.insertUpdateHeadofficeSettings = INITIAL_STATE.insertUpdateHeadofficeSettings;
      state.headofficeSettingsList = INITIAL_STATE.headofficeSettingsList;
    },
  },
  extraReducers: (builder) => {
    //------------------------------- getAllHeadOfficeSettingsAction start -------------------------
    // eslint-disable-next-line
    builder.addCase(getAllHeadOfficeSettingsAction.fulfilled, (state, action) => {
      //console.log("getAllHeadOfficeSettingsAction", action);
      state.headofficeSettingsList.data.records = action.payload.data;
      state.headofficeSettingsList.apiMsg.status = action.meta.requestStatus;
      state.headofficeSettingsList.apiMsg.message = action.payload.message.message;
    });
    builder.addCase(getAllHeadOfficeSettingsAction.pending, (state, action) => {
      //console.log("getAllHeadOfficeSettingsAction pending", action);
      state.headofficeSettingsList.data.records = null;
      state.headofficeSettingsList.apiMsg.message = action.meta.requestStatus;;
      state.headofficeSettingsList.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getAllHeadOfficeSettingsAction.rejected, (state, action) => {
      console.log("getAllHeadOfficeSettingsAction rejected", action);
      state.headofficeSettingsList.apiMsg.message = action.error.message;
      state.headofficeSettingsList.apiMsg.status = action.meta.requestStatus;
    });
    //------------------------------- getAllHeadOfficeSettingsAction end -------------------------

    //------------------------------- insertUpdateHeadofficeSettings start -------------------------

    builder.addCase(
      insertUpdateHeadofficeSettingsAction.fulfilled,
      (state, action) => {
        //console.log("insertUpdateHeadofficeSettings", action);

        state.insertUpdateHeadofficeSettings.data = action.payload.data.data;
        state.insertUpdateHeadofficeSettings.apiMsg.status = action.meta.requestStatus;
        state.insertUpdateHeadofficeSettings.apiMsg.message = action.payload.message.message;

        state.headofficeSettingsList.data.records = action.meta.arg.posParameterModel;
        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });

      }
    );
    builder.addCase(
      insertUpdateHeadofficeSettingsAction.pending,
      (state, action) => {
        //console.log("insertUpdateBrandAction pending", action);
        state.insertUpdateHeadofficeSettings.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      insertUpdateHeadofficeSettingsAction.rejected,
      (state, action) => {
        // console.log("insertUpdateHeadofficeSettings rejected", action);
        //console.log(action.error.message);
        state.insertUpdateHeadofficeSettings.apiMsg.message = action.error.message;
        state.insertUpdateHeadofficeSettings.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });
      }
    );

    //------------------------------- insertUpdateHeadofficeSettings end -------------------------

  },
});

export const { RESET, ResetHeadofficeSettingsState } = headofficeSettingsReducer.actions;
export default headofficeSettingsReducer.reducer;
