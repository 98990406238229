import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";



export const getAllTillAction = createAsyncThunk(

    "Till/GetAllTill",
    async (data) => {
      return apiCall("till", "GET",data, false)
        .then((response) => {
          // console.log("response",response)
          if (response.status === 200) {
            if (!response.data.hasError) {
            
              return {
                ...response.data,
              }
            } else {
              // console.log("response error", response.data.message);
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
        })
        .catch((error) => {
          return Promise.reject({
            ...error,
          });
        });
    }
  );


  export const insertUpdateTillAction = createAsyncThunk(
    "Till/insertupdatetill",
    async (data) => {
      return apiCall("till/add", "POST", data, false)
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.hasError) {
              let message = response.data.message
              return {
               data:{
                ...response.data,
              },
              message:{
                code:response.data.message.code,
                type:response.data.message.type,
                message:response.data.message.message
              },
              newRecord:data.id ? false : true
              }
            } else {
              if(!response.data.message && response.data.errors && response.data.errors.length){
                response.data.message=response.data.errors[0];
              }
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
          else{
            return Promise.reject({
              ...response.data,
            });
          }
        })
        .catch((error) => {
          return Promise.reject({
            ...error,
          });
        });
    }
  );


  export const getTillAction = createAsyncThunk(

    "Till/GetTill",
    async (data) => {
      return apiCall(`till/getactive?all=${true}`, "GET",data, false)
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.hasError) {
            
              return {
                ...response.data,
              }
            } else {
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
        })
        .catch((error) => {
          return Promise.reject({
            ...error,
          });
        });
    }
  );
