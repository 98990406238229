import { createSlice } from "@reduxjs/toolkit";

import {getAllPromoitonForShopAction} from "../../actions/supplier/promotionForShopActions"


const INITIAL_STATE = {
    promotionForShop: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    };
    

const promotionForShopReducer = createSlice({
    name: "PromotionForShop",
    initialState: INITIAL_STATE,
    reducers: {

          RESET(state, action) {
            state = INITIAL_STATE;
          },
         
         
    },
    extraReducers: (builder) => {

                            // get all promotion shop action start


        builder.addCase(getAllPromoitonForShopAction.fulfilled, (state, action) => {
            state.promotionForShop.data.records = action.payload.data;
            state.promotionForShop.apiMsg.status = action.meta.requestStatus;
            state.promotionForShop.apiMsg.message = "success";
            // not working above  line show error
        });
        builder.addCase(getAllPromoitonForShopAction.pending, (state, action) => {
            // console.log("getAllPromoitonForShopAction pending", action);
            state.promotionForShop.data.records = null;
            state.promotionForShop.apiMsg.message = action.meta.requestStatus;
            state.promotionForShop.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllPromoitonForShopAction.rejected, (state, action) => {
            state.promotionForShop.apiMsg.message = action.error.message;
            state.promotionForShop.apiMsg.status = action.meta.requestStatus;
        });
              
                            // get all promotion shop action end


    },
});
export const {RESET} = promotionForShopReducer.actions;
export default promotionForShopReducer.reducer;