import { createSlice } from "@reduxjs/toolkit";

import {
    getPayInOutAction,
    insertUpdatePayInOutAction,
    getPayAction,
    getPriceGroupAction,
    activeInactiveAction,
    getPayInOutComparisonAction,
    PayInOutApproveAction,
    PayInOutRejectAction
} from "../../actions/inventory/payInOutActions";

import { toast } from 'react-toastify';

const INITIAL_STATE = {
    payInOut: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    selectedPayInOut: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    selectedPriceGroup: {
        data: {
            records: null,
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    activeInactiveDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    selectedPayInOutComparsion: {
        data: {
            records: null,
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    approveDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        }
    },
    rejectDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        }
    }
}

const payInOutReducer = createSlice({
    name: "PayInOut",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetPayInOutDetailsState(state, action) {
            state.selectedPayInOut = INITIAL_STATE.selectedPayInOut;
            state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
            state.approveDetails = INITIAL_STATE.approveDetails;
            state.rejectDetails = INITIAL_STATE.rejectDetails;
        },
    },
    extraReducers: (builder) => {

        builder.addCase(getPayInOutAction.fulfilled, (state, action) => {
            state.payInOut.data.records = action.payload.data;
            state.payInOut.apiMsg.status = action.meta.requestStatus;
            state.payInOut.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getPayInOutAction.pending, (state, action) => {
            console.log("getPayInOutAction pending", action);
            state.payInOut.data.records = null;
            state.payInOut.apiMsg.message = action.meta.requestStatus;;
            state.payInOut.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getPayInOutAction.rejected, (state, action) => {
            console.log("getPayInOutAction rejected", action);
            state.payInOut.apiMsg.message = action.error.message;
            state.payInOut.apiMsg.status = action.meta.requestStatus;
        });




        // insert update payInOut Action start

        builder.addCase(
            insertUpdatePayInOutAction.fulfilled,
            (state, action) => {
                //console.log("insertUpdatePayInOutAction.fulfilled",action);
                state.insertUpdateDetails.data = action.payload.data.data;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateDetails.apiMsg.message = action.payload.message.message;

                if (!action.payload.newRecord) {
                    let updatedRecord = state.payInOut.data.records.map(x => {
                        if (x.id == action.payload.data.data.id) {
                            return action.payload.data.data;
                        }
                        else {
                            return x;
                        }
                    })

                    state.payInOut.data.records = updatedRecord;
                }
                else {
                    if (state.payInOut.data.records.length) {
                        //console.log("unshift record",state.payInOut.data.records);
                        state.payInOut.data.records.unshift(action.payload.data.data);
                    }
                    else {
                        //console.log("shift record");
                        state.payInOut.data.records.push(action.payload.data.data);
                    }
                }
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });

            }
        );
        builder.addCase(
            insertUpdatePayInOutAction.pending,
            (state, action) => {
                //console.log("insertUpdatePayInOutAction pending", action);
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdatePayInOutAction.rejected,
            (state, action) => {
                //   console.log("insertUpdatePayInOutAction rejected", action);
                state.insertUpdateDetails.apiMsg.message = action.error.message;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );


        //   insert update payInOut action end



        //------------ getPayAction start -----------

        builder.addCase(getPayAction.fulfilled, (state, action) => {
            state.selectedPayInOut.data = action.payload.data;
            state.selectedPayInOut.apiMsg.status = action.meta.requestStatus;
            state.selectedPayInOut.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getPayAction.pending, (state, action) => {
            //console.log("getPayAction pending", action);
            state.selectedPayInOut.data = null;
            state.selectedPayInOut.apiMsg.message = action.meta.requestStatus;;
            state.selectedPayInOut.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getPayAction.rejected, (state, action) => {
            // console.log("getPayAction rejected", action);
            state.selectedPayInOut.apiMsg.message = action.error.message;
            state.selectedPayInOut.apiMsg.status = action.meta.requestStatus;
        });


        //   get getPayAction end


        //------------ getpricegroupstart -----------

        builder.addCase(getPriceGroupAction.fulfilled, (state, action) => {
            state.selectedPriceGroup.data.records = action.payload.data;
            state.selectedPriceGroup.apiMsg.status = action.meta.requestStatus;
            state.selectedPriceGroup.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getPriceGroupAction.pending, (state, action) => {
            //console.log("getPriceGroupAction pending", action);
            state.selectedPriceGroup.data.records = null;
            state.selectedPriceGroup.apiMsg.message = action.meta.requestStatus;;
            state.selectedPriceGroup.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getPriceGroupAction.rejected, (state, action) => {
            // console.log("getPriceGroupAction rejected", action);
            state.selectedPriceGroup.apiMsg.message = action.error.message;
            state.selectedPriceGroup.apiMsg.status = action.meta.requestStatus;
        });


        //   get pricegroup end




        //------------------------------- activeInactiveAction start -------------------------

        builder.addCase(
            activeInactiveAction.fulfilled,
            (state, action) => {
                console.log("activeInactiveAction", action);

                state.activeInactiveDetails.data = action.payload.data.data;
                state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
                state.activeInactiveDetails.apiMsg.message = action.payload.message.message;

                let updatedRecord = state.payInOut.data.records.map(x => {
                    if (x.id == action.meta.arg.id) {
                        x.isActive = action.meta.arg.isActive;
                        return x;
                    }
                    else {
                        return x;
                    }
                })



                state.payInOut.data.records = updatedRecord;

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            activeInactiveAction.pending,
            (state, action) => {
                state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            activeInactiveAction.rejected,
            (state, action) => {
                state.activeInactiveDetails.apiMsg.message = action.error.message;
                state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "error"
                });
            }
        );

        //------------------------------- activeInactiveAction end -------------------------

        //------------ getPayInOutComparisonAction -----------

        builder.addCase(getPayInOutComparisonAction.fulfilled, (state, action) => {
            state.selectedPayInOutComparsion.data.records = action.payload.data;
            state.selectedPayInOutComparsion.apiMsg.status = action.meta.requestStatus;
            state.selectedPayInOutComparsion.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getPayInOutComparisonAction.pending, (state, action) => {
            state.selectedPayInOutComparsion.data.records = null;
            state.selectedPayInOutComparsion.apiMsg.message = action.meta.requestStatus;;
            state.selectedPayInOutComparsion.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getPayInOutComparisonAction.rejected, (state, action) => {
            state.selectedPayInOutComparsion.apiMsg.message = action.error.message;
            state.selectedPayInOutComparsion.apiMsg.status = action.meta.requestStatus;
        });


        //    getPayInOutComparisonAction end



        //------------------------------- PayInOutApproveAction start -------------------------

        builder.addCase(
            PayInOutApproveAction.fulfilled,
            (state, action) => {
                state.approveDetails.data = action.payload.data.data;
                state.approveDetails.apiMsg.status = action.meta.requestStatus;
                state.approveDetails.apiMsg.message = action.payload.message.message;

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            PayInOutApproveAction.pending,
            (state, action) => {
                state.approveDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            PayInOutApproveAction.rejected,
            (state, action) => {
                state.approveDetails.apiMsg.message = action.error.message;
                state.approveDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "error"
                });
            }
        );

        //------------------------------- PayInOutApproveAction end -------------------------

        //------------------------------- PayInOutRejectAction start -------------------------

        builder.addCase(
            PayInOutRejectAction.fulfilled,
            (state, action) => {

                state.rejectDetails.data = action.payload.data.data;
                state.rejectDetails.apiMsg.status = action.meta.requestStatus;
                state.rejectDetails.apiMsg.message = action.payload.message.message;
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            PayInOutRejectAction.pending,
            (state, action) => {
                state.rejectDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            PayInOutRejectAction.rejected,
            (state, action) => {
                state.rejectDetails.apiMsg.message = action.error.message;
                state.rejectDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "error"
                });
            }
        );

        //------------------------------- PayInOutRejectAction end ----------
    },
});
export const { RESET, ResetPayInOutDetailsState } = payInOutReducer.actions;
export default payInOutReducer.reducer;

