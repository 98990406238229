import { createSlice } from "@reduxjs/toolkit";
import {
  getAllDepartmentsAction,
  insertUpdateDepartmentAction,
  deleteAction
} from "../../actions/inventory/departmentActions";
import { toast } from 'react-toastify';
const INITIAL_STATE = {
  department: {
    data: {
      records: [],
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  insertUpdateDepartmentDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
    newRecord: false
  },
};

const departmentReducer = createSlice({
  name: "Department",
  initialState: INITIAL_STATE,
  reducers: {

    SelectDepartmentAction(state, action) {
      state.department.data.records = action.payload;
    },

    RESET(state, action) {
      state = INITIAL_STATE;
    },
    ResetInsertUpdateDepartmentDetailsState(state, action) {
      state.insertUpdateDepartmentDetails = INITIAL_STATE.insertUpdateDepartmentDetails;
    },
  },
  extraReducers: (builder) => {
    //------------------------------- getAllDepartmentsAction start -------------------------
    // eslint-disable-next-line
    builder.addCase(getAllDepartmentsAction.fulfilled, (state, action) => {
      //console.log("getAllDepartmentsAction", action);
      state.department.data.records = action.payload.data;
      state.department.apiMsg.status = action.meta.requestStatus;
      state.department.apiMsg.message = action.payload.message.message;
    });
    builder.addCase(getAllDepartmentsAction.pending, (state, action) => {
      //console.log("getAllDepartmentsAction pending", action);
      state.department.data.records = null;
      state.department.apiMsg.message = action.meta.requestStatus;;
      state.department.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getAllDepartmentsAction.rejected, (state, action) => {
      console.log("getAllDepartmentsAction rejected", action);
      state.department.apiMsg.message = action.error.message;
      state.department.apiMsg.status = action.meta.requestStatus;
    });
    //------------------------------- getRestaurantRecordsACTION end -------------------------

    //------------------------------- insertUpdateDepartmentAction start -------------------------

    builder.addCase(
      insertUpdateDepartmentAction.fulfilled,
      (state, action) => {


        state.insertUpdateDepartmentDetails.data = action.payload.data.data;
        state.insertUpdateDepartmentDetails.data.expectedVatName = action.meta.arg.expectedVatName;
        state.insertUpdateDepartmentDetails.apiMsg.status = action.meta.requestStatus;
        state.insertUpdateDepartmentDetails.apiMsg.message = action.payload.message.message;

        if (!action.payload.newRecord) {
          let updatedRecord = state.department.data.records.map(x => {
            if (x.id == action.payload.data.data.id) {
              return action.payload.data.data;
            }
            else {
              return x;
            }
          })

          state.department.data.records = updatedRecord;
        }
        else {
          if (state.department.data.records.length) {
            state.department.data.records.unshift(action.payload.data.data);
          }
          else {
            state.department.data.records.push(action.payload.data.data);
          }
        }

        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });

      }
    );
    builder.addCase(
      insertUpdateDepartmentAction.pending,
      (state, action) => {
        //console.log("insertUpdateDepartmentAction pending", action);
        state.insertUpdateDepartmentDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      insertUpdateDepartmentAction.rejected,
      (state, action) => {
        console.log("insertUpdateDepartmentAction rejected", action);
        console.log(action.error.message);
        state.insertUpdateDepartmentDetails.apiMsg.message = action.error.message;
        state.insertUpdateDepartmentDetails.apiMsg.status = action.meta.requestStatus;

        toast.error((action.error.message ? action.error.message : "Something went wrong please try again"), {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });
      }
    );

    //------------------------------- insertUpdateDepartmentAction end -------------------------

    //------------------------------- deleteAction start -------------------------

    builder.addCase(
      deleteAction.fulfilled,
      (state, action) => {
        //console.log("deleteAction",action);
        state.department.data.records = state.department.data.records.filter((item) => item.id !== action.meta.arg.id);
        toast.success("Record deleted successfully", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "msg-success"
        });

      }
    );

    builder.addCase(
      deleteAction.rejected,
      (state, action) => {
        console.log("deleteAction rejected", action);
        console.log(action.error.message);
        toast.error((action.error.message ? action.error.message : "Something went wrong please try again"), {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "msg-error"
        });
      }
    );


  },
});

export const { SelectDepartmentAction, RESET, ResetInsertUpdateDepartmentDetailsState } = departmentReducer.actions;
export default departmentReducer.reducer;
