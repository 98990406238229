import { createSlice } from "@reduxjs/toolkit";
import {
  getAllProductsAction,
  getProductsAction,
  insertUpdateProductAction,
  activeInactiveAction,
  getMappedProductAction,
  getCostProductAction,
  getPriceProductAction,
  getProductQtyHistoryAction,
  getProductComparisonAction,
  productApproveAction,
  productRejectAction,
  getProductBarcodeAction,
  uploadProductAction,
  ImportTempProductAction
} from "../../actions/inventory/productActions";

import { toast } from 'react-toastify';
const INITIAL_STATE = {
  product: {
    data: {
      records: [],
      totalRecords: 0,
    },
    productBusinessList: [],
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  selectedProduct: {
    data: null,
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  insertUpdateProductDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
    newRecord: false
  },
  activeInactiveDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
    newRecord: false
  },
  mappedProductDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
    newRecord: false
  },
  barcode: {
    data: null,
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },

  costProductDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
    newRecord: false
  },

  priceProductDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
    newRecord: false
  },
  productQtyHistory: {
    data: {
      records: [],
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  selectedProductComparsion: {
    data: {
      records: null,
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  approveDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    }
  },
  rejectDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    }
  },
  uploadProductDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    }
  },
  importProductDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    }
  },
};

const productReducer = createSlice({
  name: "Product",
  initialState: INITIAL_STATE,
  reducers: {

    ResetProductDetails(state, action) {
      state.selectedProduct = INITIAL_STATE.selectedProduct;
      state.insertUpdateProductDetails = INITIAL_STATE.insertUpdateProductDetails;
      state.costProductDetails = INITIAL_STATE.costProductDetails;
      state.priceProductDetails = INITIAL_STATE.priceProductDetails;
      state.approveDetails = INITIAL_STATE.approveDetails;
      state.rejectDetails = INITIAL_STATE.rejectDetails
    },
    ResetProductQtyHistory(state, action) {
      state.productQtyHistory = INITIAL_STATE.productQtyHistory;
    },
    ResetProductBarcodeDetails(state, action) {
      state.barcode = INITIAL_STATE.barcode
    },
  },
  extraReducers: (builder) => {
    //------------------------------- getAllProductsAction start -------------------------
    // eslint-disable-next-line
    builder.addCase(getAllProductsAction.fulfilled, (state, action) => {
      //console.log("getAllProductsAction", action);
      // var updatedProductsData;
      // if (action && action.payload && action.payload.data && action.payload.data.length) {



      //   updatedProductsData = action.payload.data.map((x) => {

      //     if (x.productBusiness && x.productBusiness.length) {

      //       var y = x;
      //       y["productBusinessDetails"] = {};
      //       x.productBusiness.map((pb) => {

      //         //***********************/
      //         y["productBusinessDetails"][pb.businessId] = pb;

      //         //***********************/

      //         if (state.product.productBusinessList.length) {
      //           var currentBusiness = state.product.productBusinessList.filter((r) => r.businessId == pb.businessId);
      //           if (!currentBusiness.length) {
      //             state.product.productBusinessList.push(
      //               {
      //                 businessId: pb.businessId,
      //                 businessName: pb.businessName,
      //                 sellingPrice: pb.sellingPrice,
      //                 nightSellingPrice: pb.nightSellingPrice,
      //                 webSellingPrice: pb.webSellingPrice,
      //                 qty: pb.qty,
      //                 costPerCase: pb.costPerCase,
      //                 unitPerCase: pb.unitPerCase,
      //                 selected: pb.selected
      //               }
      //             );
      //           }
      //         }
      //         else {

      //           state.product.productBusinessList.push(
      //             {
      //               businessId: pb.businessId,
      //               businessName: pb.businessName,
      //               sellingPrice: pb.sellingPrice,
      //               nightSellingPrice: pb.nightSellingPrice,
      //               webSellingPrice: pb.webSellingPrice,
      //               qty: pb.qty,
      //               costPerCase: pb.costPerCase,
      //               unitPerCase: pb.unitPerCase,
      //               selected: pb.selected
      //             }
      //           );
      //         }

      //       })

      //       return x;

      //     }
      //     else {


      //       return x;
      //     }
      //   });
      // }

      //  console.log(updatedProductsData);
      // state.product.data.records = updatedProductsData;
      state.product.data.records = action.payload.data;
      state.product.apiMsg.status = action.meta.requestStatus;
      //state.product.apiMsg.message = action.payload.message.message;
    });
    builder.addCase(getAllProductsAction.pending, (state, action) => {
      //console.log("getAllProductsAction pending", action);
      state.product.data.records = null;
      state.product.apiMsg.message = action.meta.requestStatus;;
      state.product.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getAllProductsAction.rejected, (state, action) => {
      console.log("getAllProductsAction rejected", action);
      state.product.apiMsg.message = action.error.message;
      state.product.apiMsg.status = action.meta.requestStatus;
    });
    //------------------------------- getAllProductsAction end -------------------------
    //------------------------------- getProductsAction start -------------------------

    builder.addCase(
      getProductsAction.fulfilled,
      (state, action) => {
        //console.log("getProductsAction", action);

        state.selectedProduct.data = action.payload.data;
        state.selectedProduct.apiMsg.status = action.meta.requestStatus;
        state.selectedProduct.apiMsg.message = action.payload.message.message;

      }
    );
    builder.addCase(
      getProductsAction.pending,
      (state, action) => {
        //console.log("getProductsAction pending", action);
        state.selectedProduct.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      getProductsAction.rejected,
      (state, action) => {
        console.log("getProductsAction rejected", action);
        console.log(action.error.message);
        state.selectedProduct.apiMsg.message = action.error.message;
        state.selectedProduct.apiMsg.status = action.meta.requestStatus;
        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });
      }
    );

    //------------------------------- getProductsAction end -------------------------

    //------------------------------- insertUpdateProductAction start -------------------------

    builder.addCase(
      insertUpdateProductAction.fulfilled,
      (state, action) => {
        console.log("insertUpdateProductAction", action);

        state.insertUpdateProductDetails.data = action.payload.data.data;
        state.insertUpdateProductDetails.apiMsg.status = action.meta.requestStatus;
        state.insertUpdateProductDetails.apiMsg.message = action.payload.message.message;
        state.insertUpdateProductDetails.newRecord = action.meta.arg.id ? false : true;

        //   if (!action.payload.newRecord) {
        //     let updatedRecord = state.product.data.records.map(x => {
        //       if (x.id == action.payload.data.data.id) {
        //         return action.payload.data.data;
        //       }
        //       else {
        //         return x;
        //       }
        //     })

        //     state.product.data.records = updatedRecord;
        //   }
        //   else {
        //     if (state.product.data.records.length) {
        //       state.product.data.records.unshift(action.payload.data.data);
        //     }
        //     else {
        //       state.product.data.records.push(action.payload.data.data);
        //     }
        //   }

        //   var updatedProductsData;
        //   var tmpProductsData = JSON.parse(JSON.stringify(state.product.data));
        // if (tmpProductsData && tmpProductsData.records && tmpProductsData.records.length) {



        //   updatedProductsData = tmpProductsData.records.map((x) => {

        //     if (x.productBusiness && x.productBusiness.length) {

        //       var y = x;

        //       x.productBusiness.map((pb) => {

        //         //***********************/
        //         y[pb.businessId] = pb;

        //         //***********************/

        //         if (state.product.productBusinessList.length) {
        //           var currentBusiness = state.product.productBusinessList.filter((r) => r.businessId == pb.businessId);
        //           if (!currentBusiness.length) {
        //             state.product.productBusinessList.push(
        //               {
        //                 businessId: pb.businessId,
        //                 businessName: pb.businessName,
        //                 sellingPrice: pb.sellingPrice,
        //                 nightSellingPrice: pb.nightSellingPrice,
        //                 webSellingPrice: pb.webSellingPrice,
        //                 qty: pb.qty,
        //                 costPerCase: pb.costPerCase,
        //                 unitPerCase: pb.unitPerCase,
        //                 selected: pb.selected
        //               }
        //             );
        //           }
        //         }
        //         else {

        //           state.product.productBusinessList.push(
        //             {
        //               businessId: pb.businessId,
        //               businessName: pb.businessName,
        //               sellingPrice: pb.sellingPrice,
        //               nightSellingPrice: pb.nightSellingPrice,
        //               webSellingPrice: pb.webSellingPrice,
        //               qty: pb.qty,
        //               costPerCase: pb.costPerCase,
        //               unitPerCase: pb.unitPerCase,
        //               selected: pb.selected
        //             }
        //           );
        //         }

        //       })

        //       return x;

        //     }
        //     else {


        //       return x;
        //     }
        //   });
        // }

        // state.product.data.records = updatedProductsData;
        //console.log("state.product",state.product)
        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });

      }
    );
    builder.addCase(
      insertUpdateProductAction.pending,
      (state, action) => {
        //console.log("insertUpdateProductAction pending", action);
        state.insertUpdateProductDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      insertUpdateProductAction.rejected,
      (state, action) => {
        console.log("insertUpdateProductAction rejected", action);
        console.log(action.error.message);
        state.insertUpdateProductDetails.apiMsg.message = action.error.message;
        state.insertUpdateProductDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });
      }
    );

    //------------------------------- insertUpdateProductAction end -------------------------


    //------------------------------- activeInactiveAction start -------------------------

    builder.addCase(
      activeInactiveAction.fulfilled,
      (state, action) => {
        console.log("activeInactiveAction", action);

        state.activeInactiveDetails.data = action.payload.data.data;
        state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
        state.activeInactiveDetails.apiMsg.message = action.payload.message.message;

        let updatedRecord = state.product.data.records.map(x => {
          if (x.id == action.meta.arg.id) {
            x.isActive = action.meta.arg.isActive;
            return x;
          }
          else {
            return x;
          }
        })



        state.product.data.records = updatedRecord;

        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "success"
        });

      }
    );
    builder.addCase(
      activeInactiveAction.pending,
      (state, action) => {
        state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      activeInactiveAction.rejected,
      (state, action) => {
        state.activeInactiveDetails.apiMsg.message = action.error.message;
        state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "error"
        });
      }
    );

    //------------------------------- activeInactiveAction end -------------------------


    // get all mappedProduct action start

    builder.addCase(getMappedProductAction.fulfilled, (state, action) => {
      //console.log("getMappedProductAction",action);
      state.mappedProductDetails.data.records = action.payload.data;
      state.mappedProductDetails.apiMsg.status = action.meta.requestStatus;
      state.mappedProductDetails.apiMsg.message = "success";

    });
    builder.addCase(getMappedProductAction.pending, (state, action) => {
      // console.log("getMappedProductAction pending", action);
      state.mappedProductDetails.data.records = null;
      state.mappedProductDetails.apiMsg.message = action.meta.requestStatus;
      state.mappedProductDetails.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getMappedProductAction.rejected, (state, action) => {
      // console.log("getMappedProductAction rejected", action);
      state.mappedProductDetails.apiMsg.message = action.error.message;
      state.mappedProductDetails.apiMsg.status = action.meta.requestStatus;
    });

    // get all mappedProduct action end

    // get GetProductQtyHistory start

    builder.addCase(getProductQtyHistoryAction.fulfilled, (state, action) => {
      //console.log("GetProductQtyHistory",action);
      state.productQtyHistory.data.records = action.payload.data;
      state.productQtyHistory.apiMsg.status = action.meta.requestStatus;
      state.productQtyHistory.apiMsg.message = "success";

    });
    builder.addCase(getProductQtyHistoryAction.pending, (state, action) => {
      // console.log("GetProductQtyHistory pending", action);
      state.productQtyHistory.data.records = null;
      state.productQtyHistory.apiMsg.message = action.meta.requestStatus;
      state.productQtyHistory.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getProductQtyHistoryAction.rejected, (state, action) => {
      // console.log("GetProductQtyHistory rejected", action);
      state.productQtyHistory.apiMsg.message = action.error.message;
      state.productQtyHistory.apiMsg.status = action.meta.requestStatus;
    });

    // get all GetProductQtyHistory end

    // get all costProduct action start


    builder.addCase(getCostProductAction.fulfilled, (state, action) => {
      console.log("getCostProductAction", action);
      state.costProductDetails.data.records = action.payload.data;
      state.costProductDetails.apiMsg.status = action.meta.requestStatus;
      state.costProductDetails.apiMsg.message = "success";

    });
    builder.addCase(getCostProductAction.pending, (state, action) => {
      // console.log("getCostProductAction pending", action);
      state.costProductDetails.data.records = null;
      state.costProductDetails.apiMsg.message = action.meta.requestStatus;
      state.costProductDetails.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getCostProductAction.rejected, (state, action) => {
      // console.log("getMappedProductAction rejected", action);
      state.costProductDetails.apiMsg.message = action.error.message;
      state.costProductDetails.apiMsg.status = action.meta.requestStatus;
    });

    // get all costProduct action end





    // get all priceProduct action start


    builder.addCase(getPriceProductAction.fulfilled, (state, action) => {
      // console.log("getMappedProductAction",action);
      state.priceProductDetails.data.records = action.payload.data;
      state.priceProductDetails.apiMsg.status = action.meta.requestStatus;
      state.priceProductDetails.apiMsg.message = "success";

    });
    builder.addCase(getPriceProductAction.pending, (state, action) => {
      // console.log("getMappedProductAction pending", action);
      state.priceProductDetails.data.records = null;
      state.priceProductDetails.apiMsg.message = action.meta.requestStatus;
      state.priceProductDetails.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getPriceProductAction.rejected, (state, action) => {
      // console.log("getMappedProductAction rejected", action);
      state.priceProductDetails.apiMsg.message = action.error.message;
      state.priceProductDetails.apiMsg.status = action.meta.requestStatus;
    });

    // get all priceProduct action end



    //------------ getProductComparisonAction -----------

    builder.addCase(getProductComparisonAction.fulfilled, (state, action) => {
      state.selectedProductComparsion.data.records = action.payload.data;
      state.selectedProductComparsion.apiMsg.status = action.meta.requestStatus;
      state.selectedProductComparsion.apiMsg.message = action.payload.message.message;
    });

    builder.addCase(getProductComparisonAction.pending, (state, action) => {
      //console.log("getProductComparisonAction pending", action);
      state.selectedProductComparsion.data.records = null;
      state.selectedProductComparsion.apiMsg.message = action.meta.requestStatus;;
      state.selectedProductComparsion.apiMsg.status = action.meta.requestStatus;
    });

    builder.addCase(getProductComparisonAction.rejected, (state, action) => {
      // console.log("selectedProductComparsion rejected", action);
      state.selectedProductComparsion.apiMsg.message = action.error.message;
      state.selectedProductComparsion.apiMsg.status = action.meta.requestStatus;
    });


    //    selectedProductComparsion end



    //------------------------------- productApproveAction start -------------------------

    builder.addCase(
      productApproveAction.fulfilled,
      (state, action) => {

        state.approveDetails.data = action.payload.data.data;
        state.approveDetails.apiMsg.status = action.meta.requestStatus;
        state.approveDetails.apiMsg.message = action.payload.message.message;

        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "success"
        });

      }
    );
    builder.addCase(
      productApproveAction.pending,
      (state, action) => {
        state.approveDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      productApproveAction.rejected,
      (state, action) => {
        state.approveDetails.apiMsg.message = action.error.message;
        state.approveDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "error"
        });
      }
    );

    //------------------------------- productApproveAction end -------------------------

    //------------------------------- productRejectAction start -------------------------

    builder.addCase(
      productRejectAction.fulfilled,
      (state, action) => {

        state.rejectDetails.data = action.payload.data.data;
        state.rejectDetails.apiMsg.status = action.meta.requestStatus;
        state.rejectDetails.apiMsg.message = action.payload.message.message;

        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "success"
        });

      }
    );
    builder.addCase(
      productRejectAction.pending,
      (state, action) => {
        state.rejectDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      productRejectAction.rejected,
      (state, action) => {
        state.rejectDetails.apiMsg.message = action.error.message;
        state.rejectDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "error"
        });
      }
    );

    //------------------------------- productRejectAction end -------------------------

    //------------------------------- getproductbarcodeaction start -------------------------


    builder.addCase(getProductBarcodeAction.fulfilled, (state, action) => {
      state.barcode.data = action.payload.data;
      state.barcode.apiMsg.status = action.meta.requestStatus;
      state.barcode.apiMsg.message = action.payload.message.message;
    });

    builder.addCase(getProductBarcodeAction.pending, (state, action) => {
      state.barcode.data = null;
      state.barcode.apiMsg.message = action.meta.requestStatus;;
      state.barcode.apiMsg.status = action.meta.requestStatus;
    });

    builder.addCase(getProductBarcodeAction.rejected, (state, action) => {
      state.barcode.apiMsg.message = action.error.message;
      state.barcode.apiMsg.status = action.meta.requestStatus;
    });

    //------------------------------- getproductbarcodeaction end -------------------------

    //------------------------------- uploadproductaction start -------------------------


    builder.addCase(uploadProductAction.fulfilled, (state, action) => {
      state.uploadProductDetails.data = action.payload.data;
      state.uploadProductDetails.apiMsg.status = action.meta.requestStatus;
      state.uploadProductDetails.apiMsg.message = action.payload.message.message;
    });

    builder.addCase(uploadProductAction.pending, (state, action) => {
      state.uploadProductDetails.data = null;
      state.uploadProductDetails.apiMsg.message = action.meta.requestStatus;;
      state.uploadProductDetails.apiMsg.status = action.meta.requestStatus;
    });

    builder.addCase(uploadProductAction.rejected, (state, action) => {
      state.uploadProductDetails.apiMsg.message = action.error.message;
      state.uploadProductDetails.apiMsg.status = action.meta.requestStatus;
    });

    //------------------------------- uploadproductaction end -------------------------
    builder.addCase(ImportTempProductAction.fulfilled, (state, action) => {
      state.importProductDetails.data = action.payload.data;
      state.importProductDetails.apiMsg.status = action.meta.requestStatus;
      state.importProductDetails.apiMsg.message = action.payload.message.message;
    });

    builder.addCase(ImportTempProductAction.pending, (state, action) => {
      state.importProductDetails.data = null;
      state.importProductDetails.apiMsg.message = action.meta.requestStatus;;
      state.importProductDetails.apiMsg.status = action.meta.requestStatus;
    });

    builder.addCase(ImportTempProductAction.rejected, (state, action) => {
      state.importProductDetails.apiMsg.message = action.error.message;
      state.importProductDetails.apiMsg.status = action.meta.requestStatus;
    });

  },
});

export const { ResetProductDetails, ResetProductQtyHistory, ResetProductBarcodeDetails } = productReducer.actions;
export default productReducer.reducer;
