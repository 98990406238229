import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { getAllStockInAction, getStockInAction, getAllActiveBusinessData, getAllPurchaseOrderData, insertUpdateStockInAction,getStockInProductByBarcodeAction } from "../../actions/stock-in/stockInAction";

const INITIAL_STATE = {
    stockIn: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedStockIn: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    businessList: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    purchaseOrderList: {
        data: {
            records: null,
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    productBarcode: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
}

const stockInReducer = createSlice({
    name: "stockIn",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetStockInDetailsState(state, action) {
            state.selectedStockIn = INITIAL_STATE.selectedStockIn;
            state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
            state.productBarcode = INITIAL_STATE.productBarcode
        },
        ResetStockInBarcodeState(state, action) {
            state.productBarcode = INITIAL_STATE.productBarcode
        },

        
    },
    extraReducers: (builder) => {

        //  --------getAllStockInAction start--------------------

        builder.addCase(getAllStockInAction.fulfilled, (state, action) => {

            state.stockIn.data.records = action.payload.data;
            state.stockIn.apiMsg.status = action.meta.requestStatus;
            state.stockIn.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getAllStockInAction.pending, (state, action) => {

            state.stockIn.data.records = null;
            state.stockIn.apiMsg.message = action.meta.requestStatus;;
            state.stockIn.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllStockInAction.rejected, (state, action) => {

            state.stockIn.apiMsg.message = action.error.message;
            state.stockIn.apiMsg.status = action.meta.requestStatus;
        });

        //  -------- getAllStockInAction Action end--------------------

        //   getStockInAction start

        builder.addCase(getStockInAction.fulfilled, (state, action) => {
            state.selectedStockIn.data = action.payload.data
            state.selectedStockIn.apiMsg.status = action.meta.requestStatus;
            state.selectedStockIn.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getStockInAction.pending, (state, action) => {
            state.selectedStockIn.data = null;
            state.selectedStockIn.apiMsg.message = action.meta.requestStatus;;
            state.selectedStockIn.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getStockInAction.rejected, (state, action) => {
            state.selectedStockIn.apiMsg.message = action.error.message;
            state.selectedStockIn.apiMsg.status = action.meta.requestStatus;
        });


        //   getStockInAction end

        //------------------------------- getbusinesss start -------------------------

        builder.addCase(getAllActiveBusinessData.fulfilled, (state, action) => {
            state.businessList.data = action.payload.data;
            state.businessList.apiMsg.status = action.meta.requestStatus;
            state.businessList.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getAllActiveBusinessData.pending, (state, action) => {
            state.businessList.data = null;
            state.businessList.apiMsg.message = action.meta.requestStatus;;
            state.businessList.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getAllActiveBusinessData.rejected, (state, action) => {
            state.businessList.apiMsg.message = action.error.message;
            state.businessList.apiMsg.status = action.meta.requestStatus;
        });


        //   get business end

        //------------------------------- getAllPurchaseOrderData start -------------------------

        builder.addCase(getAllPurchaseOrderData.fulfilled, (state, action) => {
            state.purchaseOrderList.data.records = action.payload.data;
            state.purchaseOrderList.apiMsg.status = action.meta.requestStatus;

        });
        builder.addCase(getAllPurchaseOrderData.pending, (state, action) => {
            state.purchaseOrderList.data.records = null;
            state.purchaseOrderList.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllPurchaseOrderData.rejected, (state, action) => {
            state.purchaseOrderList.apiMsg.status = action.meta.requestStatus;
        });

        // insertUpdateStockInAction start

        builder.addCase(
            insertUpdateStockInAction.fulfilled,
            (state, action) => {

                state.insertUpdateDetails.data = action.payload.data.data;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateDetails.apiMsg.message = action.payload.message.message;

                // if (!action.payload.newRecord) {
                //     let updatedRecord = state.stockIn.data.records.map(x => {
                //         if (x.id == action.payload.data.data.id) {
                //             return action.payload.data.data;
                //         }
                //         else {
                //             return x;
                //         }
                //     })

                //     state.stockIn.data.records = updatedRecord;
                // }
                // else {
                //     if (state.stockIn.data.records.length) {
                //         state.stockIn.data.records.unshift(action.payload.data.data);
                //     }
                //     else {
                //         state.stockIn.data.records.push(action.payload.data.data);
                //     }
                // }
                
                if (action.payload.newRecord) {


                    if (state.stockIn.data.records.length) {
                        state.stockIn.data.records.unshift(action.payload.data.data);
                    }   
                    else {
                        state.stockIn.data.records.push(action.payload.data.data);
                    }
                }
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });

            }
        );
        builder.addCase(
            insertUpdateStockInAction.pending,
            (state, action) => {
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateStockInAction.rejected,
            (state, action) => {

                state.insertUpdateDetails.apiMsg.message = action.error.message;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );


        //   insert update supplier action end


          //------------------------------- getStockInProductByBarcodeAction start -------------------------

          builder.addCase(getStockInProductByBarcodeAction.fulfilled, (state, action) => {
            state.productBarcode.data = action.payload.data
            state.productBarcode.apiMsg.status = action.meta.requestStatus;
            state.productBarcode.apiMsg.message = action.payload.message.message;


        });

        builder.addCase(getStockInProductByBarcodeAction.pending, (state, action) => {
            state.productBarcode.data = null;
            state.productBarcode.apiMsg.message = action.meta.requestStatus;;
            state.productBarcode.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getStockInProductByBarcodeAction.rejected, (state, action) => {
            state.productBarcode.apiMsg.message = action.error.message;
            state.productBarcode.apiMsg.status = action.meta.requestStatus;
        });


        //   getStockInProductByBarcodeAction end


    },
});
export const { RESET, ResetStockInDetailsState,ResetStockInBarcodeState } = stockInReducer.actions;
export default stockInReducer.reducer;