import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";



export const getAllPromotionAction = createAsyncThunk(
  "Promotion/getall",
  async (data) => {
    data.searchText = data.searchText ? data.searchText : "";
    return apiCall("promotion?approved=" + data.approved + "&searchText=" + data.searchText, "POST", {}, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log('response.data ',response.data)
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getPromotion = createAsyncThunk(
  "Promotion/GetPromotion",
  async (data) => {
    return apiCall("promotion/getpromotion", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log('response.data ',response.data)
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);


export const insertUpdatePromotionAction = createAsyncThunk(
  "Promotion/insertupdatepromotion",
  async (data) => {
    return apiCall("promotion/add", "POST", data, false)
      .then((response) => {
        // console.log(response);
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log('response.data ',response.data.message)
            let message = response.data.message;
            response.data.data.newRecord = data.newRecord;

            return {
              data: {
                ...response.data
              },
              message: {
                code: response.data.message.code,
                type: response.data.message.type,
                message: response.data.message.message
              },
              newRecordCreated: data.id ? false : true
            }
          } else {
            if (!response.data.message && response.data.errors && response.data.errors.length) {
              response.data.message = response.data.errors[0];
            }
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
        else {
          return Promise.reject({
            ...response.data,
          });
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getAllPromotionTypes = createAsyncThunk(
  "Promotion/GetAllPromotionTypes",
  async (data) => {
    return apiCall("promotion/getallpromotiontypes", "GET", {}, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log('response.data ',response.data)
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const activeInactiveAction = createAsyncThunk(
  'Promotion/activeinactive',
  async (data) => {
    return apiCall("promotion/activeinactive", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
)
export const deleteAction = createAsyncThunk(
  'Promotion/Delete',
  async (data) => {
    return apiCall("promotion/delete", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
)
export const getPromotionComparisonAction = createAsyncThunk(
  "Promotion/GetProductComparison",
  async (data) => {
    return apiCall("promotion/getpromotioncomparison?id=" + data.id, "POST", null, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const promotionApproveAction = createAsyncThunk(
  "Promotion/Approve",
  async (data) => {
    return apiCall("promotion/approve?id=" + data.id, "POST", null, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const promotionRejectAction = createAsyncThunk(
  "Promotion/Reject",
  async (data) => {
    return apiCall("promotion/reject?id=" + data.id, "POST", null, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
