import { createSlice } from "@reduxjs/toolkit";
import { getAllSupplierProductAction, insertUpdateSupplierProductAction,getAllNotMappedProductAction,mappedSupplierProductAction } from "../../actions/supplier/supplierProductActions"
import { toast } from 'react-toastify';


const INITIAL_STATE = {
    supplierProduct: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    notMappedSupplierProduct: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    mappedSupplierProduct: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
};


const supplierProductReducer = createSlice({
    name: "SupplierProduct",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetSupplierProductState(state, action) {
            state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
            state.mappedSupplierProduct = INITIAL_STATE.mappedSupplierProduct;
        },

    },
    extraReducers: (builder) => {



        // get all supplierProduct action start


        builder.addCase(getAllSupplierProductAction.fulfilled, (state, action) => {
            // console.log("getAllSupplierProductAction",action);
            state.supplierProduct.data.records = action.payload.data;
            state.supplierProduct.apiMsg.status = action.meta.requestStatus;
            state.supplierProduct.apiMsg.message = "success";

        });
        builder.addCase(getAllSupplierProductAction.pending, (state, action) => {
            // console.log("getAllSupplierProductAction pending", action);
            state.supplierProduct.data.records = null;
            state.supplierProduct.apiMsg.message = action.meta.requestStatus;
            state.supplierProduct.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSupplierProductAction.rejected, (state, action) => {
            // console.log("getAllSupplierProductAction rejected", action);
            state.supplierProduct.apiMsg.message = action.error.message;
            state.supplierProduct.apiMsg.status = action.meta.requestStatus;
        });

        // get all supplierProduct action end

         // get all not mapped supplierProduct action start


         builder.addCase(getAllNotMappedProductAction.fulfilled, (state, action) => {
            // console.log("getAllSupplierProductAction",action);
            state.notMappedSupplierProduct.data.records = action.payload.data;
            state.notMappedSupplierProduct.apiMsg.status = action.meta.requestStatus;
            state.notMappedSupplierProduct.apiMsg.message = "success";

        });
        builder.addCase(getAllNotMappedProductAction.pending, (state, action) => {
            // console.log("getAllSupplierProductAction pending", action);
            state.notMappedSupplierProduct.data.records = null;
            state.notMappedSupplierProduct.apiMsg.message = action.meta.requestStatus;
            state.notMappedSupplierProduct.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllNotMappedProductAction.rejected, (state, action) => {
            // console.log("getAllSupplierProductAction rejected", action);
            state.notMappedSupplierProduct.apiMsg.message = action.error.message;
            state.notMappedSupplierProduct.apiMsg.status = action.meta.requestStatus;
        });

        // get all supplierProduct action end


        // insert update supplier product Action start

        builder.addCase(
            insertUpdateSupplierProductAction.fulfilled,
            (state, action) => {
               //console.log("insertUpdateSupplierProductAction", action);

                state.insertUpdateDetails.data = action.payload.data.data;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateDetails.apiMsg.message = action.payload.message.message;

                if (!action.payload.newRecord) {
                    let updatedRecord = state.supplierProduct.data.records.map(x => {
                        if (x.id == action.payload.data.data.id) {
                            return action.payload.data.data;
                        }
                        else {
                            return x;
                        }
                    })

                     state.supplierProduct.data.records = updatedRecord;
                }
                else {
                    if (state.supplierProduct.data.records.length) {
                        state.supplierProduct.data.records.unshift(action.payload.data.data);
                    }
                    else {
                        state.supplierProduct.data.records.push(action.payload.data.data);
                    }
                }
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });

            }
        );
        builder.addCase(
            insertUpdateSupplierProductAction.pending,
            (state, action) => {
                // console.log("insertUpdateSupplierProductAction pending", action);
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateSupplierProductAction.rejected,
            (state, action) => {
                //   console.log("insertUpdateSupplierProductAction rejected", action);
                //   console.log(action.error.message);
                state.insertUpdateDetails.apiMsg.message = action.error.message;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );


        //   insert Update SupplierProduct Action end

         // mapped supplier product Action start

         builder.addCase(
            mappedSupplierProductAction.fulfilled,
            (state, action) => {
               //console.log("insertUpdateSupplierProductAction", action);

                state.mappedSupplierProduct.data = action.payload.data.data;
                state.mappedSupplierProduct.apiMsg.status = action.meta.requestStatus;
                state.mappedSupplierProduct.apiMsg.message = action.payload.message.message;

               
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });

            }
        );
        builder.addCase(
            mappedSupplierProductAction.pending,
            (state, action) => {
                // console.log("insertUpdateSupplierProductAction pending", action);
                state.mappedSupplierProduct.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            mappedSupplierProductAction.rejected,
            (state, action) => {
                //   console.log("insertUpdateSupplierProductAction rejected", action);
                //   console.log(action.error.message);
                state.mappedSupplierProduct.apiMsg.message = action.error.message;
                state.mappedSupplierProduct.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );


        //   insert mapped SupplierProduct Action end




    },
});
export const { RESET, ResetSupplierProductState } = supplierProductReducer.actions;
export default supplierProductReducer.reducer;