import { createSlice } from "@reduxjs/toolkit";

import { getAllVatAction } from "../../actions/shop-settings/vatActions";
import { insertUpdateVatAction } from "../../actions/shop-settings/vatActions";

import { toast } from 'react-toastify';

const INITIAL_STATE = {
    vat: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateDetails: {
        data: {},
    
        apiMsg: {
          message: null,
          status: null,
          type: null,
        },
        newRecord: false
      },
    };


const vatReducer = createSlice({
    name: "Vat",
    initialState: INITIAL_STATE,
    reducers: {

          RESET(state, action) {
            state = INITIAL_STATE;
          },
          ResetVatDetailsState(state, action) {
            state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
            state.selectedVat = INITIAL_STATE.selectedVat;
          },
    },
    extraReducers: (builder) => {



                            // get all vat action start


        builder.addCase(getAllVatAction.fulfilled, (state, action) => {
            // console.log("getVatAction",action);
            state.vat.data.records = action.payload.data;
            state.vat.apiMsg.status = action.meta.requestStatus;
            state.vat.apiMsg.message = "success";
            // not working above  line show error
        });
        builder.addCase(getAllVatAction.pending, (state, action) => {
            // console.log("getVatAction pending", action);
            state.vat.data.records = null;
            state.vat.apiMsg.message = action.meta.requestStatus;
            state.vat.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllVatAction.rejected, (state, action) => {
            // console.log("getVatAction rejected", action);
            state.vat.apiMsg.message = action.error.message;
            state.vat.apiMsg.status = action.meta.requestStatus;
        });

                            // get all vat action end


            // insert update vat Action start

            builder.addCase(
                insertUpdateVatAction.fulfilled,
                (state, action) => {
                //console.log("insertUpdateVatAction", action);
          
                  state.insertUpdateDetails.data = action.payload.data.data;
                  state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
                  state.insertUpdateDetails.apiMsg.message = action.payload.message.message;
          
                  if (!action.payload.newRecord) {
                    let updatedRecord = state.vat.data.records.map(x => {
                      if (x.id == action.payload.data.data.id) {
                        return action.payload.data.data;
                      }
                      else {
                        return x;
                      }
                    })
          
                    state.vat.data.records = updatedRecord;
                  }
                  else {
                    if (state.vat.data.records.length) {
                      state.vat.data.records.unshift(action.payload.data.data);
                    }
                    else {
                      state.vat.data.records.push(action.payload.data.data);
                    }
                  }
                  toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                  });
                  
                }
              );
              builder.addCase(
                insertUpdateVatAction.pending,
                (state, action) => {
                  //console.log("insertUpdateVatAction pending", action);
                  state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
                }
              );
              builder.addCase(
                insertUpdateVatAction.rejected,
                (state, action) => {
                //   console.log("insertUpdateVatAction rejected", action);
                //   console.log(action.error.message);
                  state.insertUpdateDetails.apiMsg.message = action.error.message;
                  state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
          
                  toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                  });
                }
              );


            //   insert update vat action end

    },
});
export const { RESET,ResetVatDetailsState} = vatReducer.actions;
export default vatReducer.reducer;