import React from 'react';
import { BrowserRouter} from "react-router-dom";
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './app/store';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css'
import './Style.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  
    <Provider store={store}>
    <BrowserRouter>
      <App />
      <ToastContainer/>
     </BrowserRouter>
    </Provider>

);