import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";
import { toast } from "react-toastify";


export const getAllPosParameterAction = createAsyncThunk(

  "PosParameter/GetAllPosParameter",
  async (data) => {
    return apiCall("business/getactive", "GET", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {

            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getPosParameterAction = createAsyncThunk(
  "PosParameter/GetPosParameter",
  async (data) => {
    return apiCall(`businesssetting?id=${data.id}`, "GET", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const updatePOSParameterAction = createAsyncThunk(
  "PosParameter/UpdatePosParameter",
  async (data) => {
    return apiCall("businesssetting/add", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            console.log("01",response.data);
            toast.success(response.data.message.message, {
              position: toast.POSITION.BOTTOM_CENTER,
  
            });
            return {
              data: {
                ...response.data,
              },
              message: {
                code: response.data.message.code,
                type: response.data.message.type,
                message: response.data.message.message
              },
              // newRecord: data.id ? false : true
            }
          } else {
            if (!response.data.message && response.data.errors && response.data.errors.length) {
              response.data.message = response.data.errors[0];
            }
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
        else {
          return Promise.reject({
            ...response.data,
          });
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);