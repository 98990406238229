import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";

export const getAllVatAction = createAsyncThunk(

    "Vat/GetAllVat",
    async (data) => {
      return apiCall("vat", "GET",data, false)
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.hasError) {
            
              return {
                ...response.data,
              }
            } else {
              console.log("response error", response.data.message);
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
        })
        .catch((error) => {
          return Promise.reject({
            ...error,
          });
        });
    }
  );


  export const insertUpdateVatAction = createAsyncThunk(
    "Vat/insertupdatevat",
    async (data) => {
      // console.log("api action insert",data)
      return apiCall("vat/add", "POST", data, false)
        .then((response) => {
           console.log("api response",response);
          if (response.status === 200) {
            if (!response.data.hasError) {
              // console.log('response.data ',response.data.message)
              let message = response.data.message
              // console.log("message",message);
              return {
               data:{
                ...response.data,
              },
              message:{
                code:response.data.message.code,
                type:response.data.message.type,
                message:response.data.message.message
              },
              newRecord:data.id ? false : true
              }
            } else {
              // console.log("response error", response.data.message);
              if(!response.data.message && response.data.errors && response.data.errors.length){
                response.data.message=response.data.errors[0];
              }
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
          else{
            return Promise.reject({
              ...response.data,
            });
          }
        })
        .catch((error) => {
          return Promise.reject({
            ...error,
          });
        });
    }
  );