import { createSlice } from "@reduxjs/toolkit";
import { getAllPosParameterAction, getPosParameterAction, updatePOSParameterAction } from "../../actions/shop-settings/posParameterActions";


const INITIAL_STATE = {
    posParameter: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedPosParameter: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    }

};


const posParameterReducer = createSlice({
    name: "PosParameter",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetPosParameterDetailsState(state, action) {
            state.PosParameterDetails = INITIAL_STATE.PosParameterDetails;
            state.selectedPosParameter = INITIAL_STATE.selectedPosParameter;
            state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;

        },
    },
    extraReducers: (builder) => {

        // get all PosParameter action start


        builder.addCase(getAllPosParameterAction.fulfilled, (state, action) => {
            state.posParameter.data.records = action.payload.data;
            state.posParameter.apiMsg.status = action.meta.requestStatus;
            state.posParameter.apiMsg.message = "success";
        });
        builder.addCase(getAllPosParameterAction.pending, (state, action) => {
            state.posParameter.data.records = null;
            state.posParameter.apiMsg.message = action.meta.requestStatus;
            state.posParameter.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllPosParameterAction.rejected, (state, action) => {
            state.posParameter.apiMsg.message = action.error.message;
            state.posParameter.apiMsg.status = action.meta.requestStatus;
        });

        // get all pos parameter action end

        //------------------------------- getposparameter start -------------------------

        builder.addCase(getPosParameterAction.fulfilled, (state, action) => {
            state.selectedPosParameter.data = action.payload.data;
            state.selectedPosParameter.apiMsg.status = action.meta.requestStatus;
            state.selectedPosParameter.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getPosParameterAction.pending, (state, action) => {
            //console.log("getPosParameterAction pending", action);
            state.selectedPosParameter.data = null;
            state.selectedPosParameter.apiMsg.message = action.meta.requestStatus;;
            state.selectedPosParameter.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getPosParameterAction.rejected, (state, action) => {
            // console.log("getPosParameterAction rejected", action);
            state.selectedPosParameter.apiMsg.message = action.error.message;
            state.selectedPosParameter.apiMsg.status = action.meta.requestStatus;
        });


        //   get posparameter end

        // insertUpdatePOSparameter start

        builder.addCase(updatePOSParameterAction.fulfilled, (state, action) => {
            state.insertUpdateDetails.data = action.payload.data;
            state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
            state.insertUpdateDetails.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(updatePOSParameterAction.pending, (state, action) => {
            //console.log("getPosParameterAction pending", action);
            state.insertUpdateDetails.data = null;
            state.insertUpdateDetails.apiMsg.message = action.meta.requestStatus;;
            state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(updatePOSParameterAction.rejected, (state, action) => {
            // console.log("getPosParameterAction rejected", action);
            state.insertUpdateDetails.apiMsg.message = action.error.message;
            state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
        });


        // insertUpdatePOSparameter end



    },
});
export const { RESET, ResetPosParameterDetailsState } = posParameterReducer.actions;
export default posParameterReducer.reducer;