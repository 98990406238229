import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';


import { getAllActiveBusinessData,insertUpdateStockAdjustementsAction} from "../../actions/stock-out-manage/stockAdustementsAction";

const INITIAL_STATE = {


    businessList: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    insertUpdateDetails: {
        data: {},
    
        apiMsg: {
          message: null,
          status: null,
          type: null,
        },
        newRecord: false
      }

    
}

const stockAdjustementsReducer = createSlice({
    name: "StockAdjustements",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetStockAdjustementsDetailsState(state, action) {
          state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
        },
    },
    extraReducers: (builder) => {


        //------------------------------- getbusinesss start -------------------------

        builder.addCase(getAllActiveBusinessData.fulfilled, (state, action) => {
            console.log("action",action)
            state.businessList.data = action.payload.data;
            // console.log("state.selectedBusiness.data",state.selectedBusiness.data)
            state.businessList.apiMsg.status = action.meta.requestStatus;
            state.businessList.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getAllActiveBusinessData.pending, (state, action) => {
            //console.log("getPosUser pending", action);
            state.businessList.data = null;
            state.businessList.apiMsg.message = action.meta.requestStatus;;
            state.businessList.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getAllActiveBusinessData.rejected, (state, action) => {
            // console.log("getPosUser rejected", action);
            state.businessList.apiMsg.message = action.error.message;
            state.businessList.apiMsg.status = action.meta.requestStatus;
        });


        //   get business end






           // insert update stock adjsutement Action start

        builder.addCase(
            insertUpdateStockAdjustementsAction.fulfilled,
            (state, action) => {
            // console.log("insertUpdateStockAdjustementsAction", action);

              state.insertUpdateDetails.data = action.payload.data.data;
              state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
              state.insertUpdateDetails.apiMsg.message = action.payload.message.message;

            //   if (!action.payload.newRecord) {
            //     let updatedRecord = state.supplier.data.records.map(x => {
            //       if (x.id == action.payload.data.data.id) {
            //         return action.payload.data.data;
            //       }
            //       else {
            //         return x;
            //       }
            //     })

            //     state.supplier.data.records = updatedRecord;
            //   }
            //   else {
            //     if (state.supplier.data.records.length) {
            //       state.supplier.data.records.unshift(action.payload.data.data);
            //     }
            //     else {
            //       state.supplier.data.records.push(action.payload.data.data);
            //     }
            //   }
              toast.success(action.payload.message.message, {
                position: toast.POSITION.BOTTOM_CENTER,
                toastId: "dept-error"
              });

            }
          );
          builder.addCase(
            insertUpdateStockAdjustementsAction.pending,
            (state, action) => {
              //console.log("insertUpdatesupplierAction pending", action);
              state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
            }
          );
          builder.addCase(
            insertUpdateStockAdjustementsAction.rejected,
            (state, action) => {
            //   console.log("insertUpdatesupplierAction rejected", action);
            //   console.log(action.error.message);
              state.insertUpdateDetails.apiMsg.message = action.error.message;
              state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;

              toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                position: toast.POSITION.BOTTOM_CENTER,
                toastId: "dept-error"
              });
            }
          );


        //   insert update stock adjsutement action end


    },
});

export const { RESET,ResetStockAdjustementsDetailsState} = stockAdjustementsReducer.actions;
export default stockAdjustementsReducer.reducer;