import { createSlice } from "@reduxjs/toolkit";

import {
  getAllManualPriceProductAction,
  getManualPriceProductAction,
  getProductComparisonAction,
  insertUpdateManualPriceProductAction,
  getDisplayGroupProductAction,
  getPriceGroupProductAction,
  activeInactiveAction,
  manualPriceProductApproveAction,
  manualPriceProductRejectAction
} from "../../actions/inventory/manualPriceProductActions";

import { toast } from 'react-toastify';

const INITIAL_STATE = {
  manualPriceProduct: {
    data: {
      records: [],
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  insertUpdateDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
    newRecord: false
  },
  selectedManualPriceProduct: {
    data: null,
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  selectedManualPriceComparsionProduct: {
    data: {
      records: null,
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },

  selectedDisplayGroup: {
    data: {
      records: null,
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },

  selectedPriceGroup: {
    data: {
      records: null,
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },

  activeInactiveDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
    newRecord: false
  },
  approveDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    }
  },
  rejectDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    }
  }
}

const manualPriceProductReducer = createSlice({
  name: "ManualPriceProduct",
  initialState: INITIAL_STATE,
  reducers: {
    RESET(state, action) {
      state = INITIAL_STATE;
    },
    ResetManualPriceProductCompareDetailsState(state, action){
      state.selectedManualPriceComparsionProduct = INITIAL_STATE.selectedManualPriceComparsionProduct;
    },
    ResetManualPriceProductDetailsState(state, action) {
      state.selectedManualPriceProduct = INITIAL_STATE.selectedManualPriceProduct;
      state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
      state.approveDetails = INITIAL_STATE.approveDetails;
      state.rejectDetails = INITIAL_STATE.rejectDetails;
    },
  },
  extraReducers: (builder) => {

    builder.addCase(getAllManualPriceProductAction.fulfilled, (state, action) => {
      state.manualPriceProduct.data.records = action.payload.data;
      state.manualPriceProduct.apiMsg.status = action.meta.requestStatus;
      state.manualPriceProduct.apiMsg.message = action.payload.message.message;
    });
    builder.addCase(getAllManualPriceProductAction.pending, (state, action) => {
      state.manualPriceProduct.data.records = null;
      state.manualPriceProduct.apiMsg.message = action.meta.requestStatus;;
      state.manualPriceProduct.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getAllManualPriceProductAction.rejected, (state, action) => {
      console.log("getAllManualPriceProductAction rejected", action);
      state.manualPriceProduct.apiMsg.message = action.error.message;
      state.manualPriceProduct.apiMsg.status = action.meta.requestStatus;
    });

    // insert update manualPriceProduct Action start

    builder.addCase(
      insertUpdateManualPriceProductAction.fulfilled,
      (state, action) => {
        //console.log("insertUpdateManualPriceProductAction.fulfilled",action);
        state.insertUpdateDetails.data = action.payload.data.data;
        state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
        state.insertUpdateDetails.apiMsg.message = action.payload.message.message;

        if (!action.payload.newRecord) {
          let updatedRecord = state.manualPriceProduct.data.records.map(x => {
            if (x.id == action.payload.data.data.id) {
              return action.payload.data.data;
            }
            else {
              return x;
            }
          })

          state.manualPriceProduct.data.records = updatedRecord;
        }
        else {
          if (state.manualPriceProduct.data.records.length) {
            //console.log("unshift record",state.manualPriceProduct.data.records);
            state.manualPriceProduct.data.records.unshift(action.payload.data.data);
          }
          else {
            //console.log("shift record");
            state.manualPriceProduct.data.records.push(action.payload.data.data);
          }
        }
        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });

      }
    );
    builder.addCase(
      insertUpdateManualPriceProductAction.pending,
      (state, action) => {
        //console.log("insertUpdateManualPriceProductAction pending", action);
        state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      insertUpdateManualPriceProductAction.rejected,
      (state, action) => {
        //   console.log("insertUpdateManualPriceProductAction rejected", action);
        state.insertUpdateDetails.apiMsg.message = action.error.message;
        state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });
      }
    );


    //   insert update manualPriceProduct action end


    //------------ getmanualPriceProductstart -----------

    builder.addCase(getManualPriceProductAction.fulfilled, (state, action) => {
      state.selectedManualPriceProduct.data = action.payload.data;
      state.selectedManualPriceProduct.apiMsg.status = action.meta.requestStatus;
      state.selectedManualPriceProduct.apiMsg.message = action.payload.message.message;
    });

    builder.addCase(getManualPriceProductAction.pending, (state, action) => {
      //console.log("getManualPriceProductAction pending", action);
      state.selectedManualPriceProduct.data = null;
      state.selectedManualPriceProduct.apiMsg.message = action.meta.requestStatus;;
      state.selectedManualPriceProduct.apiMsg.status = action.meta.requestStatus;
    });

    builder.addCase(getManualPriceProductAction.rejected, (state, action) => {
      // console.log("getManualPriceProductAction rejected", action);
      state.selectedManualPriceProduct.apiMsg.message = action.error.message;
      state.selectedManualPriceProduct.apiMsg.status = action.meta.requestStatus;
    });


    //   get manualPriceProduct end

    //------------ getProductComparisonAction -----------

    builder.addCase(getProductComparisonAction.fulfilled, (state, action) => {
      state.selectedManualPriceComparsionProduct.data.records = action.payload.data;
      state.selectedManualPriceComparsionProduct.apiMsg.status = action.meta.requestStatus;
      state.selectedManualPriceComparsionProduct.apiMsg.message = action.payload.message.message;
    });

    builder.addCase(getProductComparisonAction.pending, (state, action) => {
      //console.log("getProductComparisonAction pending", action);
      state.selectedManualPriceComparsionProduct.data.records = null;
      state.selectedManualPriceComparsionProduct.apiMsg.message = action.meta.requestStatus;;
      state.selectedManualPriceComparsionProduct.apiMsg.status = action.meta.requestStatus;
    });

    builder.addCase(getProductComparisonAction.rejected, (state, action) => {
      // console.log("getManualPriceProductAction rejected", action);
      state.selectedManualPriceComparsionProduct.apiMsg.message = action.error.message;
      state.selectedManualPriceComparsionProduct.apiMsg.status = action.meta.requestStatus;
    });


    //   get getProductComparisonAction end



    //   insert update manualPriceProduct action end


    //------------ getdisplaygroupstart -----------

    builder.addCase(getDisplayGroupProductAction.fulfilled, (state, action) => {
      state.selectedDisplayGroup.data.records = action.payload.data;
      state.selectedDisplayGroup.apiMsg.status = action.meta.requestStatus;
      state.selectedDisplayGroup.apiMsg.message = action.payload.message.message;
    });

    builder.addCase(getDisplayGroupProductAction.pending, (state, action) => {
      //console.log("getDisplayGroupProductAction pending", action);
      state.selectedDisplayGroup.data.records = null;
      state.selectedDisplayGroup.apiMsg.message = action.meta.requestStatus;;
      state.selectedDisplayGroup.apiMsg.status = action.meta.requestStatus;
    });

    builder.addCase(getDisplayGroupProductAction.rejected, (state, action) => {
      // console.log("getDisplayGroupProductAction rejected", action);
      state.selectedDisplayGroup.apiMsg.message = action.error.message;
      state.selectedDisplayGroup.apiMsg.status = action.meta.requestStatus;
    });


    //   get displaygroup end



    //------------ getpricegroupstart -----------

    builder.addCase(getPriceGroupProductAction.fulfilled, (state, action) => {
      state.selectedPriceGroup.data.records = action.payload.data;
      state.selectedPriceGroup.apiMsg.status = action.meta.requestStatus;
      state.selectedPriceGroup.apiMsg.message = action.payload.message.message;
    });

    builder.addCase(getPriceGroupProductAction.pending, (state, action) => {
      //console.log("getPriceGroupProductAction pending", action);
      state.selectedPriceGroup.data.records = null;
      state.selectedPriceGroup.apiMsg.message = action.meta.requestStatus;;
      state.selectedPriceGroup.apiMsg.status = action.meta.requestStatus;
    });

    builder.addCase(getPriceGroupProductAction.rejected, (state, action) => {
      // console.log("getPriceGroupProductAction rejected", action);
      state.selectedPriceGroup.apiMsg.message = action.error.message;
      state.selectedPriceGroup.apiMsg.status = action.meta.requestStatus;
    });


    //   get pricegroup end





    //------------------------------- activeInactiveAction start -------------------------

    builder.addCase(
      activeInactiveAction.fulfilled,
      (state, action) => {
        console.log("activeInactiveAction", action);

        state.activeInactiveDetails.data = action.payload.data.data;
        state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
        state.activeInactiveDetails.apiMsg.message = action.payload.message.message;

        let updatedRecord = state.manualPriceProduct.data.records.map(x => {
          if (x.id == action.meta.arg.id) {
            x.isActive = action.meta.arg.isActive;
            return x;
          }
          else {
            return x;
          }
        })



        state.manualPriceProduct.data.records = updatedRecord;

        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "success"
        });

      }
    );
    builder.addCase(
      activeInactiveAction.pending,
      (state, action) => {
        state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      activeInactiveAction.rejected,
      (state, action) => {
        state.activeInactiveDetails.apiMsg.message = action.error.message;
        state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "error"
        });
      }
    );

    //------------------------------- activeInactiveAction end -------------------------

    //------------------------------- manualPriceProductApproveAction start -------------------------

    builder.addCase(
      manualPriceProductApproveAction.fulfilled,
      (state, action) => {
        console.log("manualPriceProductApproveAction", action);

        state.approveDetails.data = action.payload.data.data;
        state.approveDetails.apiMsg.status = action.meta.requestStatus;
        state.approveDetails.apiMsg.message = action.payload.message.message;

        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "success"
        });

      }
    );
    builder.addCase(
      manualPriceProductApproveAction.pending,
      (state, action) => {
        state.approveDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      manualPriceProductApproveAction.rejected,
      (state, action) => {
        state.approveDetails.apiMsg.message = action.error.message;
        state.approveDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "error"
        });
      }
    );

    //------------------------------- manualPriceProductApproveAction end -------------------------

    //------------------------------- manualPriceProductRejectAction start -------------------------

    builder.addCase(
      manualPriceProductRejectAction.fulfilled,
      (state, action) => {
        console.log("manualPriceProductRejectAction", action);

        state.rejectDetails.data = action.payload.data.data;
        state.rejectDetails.apiMsg.status = action.meta.requestStatus;
        state.rejectDetails.apiMsg.message = action.payload.message.message;

        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "success"
        });

      }
    );
    builder.addCase(
      manualPriceProductRejectAction.pending,
      (state, action) => {
        state.rejectDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      manualPriceProductRejectAction.rejected,
      (state, action) => {
        state.rejectDetails.apiMsg.message = action.error.message;
        state.rejectDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "error"
        });
      }
    );

    //------------------------------- manualPriceProductRejectAction end -------------------------
  },
});
export const { RESET, ResetManualPriceProductDetailsState,ResetManualPriceProductCompareDetailsState } = manualPriceProductReducer.actions;
export default manualPriceProductReducer.reducer;

