import { createSlice } from "@reduxjs/toolkit";
import {
  getAllUserRolesAction,
  insertUpdateUserRolesAction,
  getUserRolesAction
} from "../../actions/manage-settings/headofficeUserRolesActions";
import { toast } from 'react-toastify';
const INITIAL_STATE = {
  userRolesList: {
    data: {
      records: [],
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  userRoles: {
    data: {
      records: [],
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  insertUpdateUserRoles: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
    newRecord: false
  },
};

const headofficeUserRolesReducer = createSlice({
  name: "HeadofficeUserRoles",
  initialState: INITIAL_STATE,
  reducers: {

    RESET(state, action) {
      state = INITIAL_STATE;
    },
    ResetUserRolesState(state, action) {
      state.insertUpdateUserRoles = INITIAL_STATE.insertUpdateUserRoles;
      state.userRoles = INITIAL_STATE.userRoles;
    },
  },
  extraReducers: (builder) => {
    //------------------------------- getAllBrandsAction start -------------------------
    // eslint-disable-next-line
    builder.addCase(getAllUserRolesAction.fulfilled, (state, action) => {
      //console.log("getAllBrandsAction", action);
      state.userRolesList.data.records = action.payload.data;
      state.userRolesList.apiMsg.status = action.meta.requestStatus;
      state.userRolesList.apiMsg.message = action.payload.message.message;
    });
    builder.addCase(getAllUserRolesAction.pending, (state, action) => {
      //console.log("getAllBrandsAction pending", action);
      state.userRolesList.data.records = null;
      state.userRolesList.apiMsg.message = action.meta.requestStatus;;
      state.userRolesList.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getAllUserRolesAction.rejected, (state, action) => {
      console.log("getAllUserRolesAction rejected", action);
      state.userRolesList.apiMsg.message = action.error.message;
      state.userRolesList.apiMsg.status = action.meta.requestStatus;
    });
    //------------------------------- getAllBrandsAction end -------------------------

    //------------------------------- insertUpdateUserRoles start -------------------------

    builder.addCase(
      insertUpdateUserRolesAction.fulfilled,
      (state, action) => {
        //console.log("insertUpdateUserRoles", action);

        state.insertUpdateUserRoles.data = action.payload.data.data;
        state.insertUpdateUserRoles.apiMsg.status = action.meta.requestStatus;
        state.insertUpdateUserRoles.apiMsg.message = action.payload.message.message;

        if (!action.payload.newRecord) {
          let updatedRecord = state.userRolesList.data.records.map(x => {
            if (x.id == action.payload.data.data.id) {
              return action.payload.data.data;
            }
            else {
              return x;
            }
          })

          state.userRolesList.data.records = updatedRecord;
        }
        else {
          if (state.userRolesList.data.records.length) {
            state.userRolesList.data.records.unshift(action.payload.data.data);
          }
          else {
            state.userRolesList.data.records.push(action.payload.data.data);
          }
        }
        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });

      }
    );
    builder.addCase(
      insertUpdateUserRolesAction.pending,
      (state, action) => {
        //console.log("insertUpdateBrandAction pending", action);
        state.insertUpdateUserRoles.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      insertUpdateUserRolesAction.rejected,
      (state, action) => {
        console.log("insertUpdateUserRoles rejected", action);
        console.log(action.error.message);
        state.insertUpdateUserRoles.apiMsg.message = action.error.message;
        state.insertUpdateUserRoles.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });
      }
    );

    //------------------------------- insertUpdateUserRoles end -------------------------

     //------------------------------- getUserRolesAction start -------------------------
    // eslint-disable-next-line
    builder.addCase(getUserRolesAction.fulfilled, (state, action) => {
      //console.log("getAllBrandsAction", action);
      state.userRoles.data.records = action.payload.data;
      state.userRoles.apiMsg.status = action.meta.requestStatus;
      state.userRoles.apiMsg.message = action.payload.message.message;
    });
    builder.addCase(getUserRolesAction.pending, (state, action) => {
      //console.log("getAllBrandsAction pending", action);
      state.userRoles.data.records = null;
      state.userRoles.apiMsg.message = action.meta.requestStatus;;
      state.userRoles.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getUserRolesAction.rejected, (state, action) => {
      console.log("getAllUserRolesAction rejected", action);
      state.userRoles.apiMsg.message = action.error.message;
      state.userRoles.apiMsg.status = action.meta.requestStatus;
    });
    //------------------------------- getUserRolesAction end -------------------------

  },
});

export const { RESET, ResetUserRolesState } = headofficeUserRolesReducer.actions;
export default headofficeUserRolesReducer.reducer;
