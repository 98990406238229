import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";
export const getAllNewsPapersAction = createAsyncThunk(
  "Newspapers/GetAllNews",
  async (data) => {
    return apiCall(`publication/getall${data.query}`, "GET", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const getNewsPapersAction = createAsyncThunk(
  "Newspapers/GetNewspapers",
  async (data) => {
    return apiCall("publication/getpublicationnewspapers", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const getPriceGroupProductAction = createAsyncThunk(
  "Newspapers/GetDisplayManualPriceProduct",
  async (data) => {
    return apiCall("productgroup/getactive", "GET", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const getFrequencyTypeAction = createAsyncThunk(
  "Newspapers/GetFrequencyType",
  async (data) => {
    return apiCall("publication/getpublicationoccurrencetypes", "GET", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const insertUpdateNewsPaperAction = createAsyncThunk(
  "Newspapers/insertUpdateNewsPaper",
  async (data) => {

    return apiCall("publication/add", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            let message = response.data.message
            return {
              data: {
                ...response.data,
              },
              message: {
                code: response.data.message.code,
                type: response.data.message.type,
                message: response.data.message.message
              },
              newRecord: data.id ? false : true
            }
          } else {
            console.log("response error", response.data.message);
            if (!response.data.message && response.data.errors && response.data.errors.length) {
              response.data.message = response.data.errors[0];
            }
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
        else {
          return Promise.reject({
            ...response.data,
          });
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const activeInactiveAction = createAsyncThunk(
  'Newspapers/activeinactive',
  async (data) => {
    return apiCall("publication/activeinactive", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
)

export const getNewspaperComparisonAction = createAsyncThunk(
  "Newspapers/GetProductComparison",
  async (data) => {
    return apiCall("publication/getpublicationComparison?id=" + data.id, "POST", null, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const newspaperApproveAction = createAsyncThunk(
  "Newspapers/Approve",
  async (data) => {
    return apiCall("publication/approve?id=" + data.id, "POST", null, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const newspaperRejectAction = createAsyncThunk(
  "Newspapers/Reject",
  async (data) => {
    return apiCall("publication/reject?id=" + data.id, "POST", null, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);


export const syncNewspaperAction = createAsyncThunk(
  "Newspapers/syncNewspaperAction",
  async (data) => {
    return apiCall("publication/DownloadPublication", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);