import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';

import { getAllStockTransferToOtherShopAction, getAllActiveBusinessData, insertUpdateTransferToOtherShopAction, getStockTransferToOtherShopAction, getAllStockTransferToOtherShopByBarcodeAction } from "../../actions/stock-out-manage/stockTransferToOtherShopAction";

const INITIAL_STATE = {
    stockTransferToOtherShop: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },


    businessList: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    stockTransferToOtherShopByBarcode: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    stockTransferInsertUpdateDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    selectedStockTransferToOtherShopDetails: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    }

};



const stockTransferToOtherShopReducer = createSlice({
    name: "stockTransferToOtherShop",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetStockTransferDetailsState(state, action) {
            state.stockTransferInsertUpdateDetails = INITIAL_STATE.stockTransferInsertUpdateDetails;
            // state.selectedStockTransferToOtherShopDetails = INITIAL_STATE.selectedStockTransferToOtherShopDetails;
            state.stockTransferToOtherShopByBarcode = INITIAL_STATE.stockTransferToOtherShopByBarcode;

        },
    },

    extraReducers: (builder) => {

        // get all getAllStockTransferToOtherShopAction action start

        builder.addCase(getAllStockTransferToOtherShopAction.fulfilled, (state, action) => {
            // console.log("getAllStockTransferToOtherShopAction", action);
            state.stockTransferToOtherShop.data.records = action.payload.data;
            state.stockTransferToOtherShop.apiMsg.status = action.meta.requestStatus;
            state.stockTransferToOtherShop.apiMsg.message = "success";
        });
        builder.addCase(getAllStockTransferToOtherShopAction.pending, (state, action) => {
            // console.log("getAllStockTransferToOtherShopAction pending", action);
            state.stockTransferToOtherShop.data.records = null;
            state.stockTransferToOtherShop.apiMsg.message = action.meta.requestStatus;
            state.stockTransferToOtherShop.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllStockTransferToOtherShopAction.rejected, (state, action) => {
            // console.log("getAllStockTransferToOtherShopAction rejected", action);
            state.stockTransferToOtherShop.apiMsg.message = action.error.message;
            state.stockTransferToOtherShop.apiMsg.status = action.meta.requestStatus;
        });

        // get all getAllStockTransferToOtherShopAction action end




        //------------------------------- getbusinesss start -------------------------

        builder.addCase(getAllActiveBusinessData.fulfilled, (state, action) => {
            // console.log("action", action)
            state.businessList.data = action.payload.data;
            // console.log("state.selectedBusiness.data",state.selectedBusiness.data)
            state.businessList.apiMsg.status = action.meta.requestStatus;
            state.businessList.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getAllActiveBusinessData.pending, (state, action) => {
            //console.log("getPosUser pending", action);
            state.businessList.data = null;
            state.businessList.apiMsg.message = action.meta.requestStatus;;
            state.businessList.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getAllActiveBusinessData.rejected, (state, action) => {
            // console.log("getPosUser rejected", action);
            state.businessList.apiMsg.message = action.error.message;
            state.businessList.apiMsg.status = action.meta.requestStatus;
        });


        //   get business end

        //    get stockTransferToOtherShopByBarcode start



        builder.addCase(getAllStockTransferToOtherShopByBarcodeAction.fulfilled, (state, action) => {
            state.stockTransferToOtherShopByBarcode.data = action.payload.data;
            state.stockTransferToOtherShopByBarcode.apiMsg.status = action.meta.requestStatus;
            state.stockTransferToOtherShopByBarcode.apiMsg.message = "success";
        });
        builder.addCase(getAllStockTransferToOtherShopByBarcodeAction.pending, (state, action) => {
            // console.log("getAllStockTransferToOtherShopAction pending", action);
            state.stockTransferToOtherShopByBarcode.data.records = null;
            state.stockTransferToOtherShopByBarcode.apiMsg.message = action.meta.requestStatus;
            state.stockTransferToOtherShopByBarcode.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllStockTransferToOtherShopByBarcodeAction.rejected, (state, action) => {
            // console.log("getAllStockTransferToOtherShopAction rejected", action);
            state.stockTransferToOtherShopByBarcode.apiMsg.message = action.error.message;
            state.stockTransferToOtherShopByBarcode.apiMsg.status = action.meta.requestStatus;
        });

        //    get stockTransferToOtherShopByBarcode end


        //     insertUpdateStockTransferToOtherShop start

        builder.addCase(
            insertUpdateTransferToOtherShopAction.fulfilled,
            (state, action) => {
                console.log("insertUpdateStockReturnToSupplierAction", action);

                state.stockTransferInsertUpdateDetails.data = action.payload.data.data;
                state.stockTransferInsertUpdateDetails.apiMsg.status = action.meta.requestStatus;
                state.stockTransferInsertUpdateDetails.apiMsg.message = action.payload.message.message;
                if (!action.payload.data.data.isDraft) {
                    if (state.stockTransferToOtherShop.data.records.length) {
                        var deletedIndex = state.stockTransferToOtherShop.data.records.findIndex(x => x.id === action.payload.data.data.id);
                        state.stockTransferToOtherShop.data.records.splice(deletedIndex, 1);
                    }
                }
                else if (!action.payload.newRecord) {
                    let updatedRecord = state.stockTransferToOtherShop.data.records.map(x => {
                        if (x.id == action.payload.data.data.id) {
                            return action.payload.data.data;
                        }
                        else {
                            return x;
                        }
                    })

                    state.stockTransferToOtherShop.data.records = updatedRecord;
                }
                else {
                    if (state.stockTransferToOtherShop.data.records.length) {
                        state.stockTransferToOtherShop.data.records.unshift(action.payload.data.data);
                    }
                    else {
                        state.stockTransferToOtherShop.data.records.push(action.payload.data.data);
                    }
                }
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });

            }
        );
        builder.addCase(
            insertUpdateTransferToOtherShopAction.pending,
            (state, action) => {
               
                state.stockTransferInsertUpdateDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateTransferToOtherShopAction.rejected,
            (state, action) => {
     
                console.log(action.error.message);
                state.stockTransferInsertUpdateDetails.apiMsg.message = action.error.message;
                state.stockTransferInsertUpdateDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );


        //     insertUpdateStockTransferToOtherShop end    

        //    getStockTransferToOtherShopAction start

        builder.addCase(getStockTransferToOtherShopAction.fulfilled, (state, action) => {
            state.selectedStockTransferToOtherShopDetails.data = action.payload.data;
            state.selectedStockTransferToOtherShopDetails.apiMsg.status = action.meta.requestStatus;
            state.selectedStockTransferToOtherShopDetails.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getStockTransferToOtherShopAction.pending, (state, action) => {
            //console.log("getStockReturnTosupplierAction pending", action);
            state.selectedStockTransferToOtherShopDetails.data = null;
            state.selectedStockTransferToOtherShopDetails.apiMsg.message = action.meta.requestStatus;;
            state.selectedStockTransferToOtherShopDetails.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getStockTransferToOtherShopAction.rejected, (state, action) => {
            // console.log("getStockReturnTosupplierAction rejected", action);
            state.selectedStockTransferToOtherShopDetails.apiMsg.message = action.error.message;
            state.selectedStockTransferToOtherShopDetails.apiMsg.status = action.meta.requestStatus;
        });

        //   getStockTransferToOtherShopAction end

    },
})
export const { RESET, ResetStockTransferDetailsState } = stockTransferToOtherShopReducer.actions;
export default stockTransferToOtherShopReducer.reducer;