import { createSlice } from "@reduxjs/toolkit";
import { getAllActiveBusinessData } from "../../actions/purchase-orders/assistedOrderAction";

const INITIAL_STATE = {

  businessList: {
    data: {
      records: null,
      totalRecords: 0,
      selected: ''
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
};

const assistedOrderReducer = createSlice({
  name: "assistedOrder",
  initialState: INITIAL_STATE,
  reducers: {

    ResetAssistedOrderState(state, action) {
      state.businessList = INITIAL_STATE.businessList;
    },
  },


  extraReducers: (builder) => {



    //------------------------------- getAllActiveBusinessData start -------------------------
    // eslint-disable-next-line
    builder.addCase(getAllActiveBusinessData.fulfilled, (state, action) => {
      //console.log("getAllActiveBusinessData", action);
      state.businessList.data.records = action.payload.data;
      state.businessList.apiMsg.status = action.meta.requestStatus;

    });
    builder.addCase(getAllActiveBusinessData.pending, (state, action) => {
      state.businessList.data.records = null;
      state.businessList.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getAllActiveBusinessData.rejected, (state, action) => {
      state.businessList.apiMsg.status = action.meta.requestStatus;
    });
    //------------------------------- getAllActiveBusinessData end -------------------------
  }
});

export const { ResetAssistedOrderState } = assistedOrderReducer.actions;
export default assistedOrderReducer.reducer;
