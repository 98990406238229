import { createSlice } from "@reduxjs/toolkit";
import { getAllStaffDiscountAction, insertUpdateStaffDiscountAction, getStaffDiscountAction } from "../../actions/promotion/staffDiscountAction";
import { toast } from 'react-toastify';


const INITIAL_STATE = {
    staffDiscount: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    insertUpdateDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    selectedStaffDiscount: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

};



const staffDiscountReducer = createSlice({
    name: "StaffDiscount",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetStaffDiscountDetailsState(state, action) {
            state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
            state.selectedStaffDiscount = INITIAL_STATE.selectedStaffDiscount;
        },
    },
    extraReducers: (builder) => {



        // get all staff discount action start


        builder.addCase(getAllStaffDiscountAction.fulfilled, (state, action) => {
            console.log("action", action)
            state.staffDiscount.data.records = action.payload.data;
            state.staffDiscount.apiMsg.status = action.meta.requestStatus;
            state.staffDiscount.apiMsg.message = "success";
        });
        builder.addCase(getAllStaffDiscountAction.pending, (state, action) => {
            console.log("action", action)

            state.staffDiscount.data.records = null;
            state.staffDiscount.apiMsg.message = action.meta.requestStatus;
            state.staffDiscount.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllStaffDiscountAction.rejected, (state, action) => {
            console.log("action", action)

            state.staffDiscount.apiMsg.message = action.error.message;
            state.staffDiscount.apiMsg.status = action.meta.requestStatus;
        });

        // get all staff discount action end






        // insert update staffDiscount Action start

        builder.addCase(
            insertUpdateStaffDiscountAction.fulfilled,
            (state, action) => {
                // console.log("insertUpdateStaffDiscountAction", action);

                state.insertUpdateDetails.data = action.payload.data.data;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateDetails.apiMsg.message = action.payload.message.message;

                
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });

            }
        );
        builder.addCase(
            insertUpdateStaffDiscountAction.pending,
            (state, action) => {
                //console.log("insertUpdateStaffDiscountAction pending", action);
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateStaffDiscountAction.rejected,
            (state, action) => {
                //   console.log("insertUpdateStaffDiscountAction rejected", action);
                //   console.log(action.error.message);
                state.insertUpdateDetails.apiMsg.message = action.error.message;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );


        //   insert update staffDiscount action end

        //------------------------------- getstaffdiscount start -------------------------

        builder.addCase(getStaffDiscountAction.fulfilled, (state, action) => {
            state.selectedStaffDiscount.data = action.payload.data;
            state.selectedStaffDiscount.apiMsg.status = action.meta.requestStatus;
            state.selectedStaffDiscount.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getStaffDiscountAction.pending, (state, action) => {
            //console.log("getStaffDiscountAction pending", action);
            state.selectedStaffDiscount.data = null;
            state.selectedStaffDiscount.apiMsg.message = action.meta.requestStatus;;
            state.selectedStaffDiscount.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getStaffDiscountAction.rejected, (state, action) => {
            // console.log("getStaffDiscountAction rejected", action);
            state.selectedStaffDiscount.apiMsg.message = action.error.message;
            state.selectedStaffDiscount.apiMsg.status = action.meta.requestStatus;
        });


        //   get staffdiscount end




    },
});

export const { RESET,ResetStaffDiscountDetailsState } = staffDiscountReducer.actions;
export default staffDiscountReducer.reducer;