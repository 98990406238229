import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";

export const getAllManualPriceProductAction = createAsyncThunk(
  "ManualPriceProduct/GetAllManualPriceProduct",
  async (data) => {
    return apiCall("manualpriceproduct?approved=" + data.approved, "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {

            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const insertUpdateManualPriceProductAction = createAsyncThunk(
  "ManualPriceProduct/insertUpdateManualPriceProduct",
  async (data) => {

    return apiCall("manualpriceproduct/add", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            let message = response.data.message
            return {
              data: {
                ...response.data,
              },
              message: {
                code: response.data.message.code,
                type: response.data.message.type,
                message: response.data.message.message
              },
              newRecord: data.id ? false : true
            }
          } else {
            console.log("response error", response.data.message);
            if (!response.data.message && response.data.errors && response.data.errors.length) {
              response.data.message = response.data.errors[0];
            }
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
        else {
          return Promise.reject({
            ...response.data,
          });
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getManualPriceProductAction = createAsyncThunk(
  "ManualPriceProduct/GetManualPriceProduct",
  async (data) => {
    return apiCall("manualpriceproduct/getproduct", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);


export const getProductComparisonAction = createAsyncThunk(
  "ManualPriceProduct/GetProductComparison",
  async (data) => {
    return apiCall("manualpriceproduct/getproductcomparison?id=" + data.id, "POST", null, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const manualPriceProductApproveAction = createAsyncThunk(
  "ManualPriceProduct/Approve",
  async (data) => {
    return apiCall("manualpriceproduct/approve?id=" + data.id, "POST", null, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const manualPriceProductRejectAction = createAsyncThunk(
  "ManualPriceProduct/Reject",
  async (data) => {
    return apiCall("manualpriceproduct/reject?id=" + data.id, "POST", null, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getDisplayGroupProductAction = createAsyncThunk(
  "ManualPriceProduct/GetDisplayManualPriceProduct",
  async (data) => {
    return apiCall(`productgroup/getactive?all=${true}`, "GET", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getPriceGroupProductAction = createAsyncThunk(
  "ManualPriceProductgroup/GetPriceManualPriceProduct",
  async (data) => {
    return apiCall(`manualpriceproductgroup/getactive?all=${true}`, "GET", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const activeInactiveAction = createAsyncThunk(
  'ManualPriceProduct/activeinactive',
  async (data) => {
    return apiCall("manualpriceproduct/activeinactive", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
)