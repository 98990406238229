import React from "react";
import { Oval } from 'react-loader-spinner'


const RouteLoader = () => {
    return(<>
   <div className="route-loader">
   <Oval
  height={60}
  width={60}
  color="#337ab7"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  ariaLabel='oval-loading'
  secondaryColor="#337ab7"
  strokeWidth={2}
  strokeWidthSecondary={2}

/>
  
   </div>
   
    </>);
  }

  export default RouteLoader;