import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";
import { toast } from 'react-toastify';

export const getAllPurchaseOrderAction = createAsyncThunk(
    "PurchaseOrder/GetAllPurchaseOrder",
    async (data) => {
        return apiCall("purchaseorder/getallpurchaseorder", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        //  console.log("response error", response.data.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getPurchaseOrderAction = createAsyncThunk(
    "PurchaseOrder/GetPurchaseOrder",
    async (data) => {
        return apiCall("purchaseorder/get", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getAllActiveBusinessData = createAsyncThunk(
    "PurchaseOrder/businesses",
    async (data) => {
        return apiCall(`business/GetActive?all=${true}`, "GET", {}, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const insertUpdatePurchaseOrderAction = createAsyncThunk(
    "PurchaseOrder/insertupdatePurchaseOrder",
    async (data) => {
        return apiCall("purchaseorder/insertupdate", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        // console.log('response.data ',response.data.message)
                        let message = response.data.message
                        // console.log("message",message);
                        return {
                            data: {
                                ...response.data,
                            },
                            message: {
                                code: response.data.message.code,
                                type: response.data.message.type,
                                message: response.data.message.message
                            },
                            newRecord: data.id ? false : true
                        }
                    } else {
                        // console.log("response error", response.data.message);
                        if (!response.data.message && response.data.errors && response.data.errors.length) {
                            response.data.message = response.data.errors[0];
                        }
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
                else {
                    return Promise.reject({
                        ...response.data,
                    });
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getPurchaseOrderProductByBarcodeAction = createAsyncThunk(
    "PurchaseOrder/GetPurchaeOrderProductBarcode",
    async (data) => {
        return apiCall("purchaseorder/getproductpurchaseorderbybarcode", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);


export const changeStatusAction = createAsyncThunk(
    "PurchaseOrder/changestatus",
    async (data) => {
      return apiCall("purchaseorder/changestatus", "POST", data, false)
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.hasError) {
  
              return {
                ...response.data,
              }
            } else {
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
        })
        .catch((error) => {
          return Promise.reject({
            ...error,
          });
        });
    }
  );



export const deleteAction = createAsyncThunk(
    "PurchaseOrder/delete",
    async (data) => {
      return apiCall("purchaseorder/changestatus", "POST", data, false)
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.hasError) {
  
              return {
                ...response.data,
              }
            } else {
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
        })
        .catch((error) => {
          return Promise.reject({
            ...error,
          });
        });
    }
  );

//   below api has no used,only test purpose
export const productPurchaseOrderAction = createAsyncThunk(
    "PurchaseOrder/ProductPurchaseOrder",
    async (data) => {

        return apiCall("purchaseorder/insertupdate", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);