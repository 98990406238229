import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import AppRoutes from './app.routes';
import AuthRoutes from './auth.routes';
import { StateCompleted, StateRejected } from './app/helper/functions/general-func';
import { ReducerStateContant } from "./app/helper/constants/general-const";
import { getAllActiveBusinessData } from "./app/redux-store/actions/masterDataActions";

const App = () => {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const masterDataReducer = useSelector((state) => state.masterDataReducer);
  const [authorizedAccess, setAuthorizedAccess] = useState(ReducerStateContant.pending);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getAllActiveBusinessData());
    }

  }, [isLoggedIn]);

  useEffect(() => {

    if (StateCompleted(masterDataReducer.businessList)) {
      setAuthorizedAccess(ReducerStateContant.fulfilled)
    }
    if (StateRejected(masterDataReducer.businessList)) {
      setAuthorizedAccess(ReducerStateContant.rejected)
    }

  }, [masterDataReducer.businessList])
  return (
    <>
      {
        isLoggedIn ? <AppRoutes authorizedAccess={authorizedAccess} active={isLoggedIn} /> : <AuthRoutes />
      }
    </>
  );
};

export default App;