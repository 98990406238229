import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";



export const getAllStockReturnToSupplierAction = createAsyncThunk(

  "StockReturnToSupplier/GetAllStockReturnToSupplier",
  async (data) => {
    return apiCall("stockreturn", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {

            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getAllActiveBusinessData = createAsyncThunk(
  "StockReturnToSupplier/businesses",
  async (data) => {
    return apiCall("business/GetActive", "GET", {}, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);


export const insertUpdateStockReturnToSupplierAction = createAsyncThunk(
  "StockReturnToSupplier/insertupdatestockreturn",
  async (data) => {
    return apiCall("stockreturn/add", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            let message = response.data.message
            return {
              data: {
                ...response.data,
              },
              message: {
                code: response.data.message.code,
                type: response.data.message.type,
                message: response.data.message.message
              },
              newRecord: data.id ? false : true
            }
          } else {
            if (!response.data.message && response.data.errors && response.data.errors.length) {
              response.data.message = response.data.errors[0];
            }
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
        else {
          return Promise.reject({
            ...response.data,
          });
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);


export const getStockReturnTosupplierAction = createAsyncThunk(
  "StockReturnToSupplier/GetStockReturnToSupplier",
  async (data) => {
    return apiCall("stockreturn/getstockreturn", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            }
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);


export const getStockReturnTosupplierByBarcodeAction = createAsyncThunk(
  "StockReturnToSupplier/GetStockReturntoSupplierByBarcode",
  async (data) => {
    return apiCall("StockReturn/GetProductStockReturnByBarcode", "POST", data, false)
    .then((response) => {
      if (response.status === 200) {
        if (!response.data.hasError) {
          console.log(response.data);
          return {
            ...response.data,
          }
        } else {
          return Promise.reject({
            ...response.data.message,
          });
        }
      }
    })
    .catch((error) => {
      return Promise.reject({
        ...error,
      });
    });
  }
)