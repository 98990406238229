import { createSlice } from "@reduxjs/toolkit";
import {
  getAllActiveVatData,
  getAllActiveBusinessData,
  getAllDepartmentData,
  getAllSubDepartmentData,
  getAllProductGroupData,
  getAllPendingApprovals,
  getAllProductData,
  getAllProductDataByBarcode,
  getAllActiveSupplier,
  getProductByBarcode,
  getAllActiveBrand
} from "../actions/masterDataActions";
const INITIAL_STATE = {
  vatList: {
    data: {
      records: null,
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  businessList: {

    data: {
      records: null,
      totalRecords: 0,
      selected: ''
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  departmentList: {
    data: {
      records: null,
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    }
  },
  subDepartmentList: {
    data: {
      records: null,
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  productGroupList: {
    data: {
      records: null,
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  pendingApprovalList: {
    data: {
      records: null,
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  productList: {
    data: {
      records: null,
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  productListByBarcode: {
    data: {
      records: null,
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  supplierList: {
    data: {
      records: null,
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  barcodeProductList: {
    data: {
      records: null,
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  brandList: {
    data: {
      records: null,
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
};

const masterDataReducer = createSlice({
  name: "masterdata",
  initialState: INITIAL_STATE,
  reducers: {
    SetSelectedShop(state, data) {
      state.businessList.data.selected = data.payload;
    },
    ResetVatListState(state, action) {
      state.vatList = INITIAL_STATE.vatList;
    },
    ResetProductList(state, action) {
      state.productList = INITIAL_STATE.productList;
    },
    ResetProductListByBarcode(state, action) {
      state.productListByBarcode = INITIAL_STATE.productListByBarcode;
    },
    ResetProductByBarcodeState(state, action) {
      state.barcodeProductList = INITIAL_STATE.barcodeProductList;
    },
  },
  extraReducers: (builder) => {
    //------------------------------- getAllActiveVatData start -------------------------
    // eslint-disable-next-line
    builder.addCase(getAllActiveVatData.fulfilled, (state, action) => {
      //console.log("getAllActiveVatData", action);
      state.vatList.data.records = action.payload.data;
      state.vatList.apiMsg.status = action.meta.requestStatus;

    });
    builder.addCase(getAllActiveVatData.pending, (state, action) => {
      state.vatList.data.records = null;
      state.vatList.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getAllActiveVatData.rejected, (state, action) => {
      state.vatList.apiMsg.status = action.meta.requestStatus;
    });
    //------------------------------- getAllActiveVatData end -------------------------

    //------------------------------- getAllActiveBusinessData start -------------------------

    builder.addCase(getAllActiveBusinessData.fulfilled, (state, action) => {
      state.businessList.data.records = action.payload.data;
      state.businessList.apiMsg.status = action.meta.requestStatus;

    });
    builder.addCase(getAllActiveBusinessData.pending, (state, action) => {
      state.businessList.data.records = null;
      state.businessList.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getAllActiveBusinessData.rejected, (state, action) => {
      state.businessList.apiMsg.status = action.meta.requestStatus;
    });

    //------------------------------- getAllActiveBusinessData end -------------------------

    //------------------------------- getAllActiveBusinessData start -------------------------

    builder.addCase(getAllDepartmentData.fulfilled, (state, action) => {
      state.departmentList.data.records = action.payload.data;
      state.departmentList.apiMsg.status = action.meta.requestStatus;

    });
    builder.addCase(getAllDepartmentData.pending, (state, action) => {
      state.departmentList.data.records = null;
      state.departmentList.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getAllDepartmentData.rejected, (state, action) => {
      state.departmentList.apiMsg.status = action.meta.requestStatus;
    });

    //------------------------------- getAllSubDepartmentData start -------------------------

    builder.addCase(getAllSubDepartmentData.fulfilled, (state, action) => {
      state.subDepartmentList.data.records = action.payload.data;
      state.subDepartmentList.apiMsg.status = action.meta.requestStatus;

    });
    builder.addCase(getAllSubDepartmentData.pending, (state, action) => {
      state.subDepartmentList.data.records = null;
      state.subDepartmentList.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getAllSubDepartmentData.rejected, (state, action) => {
      state.subDepartmentList.apiMsg.status = action.meta.requestStatus;
    });

    //------------------------------- getAllProductGroupData start -------------------------

    builder.addCase(getAllProductGroupData.fulfilled, (state, action) => {
      state.productGroupList.data.records = action.payload.data;
      state.productGroupList.apiMsg.status = action.meta.requestStatus;

    });
    builder.addCase(getAllProductGroupData.pending, (state, action) => {
      state.productGroupList.data.records = null;
      state.productGroupList.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getAllProductGroupData.rejected, (state, action) => {
      state.productGroupList.apiMsg.status = action.meta.requestStatus;
    });

    //------------------------------- getAllPendingApprovals start -------------------------

    builder.addCase(getAllPendingApprovals.fulfilled, (state, action) => {
      state.pendingApprovalList.data.records = action.payload.data;
      state.pendingApprovalList.apiMsg.status = action.meta.requestStatus;

    });
    builder.addCase(getAllPendingApprovals.pending, (state, action) => {
      state.pendingApprovalList.data.records = null;
      state.pendingApprovalList.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getAllPendingApprovals.rejected, (state, action) => {
      state.pendingApprovalList.apiMsg.status = action.meta.requestStatus;
    });

    //------------------------------- getAllProductData start -------------------------

    builder.addCase(getAllProductData.fulfilled, (state, action) => {
      state.productList.data.records = action.payload.data;
      state.productList.apiMsg.status = action.meta.requestStatus;

    });
    builder.addCase(getAllProductData.pending, (state, action) => {
      state.productList.data.records = null;
      state.productList.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getAllProductData.rejected, (state, action) => {
      state.productList.apiMsg.status = action.meta.requestStatus;
    });

    //------------------------------- getAllProductData start -------------------------

    builder.addCase(getAllProductDataByBarcode.fulfilled, (state, action) => {
      state.productListByBarcode.data.records = action.payload.data;
      state.productListByBarcode.apiMsg.status = action.meta.requestStatus;

    });
    builder.addCase(getAllProductDataByBarcode.pending, (state, action) => {
      state.productListByBarcode.data.records = null;
      state.productListByBarcode.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getAllProductDataByBarcode.rejected, (state, action) => {
      state.productListByBarcode.apiMsg.status = action.meta.requestStatus;
    });

    //------------------------------- getAllActiveSupplier start -------------------------

    builder.addCase(getAllActiveSupplier.fulfilled, (state, action) => {
      state.supplierList.data.records = action.payload.data;
      state.supplierList.apiMsg.status = action.meta.requestStatus;

    });
    builder.addCase(getAllActiveSupplier.pending, (state, action) => {
      state.supplierList.data.records = null;
      state.supplierList.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getAllActiveSupplier.rejected, (state, action) => {
      state.supplierList.apiMsg.status = action.meta.requestStatus;
    });

    //------------------------------- getProductByBarcode start -------------------------

    //------------------------------- getAllActiveBrand start -------------------------

    builder.addCase(getAllActiveBrand.fulfilled, (state, action) => {
      state.brandList.data.records = action.payload.data;
      state.brandList.apiMsg.status = action.meta.requestStatus;

    });
    builder.addCase(getAllActiveBrand.pending, (state, action) => {
      state.brandList.data.records = null;
      state.brandList.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getAllActiveBrand.rejected, (state, action) => {
      state.brandList.apiMsg.status = action.meta.requestStatus;
    });

    //------------------------------- getProductByBarcode start -------------------------

    builder.addCase(getProductByBarcode.fulfilled, (state, action) => {
      state.barcodeProductList.data.records = action.payload.data;
      state.barcodeProductList.apiMsg.status = action.meta.requestStatus;

    });
    builder.addCase(getProductByBarcode.pending, (state, action) => {
      state.barcodeProductList.data.records = null;
      state.barcodeProductList.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getProductByBarcode.rejected, (state, action) => {
      state.barcodeProductList.apiMsg.status = action.meta.requestStatus;
    });
  }
});

export const { ResetVatListState, SetSelectedShop, ResetProductByBarcodeState, ResetProductList, ResetProductListByBarcode } = masterDataReducer.actions;
export default masterDataReducer.reducer;
