import { createSlice } from "@reduxjs/toolkit";
import {
  getAllBrandsAction,
  insertUpdateBrandAction,
  deleteAction
} from "../../actions/inventory/brandActions";
import { toast } from 'react-toastify';
const INITIAL_STATE = {
  brand: {
    data: {
      records: [],
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  brandDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
    newRecord: false
  },
};

const brandReducer = createSlice({
  name: "Brand",
  initialState: INITIAL_STATE,
  reducers: {

    RESET(state, action) {
      state = INITIAL_STATE;
    },
    ResetBrandDetailsState(state, action) {
      state.brandDetails = INITIAL_STATE.brandDetails;
    },
  },
  extraReducers: (builder) => {
    //------------------------------- getAllBrandsAction start -------------------------
    // eslint-disable-next-line
    builder.addCase(getAllBrandsAction.fulfilled, (state, action) => {
      //console.log("getAllBrandsAction", action);
      state.brand.data.records = action.payload.data;
      state.brand.apiMsg.status = action.meta.requestStatus;
      state.brand.apiMsg.message = action.payload.message.message;
    });
    builder.addCase(getAllBrandsAction.pending, (state, action) => {
      //console.log("getAllBrandsAction pending", action);
      state.brand.data.records = null;
      state.brand.apiMsg.message = action.meta.requestStatus;;
      state.brand.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getAllBrandsAction.rejected, (state, action) => {
      console.log("getAllBrandsAction rejected", action);
      state.brand.apiMsg.message = action.error.message;
      state.brand.apiMsg.status = action.meta.requestStatus;
    });
    //------------------------------- getAllBrandsAction end -------------------------

    //------------------------------- insertUpdateBrandAction start -------------------------

    builder.addCase(
      insertUpdateBrandAction.fulfilled,
      (state, action) => {
        //console.log("insertUpdateBrandAction", action);

        state.brandDetails.data = action.payload.data.data;
        state.brandDetails.apiMsg.status = action.meta.requestStatus;
        state.brandDetails.apiMsg.message = action.payload.message.message;

        if (!action.payload.newRecord) {
          let updatedRecord = state.brand.data.records.map(x => {
            if (x.id == action.payload.data.data.id) {
              return action.payload.data.data;
            }
            else {
              return x;
            }
          })

          state.brand.data.records = updatedRecord;
        }
        else {
          if (state.brand.data.records.length) {
            state.brand.data.records.unshift(action.payload.data.data);
          }
          else {
            state.brand.data.records.push(action.payload.data.data);
          }
        }
        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });

      }
    );
    builder.addCase(
      insertUpdateBrandAction.pending,
      (state, action) => {
        //console.log("insertUpdateBrandAction pending", action);
        state.brandDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      insertUpdateBrandAction.rejected,
      (state, action) => {
        console.log("insertUpdateBrandAction rejected", action);
        console.log(action.error.message);
        state.brandDetails.apiMsg.message = action.error.message;
        state.brandDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });
      }
    );

    //------------------------------- insertUpdateBrandAction end -------------------------

    //------------------------------- deleteAction start -------------------------

    builder.addCase(
      deleteAction.fulfilled,
      (state, action) => {
        //console.log("deleteAction",action);
        state.brand.data.records = state.brand.data.records.filter((item) => item.id !== action.meta.arg.id);
        toast.success("Record deleted successfully", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "msg-success"
        });

      }
    );

    builder.addCase(
      deleteAction.rejected,
      (state, action) => {
        console.log("deleteAction rejected", action);
        console.log(action.error.message);
        toast.error((action.error.message ? action.error.message : "Something went wrong please try again"), {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "msg-error"
        });
      }
    );

  },
});

export const { RESET, ResetBrandDetailsState } = brandReducer.actions;
export default brandReducer.reducer;
