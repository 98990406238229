import { createSlice } from "@reduxjs/toolkit";

import {
    getConditionalVoucherAction,
    insertUpdateConditionalVoucherAction,
    getVoucherAction,
    getActiveDiscountAction,
    activeInactiveAction,
    getConditionalVoucherComparisonAction,
    conditionalVoucherApproveAction,
    conditionalVoucherRejectAction
} from "../../actions/promotion/conditionalVoucherActions";

import { toast } from 'react-toastify';

const INITIAL_STATE = {
    conditionalVoucher: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    selectedConditionalVoucher: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },


    activeDiscount: {
        data: {
            records: null,
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        }
    },
    activeInactiveDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    selectedConditionalVoucherComparsion: {
        data: {
            records: null,
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    approveDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        }
    },
    rejectDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        }
    }


}
const conditionalVoucherReducer = createSlice({
    name: "ConditionalVoucher",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetConditionalVoucherDetailsState(state, action) {
            state.selectedConditionalVoucher = INITIAL_STATE.selectedConditionalVoucher;
            state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
            state.activeDiscount = INITIAL_STATE.activeDiscount;
            state.approveDetails = INITIAL_STATE.approveDetails
            state.rejectDetails = INITIAL_STATE.rejectDetails
        },
    },
    extraReducers: (builder) => {


        builder.addCase(getConditionalVoucherAction.fulfilled, (state, action) => {
            // console.log("getConditionalVoucherAction", action);
            state.conditionalVoucher.data.records = action.payload.data;
            state.conditionalVoucher.apiMsg.status = action.meta.requestStatus;
            state.conditionalVoucher.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getConditionalVoucherAction.pending, (state, action) => {
            // console.log("getConditionalVoucher pending", action);
            state.conditionalVoucher.data.records = null;
            state.conditionalVoucher.apiMsg.message = action.meta.requestStatus;;
            state.conditionalVoucher.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getConditionalVoucherAction.rejected, (state, action) => {
            // console.log("getConditionalVoucher rejected", action);
            state.conditionalVoucher.apiMsg.message = action.error.message;
            state.conditionalVoucher.apiMsg.status = action.meta.requestStatus;
            toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                position: toast.POSITION.BOTTOM_CENTER,
                toastId: "dept-error"
            });
        });

        // insertUpdateConditionalVoucherAction  start

        builder.addCase(
            insertUpdateConditionalVoucherAction.fulfilled,
            (state, action) => {
                // console.log("insertUpdateConditionalVoucherAction", action);

                state.insertUpdateDetails.data = action.payload.data.data;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateDetails.apiMsg.message = action.payload.message.message;

                if (!action.payload.newRecord) {
                    let updatedRecord = state.conditionalVoucher.data.records.map(x => {
                        if (x.id == action.payload.data.data.id) {
                            return action.payload.data.data;
                        }
                        else {
                            return x;
                        }
                    })

                    state.conditionalVoucher.data.records = updatedRecord;
                }
                else {
                    if (state.conditionalVoucher.data.records.length) {
                        state.conditionalVoucher.data.records.unshift(action.payload.data.data);
                    }
                    else {
                        state.conditionalVoucher.data.records.push(action.payload.data.data);
                    }
                }
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });

            }
        );
        builder.addCase(
            insertUpdateConditionalVoucherAction.pending,
            (state, action) => {
                //console.log("insertUpdateDiscountAction pending", action);
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateConditionalVoucherAction.rejected,
            (state, action) => {
                //   console.log("insertUpdateDiscountAction rejected", action);
                //   console.log(action.error.message);
                state.insertUpdateDetails.apiMsg.message = action.error.message;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );


        //    insertUpdateConditionalVoucherAction action end



        //------------------------------- getdiscount start -------------------------

        builder.addCase(getVoucherAction.fulfilled, (state, action) => {
            // console.log("getVoucherAction ", action);

            state.selectedConditionalVoucher.data = action.payload.data;
            state.selectedConditionalVoucher.apiMsg.status = action.meta.requestStatus;
            state.selectedConditionalVoucher.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getVoucherAction.pending, (state, action) => {
            //console.log("getVoucherAction pending", action);
            state.selectedConditionalVoucher.data = null;
            state.selectedConditionalVoucher.apiMsg.message = action.meta.requestStatus;
            state.selectedConditionalVoucher.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getVoucherAction.rejected, (state, action) => {
            // console.log("getVoucherAction rejected", action);
            state.selectedConditionalVoucher.apiMsg.message = action.error.message;
            state.selectedConditionalVoucher.apiMsg.status = action.meta.requestStatus;
        });


        //   get discount end


        //------------------------------- getActiveDiscountAction start -------------------------

        builder.addCase(getActiveDiscountAction.fulfilled, (state, action) => {
            console.log("getActiveDiscountAction ", action);

            state.activeDiscount.data.records = action.payload.data;
            state.activeDiscount.apiMsg.status = action.meta.requestStatus;
            state.activeDiscount.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getActiveDiscountAction.pending, (state, action) => {
            // console.log("getActiveDiscountAction pending", action);
            state.activeDiscount.data.records = null;
            state.activeDiscount.apiMsg.message = action.meta.requestStatus;
            state.activeDiscount.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getActiveDiscountAction.rejected, (state, action) => {
            // console.log("getActiveDiscountAction rejected", action);
            state.activeDiscount.apiMsg.message = action.error.message;
            state.activeDiscount.apiMsg.status = action.meta.requestStatus;
        });


        //   getActiveDiscountAction end


        //------------------------------- activeInactiveAction start -------------------------

        builder.addCase(
            activeInactiveAction.fulfilled,
            (state, action) => {
                //console.log("activeInactiveAction", action);

                state.activeInactiveDetails.data = action.payload.data.data;
                state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
                state.activeInactiveDetails.apiMsg.message = action.payload.message.message;

                let updatedRecord = state.conditionalVoucher.data.records.map(x => {
                    if (x.id == action.meta.arg.id) {
                        x.isActive = action.meta.arg.isActive;
                        return x;
                    }
                    else {
                        return x;
                    }
                })



                state.conditionalVoucher.data.records = updatedRecord;

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            activeInactiveAction.pending,
            (state, action) => {
                state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            activeInactiveAction.rejected,
            (state, action) => {
                state.activeInactiveDetails.apiMsg.message = action.error.message;
                state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "error"
                });
            }
        );

        //------------------------------- activeInactiveAction end -------------------------


        //------------ getConditionalVoucherComparisonAction -----------

        builder.addCase(getConditionalVoucherComparisonAction.fulfilled, (state, action) => {
            state.selectedConditionalVoucherComparsion.data.records = action.payload.data;
            state.selectedConditionalVoucherComparsion.apiMsg.status = action.meta.requestStatus;
            state.selectedConditionalVoucherComparsion.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getConditionalVoucherComparisonAction.pending, (state, action) => {
            //console.log("getConditionalVoucherComparisonAction pending", action);
            state.selectedConditionalVoucherComparsion.data.records = null;
            state.selectedConditionalVoucherComparsion.apiMsg.message = action.meta.requestStatus;;
            state.selectedConditionalVoucherComparsion.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getConditionalVoucherComparisonAction.rejected, (state, action) => {
            state.selectedConditionalVoucherComparsion.apiMsg.message = action.error.message;
            state.selectedConditionalVoucherComparsion.apiMsg.status = action.meta.requestStatus;
        });


        //    getConditionalVoucherComparisonAction end



        //------------------------------- conditionalVoucherApproveAction start -------------------------

        builder.addCase(
            conditionalVoucherApproveAction.fulfilled,
            (state, action) => {

                state.approveDetails.data = action.payload.data.data;
                state.approveDetails.apiMsg.status = action.meta.requestStatus;
                state.approveDetails.apiMsg.message = action.payload.message.message;

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            conditionalVoucherApproveAction.pending,
            (state, action) => {
                state.approveDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            conditionalVoucherApproveAction.rejected,
            (state, action) => {
                state.approveDetails.apiMsg.message = action.error.message;
                state.approveDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "error"
                });
            }
        );

        //------------------------------- conditionalVoucherApproveAction end -------------------------

        //------------------------------- conditionalVoucherRejectAction start -------------------------

        builder.addCase(
            conditionalVoucherRejectAction.fulfilled,
            (state, action) => {

                state.rejectDetails.data = action.payload.data.data;
                state.rejectDetails.apiMsg.status = action.meta.requestStatus;
                state.rejectDetails.apiMsg.message = action.payload.message.message;

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            conditionalVoucherRejectAction.pending,
            (state, action) => {
                state.rejectDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            conditionalVoucherRejectAction.rejected,
            (state, action) => {
                state.rejectDetails.apiMsg.message = action.error.message;
                state.rejectDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "error"
                });
            }
        );
        //------------------------------- conditionalVoucherRejectAction end -------------------------
    },
});
export const { RESET, ResetConditionalVoucherDetailsState } = conditionalVoucherReducer.actions;
export default conditionalVoucherReducer.reducer;

