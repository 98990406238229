import { createSlice } from "@reduxjs/toolkit";

import { getAllTillAction } from "../../actions/shop-settings/tillActions";
import { insertUpdateTillAction,getTillAction } from "../../actions/shop-settings/tillActions";


import { toast } from 'react-toastify';

const INITIAL_STATE = {
    till: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateDetails: {
        data: {},
    
        apiMsg: {
          message: null,
          status: null,
          type: null,
        },
        newRecord: false
      }

    };
    

const tillReducer = createSlice({
    name: "Till",
    initialState: INITIAL_STATE,
    reducers: {

          RESET(state, action) {
            state = INITIAL_STATE;
          },
          ResetTillDetailsState(state, action) {
            state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
            state.selectedTill = INITIAL_STATE.selectedTill;
          },
    },
    extraReducers: (builder) => {



                            // get all till action start


        builder.addCase(getAllTillAction.fulfilled, (state, action) => {
            // console.log("gettillAction",action);
            state.till.data.records = action.payload.data;
            state.till.apiMsg.status = action.meta.requestStatus;
            state.till.apiMsg.message = "success";
            // not working above  line show error
        });
        builder.addCase(getAllTillAction.pending, (state, action) => {
            // console.log("getTillAction pending", action);
            state.till.data.records = null;
            state.till.apiMsg.message = action.meta.requestStatus;
            state.till.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllTillAction.rejected, (state, action) => {
            // console.log("getTillAction rejected", action);
            state.till.apiMsg.message = action.error.message;
            state.till.apiMsg.status = action.meta.requestStatus;
        });
              
                            // get all till action end



                             // insert update till Action start

                             builder.addCase(
                              insertUpdateTillAction.fulfilled,
                              (state, action) => {
                              // console.log("insertUpdateTillAction", action);
                        
                                state.insertUpdateDetails.data = action.payload.data.data;
                                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
                                state.insertUpdateDetails.apiMsg.message = action.payload.message.message;
                        
                                if (!action.payload.newRecord) {
                                  let updatedRecord = state.till.data.records.map(x => {
                                    if (x.id == action.payload.data.data.id) {
                                      return action.payload.data.data;
                                    }
                                    else {
                                      return x;
                                    }
                                  })
                        
                                  state.till.data.records = updatedRecord;
                                }
                                else {
                                  if (state.till.data.records.length) {
                                    state.till.data.records.unshift(action.payload.data.data);
                                  }
                                  else {
                                    state.till.data.records.push(action.payload.data.data);
                                  }
                                }
                                toast.success(action.payload.message.message, {
                                  position: toast.POSITION.BOTTOM_CENTER,
                                  toastId: "dept-error"
                                });
                                
                              }
                            );
                            builder.addCase(
                              insertUpdateTillAction.pending,
                              (state, action) => {
                                //console.log("insertUpdateVatAction pending", action);
                                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
                              }
                            );
                            builder.addCase(
                              insertUpdateTillAction.rejected,
                              (state, action) => {
                              //   console.log("insertUpdateTillAction rejected", action);
                                console.log(action.error.message);
                                state.insertUpdateDetails.apiMsg.message = action.error.message;
                                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
                        
                                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                                  position: toast.POSITION.BOTTOM_CENTER,
                                  toastId: "dept-error"
                                });
                              }
                            );
              
              
                          //   insert update till action end

    },
});
export const {RESET,ResetTillDetailsState} = tillReducer.actions;
export default tillReducer.reducer;