import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';

import { getAllPurchaseOrderAction, getPurchaseOrderAction, getAllActiveBusinessData, insertUpdatePurchaseOrderAction, getPurchaseOrderProductByBarcodeAction, changeStatusAction, deleteAction, productPurchaseOrderAction } from "../../actions/purchase-orders/purchaseOrderAction";

const INITIAL_STATE = {
    purchaseOrder: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedPurchaseOrder: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    businessList: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    productBarcode: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    productDetail: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    changeStatusDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    deleteDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

};

const purchaseOrderReducer = createSlice({
    name: "PurchaseOrder",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetPurchaseOrderDetailsState(state, action) {
            state.selectedPurchaseOrder = INITIAL_STATE.selectedPurchaseOrder;
            state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
            state.productBarcode = INITIAL_STATE.productBarcode;
        },
        ResetPurchaseOrderBarcodeState(state, action) {
            state.productBarcode = INITIAL_STATE.productBarcode;
        },
    },
    extraReducers: (builder) => {

        //  --------getAllPurchaseOrderAction start--------------------

        builder.addCase(getAllPurchaseOrderAction.fulfilled, (state, action) => {

            state.purchaseOrder.data.records = action.payload.data;
            state.purchaseOrder.apiMsg.status = action.meta.requestStatus;
            state.purchaseOrder.apiMsg.message = action.payload.message.message;

        });
        builder.addCase(getAllPurchaseOrderAction.pending, (state, action) => {
            // console.log("getAllPurchaseOrderAction pending", action);
            state.purchaseOrder.data.records = null;
            state.purchaseOrder.apiMsg.message = action.meta.requestStatus;;
            state.purchaseOrder.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllPurchaseOrderAction.rejected, (state, action) => {
            // console.log("getAllPurchaseOrderAction rejected", action);
            state.purchaseOrder.apiMsg.message = action.error.message;
            state.purchaseOrder.apiMsg.status = action.meta.requestStatus;
        });

        //  -------- getAllPurchaseOrderAction end--------------------




        //------------------------------- getPurchaseOrderAction start -------------------------

        builder.addCase(getPurchaseOrderAction.fulfilled, (state, action) => {
            state.selectedPurchaseOrder.data = action.payload.data
            state.selectedPurchaseOrder.apiMsg.status = action.meta.requestStatus;
            state.selectedPurchaseOrder.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getPurchaseOrderAction.pending, (state, action) => {
            //console.log("getPurchaseOrderAction pending", action);
            state.selectedPurchaseOrder.data = null;
            state.selectedPurchaseOrder.apiMsg.message = action.meta.requestStatus;;
            state.selectedPurchaseOrder.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getPurchaseOrderAction.rejected, (state, action) => {
            // console.log("getPurchaseOrderAction rejected", action);
            state.selectedPurchaseOrder.apiMsg.message = action.error.message;
            state.selectedPurchaseOrder.apiMsg.status = action.meta.requestStatus;
        });


        //   getPurchaseOrderAction end

        //------------------------------- getbusinesss start -------------------------

        builder.addCase(getAllActiveBusinessData.fulfilled, (state, action) => {
            // console.log("action", action)
            state.businessList.data = action.payload.data;
            // console.log("state.selectedBusiness.data",state.selectedBusiness.data)
            state.businessList.apiMsg.status = action.meta.requestStatus;
            state.businessList.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getAllActiveBusinessData.pending, (state, action) => {
            //console.log("getPosUser pending", action);
            state.businessList.data = null;
            state.businessList.apiMsg.message = action.meta.requestStatus;;
            state.businessList.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getAllActiveBusinessData.rejected, (state, action) => {
            // console.log("getPosUser rejected", action);
            state.businessList.apiMsg.message = action.error.message;
            state.businessList.apiMsg.status = action.meta.requestStatus;
        });


        //   get business end

        // insertUpdatePurchaseOrderAction Action start

        builder.addCase(
            insertUpdatePurchaseOrderAction.fulfilled,
            (state, action) => {

                state.insertUpdateDetails.data = action.payload.data.data;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateDetails.apiMsg.message = action.payload.message.message;

                if (action.payload.newRecord) {


                    if (state.purchaseOrder.data.records.length) {
                        state.purchaseOrder.data.records.unshift(action.payload.data.data);
                    }
                    else {
                        state.purchaseOrder.data.records.push(action.payload.data.data);
                    }
                }
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });

            }
        );
        builder.addCase(
            insertUpdatePurchaseOrderAction.pending,
            (state, action) => {
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdatePurchaseOrderAction.rejected,
            (state, action) => {

                state.insertUpdateDetails.apiMsg.message = action.error.message;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );


        //   insertUpdatePurchaseOrderAction  end


        //------------------------------- getPurchaseOrderProductByBarcodeAction start -------------------------

        builder.addCase(getPurchaseOrderProductByBarcodeAction.fulfilled, (state, action) => {
            console.log("getPurchaseOrderProductByBarcodeAction fulfilled", action);
            state.productBarcode.data = action.payload.data;
            state.productBarcode.data.autoSelect = action.meta.arg.autoSelect;
            state.productBarcode.apiMsg.status = action.meta.requestStatus;
            state.productBarcode.apiMsg.message = action.payload.message.message;


        });

        builder.addCase(getPurchaseOrderProductByBarcodeAction.pending, (state, action) => {
            //console.log("getPurchaseOrderProductByBarcodeAction pending", action);
            state.productBarcode.data = null;
            state.productBarcode.apiMsg.message = action.meta.requestStatus;;
            state.productBarcode.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getPurchaseOrderProductByBarcodeAction.rejected, (state, action) => {
            // console.log("getPurchaseOrderProductByBarcodeAction rejected", action);
            state.productBarcode.apiMsg.message = action.error.message;
            state.productBarcode.apiMsg.status = action.meta.requestStatus;
        });


        //   getPurchaseOrderProductByBarcodeAction end








        //------------------------------- changeStatusAction start -------------------------

        builder.addCase(
            changeStatusAction.fulfilled,
            (state, action) => {
                console.log("changeStatusAction", action);

                state.changeStatusDetails.data = action.payload.data;
                state.changeStatusDetails.apiMsg.status = action.meta.requestStatus;
                state.changeStatusDetails.apiMsg.message = action.payload.message.message;

                let updatedRecord = state.purchaseOrder.data.records.map(x => {
                    if (x.id == action.meta.arg.id) {
                        x.isActive = state.changeStatusDetails.data.isActive;
                        x.orderStatus = state.changeStatusDetails.data.orderStatus;
                        return x;
                    }
                    else {
                        return x;
                    }
                })



                state.purchaseOrder.data.records = updatedRecord;

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            changeStatusAction.pending,
            (state, action) => {
                state.changeStatusDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            changeStatusAction.rejected,
            (state, action) => {
                state.changeStatusDetails.apiMsg.message = action.error.message;
                state.changeStatusDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "error"
                });
            }
        );

        //------------------------------- changeStatusAction end -------------------------


        //------------------------------- deleteAction start -------------------------

        builder.addCase(
            deleteAction.fulfilled,
            (state, action) => {
                //console.log("deleteAction",action);
                state.purchaseOrder.data.records = state.purchaseOrder.data.records.filter((item) => item.id !== action.meta.arg.id);
                toast.success("Record deleted successfully", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "msg-success"
                });

            }
        );

        builder.addCase(
            deleteAction.rejected,
            (state, action) => {
                console.log("deleteAction rejected", action);
                console.log(action.error.message);
                toast.error((action.error.message ? action.error.message : "Something went wrong please try again"), {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "msg-error"
                });
            }
        );


        // below api has no use ,only for test purpose
        //------------------------------- productPurchaseOrderAction start -------------------------

        builder.addCase(productPurchaseOrderAction.fulfilled, (state, action) => {
            state.productDetail.data = action.payload.data
            state.productDetail.apiMsg.status = action.meta.requestStatus;
            state.productDetail.apiMsg.message = action.payload.message.message;
            toast.success(action.payload.message.message, {
                position: toast.POSITION.BOTTOM_CENTER,
                toastId: "dept-error"
            });
        });

        builder.addCase(productPurchaseOrderAction.pending, (state, action) => {
            state.productDetail.data = null;
            state.productDetail.apiMsg.message = action.meta.requestStatus;;
            state.productDetail.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(productPurchaseOrderAction.rejected, (state, action) => {
            state.productDetail.apiMsg.message = action.error.message;
            state.productDetail.apiMsg.status = action.meta.requestStatus;
            toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                position: toast.POSITION.BOTTOM_CENTER,
                toastId: "dept-error"
            });
        });
        //   productPurchaseOrderAction end




    },
});

export const { RESET, ResetPurchaseOrderDetailsState,ResetPurchaseOrderBarcodeState } = purchaseOrderReducer.actions;
export default purchaseOrderReducer.reducer;
