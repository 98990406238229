import React from "react";
import { Routes, Route, Navigate, Switch } from "react-router-dom";
import WithLoading from "./app/components/shared/WithLoading";

const LoginPage = WithLoading(React.lazy(() => import("./app/components/Login")));

const AuthRoutes = () => {

    return (
        <Routes>
           
            <Route path="/auth" >
                <Route path="login" element={<LoginPage />} />
            </Route>
            <Route path="*" element={<LoginPage />} />

        </Routes>
    );


};

export default AuthRoutes;
