import { createSlice } from "@reduxjs/toolkit";

import { getAllPOSUserAction, insertUpdatePosUserAction, getPOSUserAction, getPOSUserGroupAction, getAllActiveTillAction, activeInactiveAction, getPosUserComparisonAction, POSUserApproveAction, POSUserRejectAction } from "../../actions/shop-settings/pOSUserActions";



import { toast } from 'react-toastify';

const INITIAL_STATE = {
    posUser: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    insertUpdateDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    selectedPosUser: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    posUserGroup: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    activeTillData: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    activeInactiveDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    selectedPosUserComparsion: {
        data: {
            records: null,
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    approveDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        }
    },
    rejectDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        }
    }
};

const posUserReducer = createSlice({
    name: "POSUser",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetPOSUserDetailsState(state, action) {
            state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
            state.selectedPosUser = INITIAL_STATE.selectedPosUser;
            state.approveDetails = INITIAL_STATE.approveDetails;
            state.rejectDetails = INITIAL_STATE.rejectDetails;
        },
    },
    extraReducers: (builder) => {

        // get all posUser action start


        builder.addCase(getAllPOSUserAction.fulfilled, (state, action) => {
            state.posUser.data.records = action.payload.data;
            state.posUser.apiMsg.status = action.meta.requestStatus;
            state.posUser.apiMsg.message = "success";
            // not working above  line show error
        });
        builder.addCase(getAllPOSUserAction.pending, (state, action) => {
            state.posUser.data.records = null;
            state.posUser.apiMsg.message = action.meta.requestStatus;
            state.posUser.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllPOSUserAction.rejected, (state, action) => {
            state.posUser.apiMsg.message = action.error.message;
            state.posUser.apiMsg.status = action.meta.requestStatus;
        });

        // get all posUser action end

        // insert update posUser Action start

        builder.addCase(
            insertUpdatePosUserAction.fulfilled,
            (state, action) => {

                state.insertUpdateDetails.data = action.payload.data.data;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateDetails.apiMsg.message = action.payload.message.message;

                if (!action.payload.newRecord) {
                    let updatedRecord = state.posUser.data.records.map(x => {
                        if (x.id == action.payload.data.data.id) {
                            return action.payload.data.data;
                        }
                        else {
                            return x;
                        }
                    })

                    state.posUser.data.records = updatedRecord;
                }
                else {
                    if (state.posUser.data.records.length) {
                        state.posUser.data.records.unshift(action.payload.data.data);
                    }
                    else {
                        state.posUser.data.records.push(action.payload.data.data);
                    }
                }
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });

            }
        );
        builder.addCase(
            insertUpdatePosUserAction.pending,
            (state, action) => {
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdatePosUserAction.rejected,
            (state, action) => {

                console.log(action.error.message);
                state.insertUpdateDetails.apiMsg.message = action.error.message;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );

        //   insert update posUser action end

        //------------------------------- getposUser start -------------------------

        builder.addCase(getPOSUserAction.fulfilled, (state, action) => {
            state.selectedPosUser.data = action.payload.data;
            state.selectedPosUser.apiMsg.status = action.meta.requestStatus;
            state.selectedPosUser.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getPOSUserAction.pending, (state, action) => {
            state.selectedPosUser.data = null;
            state.selectedPosUser.apiMsg.message = action.meta.requestStatus;;
            state.selectedPosUser.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getPOSUserAction.rejected, (state, action) => {
            state.selectedPosUser.apiMsg.message = action.error.message;
            state.selectedPosUser.apiMsg.status = action.meta.requestStatus;
        });


        //   get posUser end


        //   get posUserGroup start


        builder.addCase(getPOSUserGroupAction.fulfilled, (state, action) => {
            state.posUserGroup.data = action.payload.data;
            state.posUserGroup.apiMsg.status = action.meta.requestStatus;
            state.posUserGroup.apiMsg.message = "success";

        });
        builder.addCase(getPOSUserGroupAction.pending, (state, action) => {
            state.posUserGroup.data = null;
            state.posUserGroup.apiMsg.message = action.meta.requestStatus;
            state.posUserGroup.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getPOSUserGroupAction.rejected, (state, action) => {
            state.posUserGroup.apiMsg.message = action.error.message;
            state.posUserGroup.apiMsg.status = action.meta.requestStatus;
        });


        //   get posUserGroup end

        // get all till active action start


        builder.addCase(getAllActiveTillAction.fulfilled, (state, action) => {

            state.activeTillData.data.records = action.payload.data;
            state.activeTillData.apiMsg.status = action.meta.requestStatus;
            state.activeTillData.apiMsg.message = "success";

        });
        builder.addCase(getAllActiveTillAction.pending, (state, action) => {

            state.activeTillData.data.records = null;
            state.activeTillData.apiMsg.message = action.meta.requestStatus;
            state.activeTillData.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllActiveTillAction.rejected, (state, action) => {

            state.activeTillData.apiMsg.message = action.error.message;
            state.activeTillData.apiMsg.status = action.meta.requestStatus;
        });

        // get all till action end

        //------------------------------- activeInactiveAction start -------------------------

        builder.addCase(
            activeInactiveAction.fulfilled,
            (state, action) => {
                //console.log("activeInactiveAction", action);

                state.activeInactiveDetails.data = action.payload.data.data;
                state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
                state.activeInactiveDetails.apiMsg.message = action.payload.message.message;

                let updatedRecord = state.posUser.data.records.map(x => {
                    if (x.id == action.meta.arg.id) {
                        x.isActive = action.meta.arg.isActive;
                        return x;
                    }
                    else {
                        return x;
                    }
                })



                state.posUser.data.records = updatedRecord;

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            activeInactiveAction.pending,
            (state, action) => {
                state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            activeInactiveAction.rejected,
            (state, action) => {
                state.activeInactiveDetails.apiMsg.message = action.error.message;
                state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "error"
                });
            }
        );

        //------------------------------- activeInactiveAction end -------------------------


        //------------ getPosUserComparisonAction -----------

        builder.addCase(getPosUserComparisonAction.fulfilled, (state, action) => {
            state.selectedPosUserComparsion.data.records = action.payload.data;
            state.selectedPosUserComparsion.apiMsg.status = action.meta.requestStatus;
            state.selectedPosUserComparsion.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getPosUserComparisonAction.pending, (state, action) => {
            //console.log("getPosUserComparisonAction pending", action);
            state.selectedPosUserComparsion.data.records = null;
            state.selectedPosUserComparsion.apiMsg.message = action.meta.requestStatus;;
            state.selectedPosUserComparsion.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getPosUserComparisonAction.rejected, (state, action) => {
            // console.log("getPosUserComparisonAction rejected", action);
            state.selectedPosUserComparsion.apiMsg.message = action.error.message;
            state.selectedPosUserComparsion.apiMsg.status = action.meta.requestStatus;
        });


        //    getPosUserComparisonAction end



        //------------------------------- POSUserApproveAction start -------------------------

        builder.addCase(
            POSUserApproveAction.fulfilled,
            (state, action) => {
                state.approveDetails.data = action.payload.data.data;
                state.approveDetails.apiMsg.status = action.meta.requestStatus;
                state.approveDetails.apiMsg.message = action.payload.message.message;

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            POSUserApproveAction.pending,
            (state, action) => {
                state.approveDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            POSUserApproveAction.rejected,
            (state, action) => {
                state.approveDetails.apiMsg.message = action.error.message;
                state.approveDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "error"
                });
            }
        );

        //------------------------------- POSUserApproveAction end -------------------------

        //------------------------------- POSUserRejectAction start -------------------------

        builder.addCase(
            POSUserRejectAction.fulfilled,
            (state, action) => {

                state.rejectDetails.data = action.payload.data.data;
                state.rejectDetails.apiMsg.status = action.meta.requestStatus;
                state.rejectDetails.apiMsg.message = action.payload.message.message;
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            POSUserRejectAction.pending,
            (state, action) => {
                state.rejectDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            POSUserRejectAction.rejected,
            (state, action) => {
                state.rejectDetails.apiMsg.message = action.error.message;
                state.rejectDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "error"
                });
            }
        );

        //------------------------------- POSUserRejectAction end -------------------------

    }

});
export const { RESET, ResetPOSUserDetailsState } = posUserReducer.actions;
export default posUserReducer.reducer;