import { combineReducers } from 'redux'
import authReducer from "./authReducer";
import masterDataReducer from "./masterDataReducer";
import productReducer from './inventory/productReducer';
import departmentReducer from './inventory/departmentReducer';
import subDepartmentReducer from './inventory/subDepartmentReducer';
import brandReducer from './inventory/brandReducer';
import productGroupReducer from './inventory/productGroupReducer';
import promotionReducer from './promotion/promotionReducer';
import discountReducer from './promotion/discountReducer';
import conditionalVoucherReducer from './promotion/conditionalVoucherReducer';
import staffDiscountReducer from './promotion/staffDiscountReducer'
import vatReducer from './shop-settings/vatReducer';
import tillReducer from './shop-settings/TillReducer';
import posUserReducer from './shop-settings/POSUserReducer';
import posUserGroupReducer from './shop-settings/POSUserGroupReducer';
import posParameterReducer from './shop-settings/posParameterReducer';
import headofficeUserRolesReducer from './manage-settings/headofficeUserRolesReducer';
import headofficeUsersReducer from './manage-settings/headofficeUsersReducer';
import headofficeSettingsReducer from './manage-settings/headofficeSettingsReducer';
import manualPriceProductReducer from './inventory/manualPriceProductReducer';
import manualPriceProductGroupReducer from './inventory/manualPriceProductGroupReducer';
import dynamicBarcodeProductReducer from './inventory/dynamicBarcodeProductReducer';
import reducedPriceProductReducer from './inventory/reducedPriceProductReducer';
import payInOutReducer from './inventory/payInOutReducer';
import customerReducer from './customer/customerReducer';
import supplierReducer from './supplier/supplierReducer';
import supplierProductReducer from './supplier/supplierProductReducer';
import promotionForShopReducer from './supplier/promotionForShopReducer';
import stockAdustementsReducer from './stock-out-manage/stockAdustementsReducer';
import stockReturnToSupplierReducer from './stock-out-manage/stockReturnToSupplierReducer';
import stockTransferToOtherShopReducer from './stock-out-manage/stockTransferToOtherShopReducer';
import stockCountReducer from './stock-out-manage/stockCountReducer';
import purchaseOrderReducer from './purchase-orders/purchaseOrderReducer';
import newspapersReducer from './inventory/newspapersReducer';
import magazineReducer from './inventory/magazineReducer';
import stockInReducer from './stock-in/stockInReducer';
import assistedOrderReducer from './purchase-orders/assistedOrderReducer';

const reducer = {
  auth: authReducer,
  masterDataReducer,
  departmentReducer,
  subDepartmentReducer,
  brandReducer,
  productGroupReducer,
  promotionReducer,
  discountReducer,
  staffDiscountReducer,
  conditionalVoucherReducer,
  vatReducer,
  posParameterReducer,
  tillReducer,
  posUserReducer,
  posUserGroupReducer,
  headofficeUserRolesReducer,
  headofficeUsersReducer,
  headofficeSettingsReducer,
  productReducer,
  manualPriceProductReducer,
  manualPriceProductGroupReducer,
  dynamicBarcodeProductReducer,
  reducedPriceProductReducer,
  payInOutReducer,
  customerReducer,
  supplierReducer,
  supplierProductReducer,
  promotionForShopReducer,
  stockAdustementsReducer,
  stockReturnToSupplierReducer,
  stockTransferToOtherShopReducer,
  stockCountReducer,
  purchaseOrderReducer,
  newspapersReducer,
  magazineReducer,
  stockInReducer,
  assistedOrderReducer
}

const appReducer = combineReducers(reducer);


const rootReducer = (state, action) => {
  //console.log("state",state);
  // console.log("action",action);
  if (action.type === 'auth/logout/fulfilled') {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}


export default rootReducer;
// export default combineReducers(reducer);

export function CLEARSTORE() {
  return {
    type: "CLEARSTORE"
  };
}