import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";

export const getAllActiveBusinessData = createAsyncThunk(
    "assistedOrder/business",
    async (data) => {
  
      return apiCall(`business/getactive`, "GET", {}, false)
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.hasError) {
              return {
                ...response.data,
              }
            } else {
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
        })
        .catch((error) => {
          return Promise.reject({
            ...error,
          });
        });
    }
  );