import { createSlice } from "@reduxjs/toolkit";

import {
  getDynamicBarcodeProductAction, insertUpdateDynamicBarcodeProductAction, getDynamicProductAction, activeInactiveAction, getDynamicBarcodeComparisonAction, dynamicBarcodeApproveAction, dynamicBarcodeRejectAction
} from "../../actions/inventory/dynamicBarcodeProductActions";

import { toast } from 'react-toastify';

const INITIAL_STATE = {
  dynamicBarcodeProduct: {
    data: {
      records: [],
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },

  insertUpdateDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
    newRecord: false
  },
  selectedDynamicProduct: {
    data: null,
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  activeInactiveDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
    newRecord: false
  },

  selectedDynamicBarcodeComparsion: {
    data: {
      records: null,
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  approveDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    }
  },
  rejectDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    }
  }
}

const dynamicBarcodeProductReducer = createSlice({
  name: "DynamicBarcodeProduct",
  initialState: INITIAL_STATE,
  reducers: {
    RESET(state, action) {
      state = INITIAL_STATE;
    },
    ResetDynamicBarcodeProductDetailsState(state, action) {
      state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
      state.selectedDynamicProduct = INITIAL_STATE.selectedDynamicProduct;
      state.approveDetails = INITIAL_STATE.approveDetails;
      state.rejectDetails = INITIAL_STATE.rejectDetails;
    },
  },
  extraReducers: (builder) => {

    builder.addCase(getDynamicBarcodeProductAction.fulfilled, (state, action) => {
      state.dynamicBarcodeProduct.data.records = action.payload.data;
      state.dynamicBarcodeProduct.apiMsg.status = action.meta.requestStatus;
      state.dynamicBarcodeProduct.apiMsg.message = action.payload.message.message;
    });
    builder.addCase(getDynamicBarcodeProductAction.pending, (state, action) => {
      state.dynamicBarcodeProduct.data.records = null;
      state.dynamicBarcodeProduct.apiMsg.message = action.meta.requestStatus;;
      state.dynamicBarcodeProduct.apiMsg.status = action.meta.requestStatus;
    });
    builder.addCase(getDynamicBarcodeProductAction.rejected, (state, action) => {
      console.log("getDynamicBarcodeProductAction rejected", action);
      state.dynamicBarcodeProduct.apiMsg.message = action.error.message;
      state.dynamicBarcodeProduct.apiMsg.status = action.meta.requestStatus;
      toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
        position: toast.POSITION.BOTTOM_CENTER,
        toastId: "dept-error"
      });
    });

    // insert update posusergroup Action start

    builder.addCase(
      insertUpdateDynamicBarcodeProductAction.fulfilled,
      (state, action) => {

        state.insertUpdateDetails.data = action.payload.data.data;
        state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
        state.insertUpdateDetails.apiMsg.message = action.payload.message.message;

        if (!action.payload.newRecord) {
          let updatedRecord = state.dynamicBarcodeProduct.data.records.map(x => {
            if (x.id == action.payload.data.data.id) {
              return action.payload.data.data;
            }
            else {
              return x;
            }
          })

          state.dynamicBarcodeProduct.data.records = updatedRecord;
        }
        else {
          if (state.dynamicBarcodeProduct.data.records.length) {
            state.dynamicBarcodeProduct.data.records.unshift(action.payload.data.data);
          }
          else {
            state.dynamicBarcodeProduct.data.records.push(action.payload.data.data);
          }
        }
        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });

      }
    );
    builder.addCase(
      insertUpdateDynamicBarcodeProductAction.pending,
      (state, action) => {
        //console.log("insertUpdatePOSUserGroupAction pending", action);
        state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      insertUpdateDynamicBarcodeProductAction.rejected,
      (state, action) => {
        //   console.log("insertUpdatePOSUserGroupAction rejected", action);
        state.insertUpdateDetails.apiMsg.message = action.error.message;
        state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "dept-error"
        });
      }
    );


    //   insert update insertUpdatePOSUserGroupAction action end




    //------------------------------- getdynamicbarcode start -------------------------

    builder.addCase(getDynamicProductAction.fulfilled, (state, action) => {
      state.selectedDynamicProduct.data = action.payload.data;
      state.selectedDynamicProduct.apiMsg.status = action.meta.requestStatus;
      state.selectedDynamicProduct.apiMsg.message = action.payload.message.message;
    });

    builder.addCase(getDynamicProductAction.pending, (state, action) => {
      //console.log("getDynamicProductAction pending", action);
      state.selectedDynamicProduct.data = null;
      state.selectedDynamicProduct.apiMsg.message = action.meta.requestStatus;;
      state.selectedDynamicProduct.apiMsg.status = action.meta.requestStatus;
    });

    builder.addCase(getDynamicProductAction.rejected, (state, action) => {
      // console.log("getDynamicProductAction rejected", action);
      state.selectedDynamicProduct.apiMsg.message = action.error.message;
      state.selectedDynamicProduct.apiMsg.status = action.meta.requestStatus;
    });


    //   get dynamicbarcode end


    //------------------------------- activeInactiveAction start -------------------------

    builder.addCase(
      activeInactiveAction.fulfilled,
      (state, action) => {
        //console.log("activeInactiveAction", action);

        state.activeInactiveDetails.data = action.payload.data.data;
        state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
        state.activeInactiveDetails.apiMsg.message = action.payload.message.message;

        let updatedRecord = state.dynamicBarcodeProduct.data.records.map(x => {
          if (x.id == action.meta.arg.id) {
            x.isActive = action.meta.arg.isActive;
            return x;
          }
          else {
            return x;
          }
        })



        state.dynamicBarcodeProduct.data.records = updatedRecord;

        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "success"
        });

      }
    );
    builder.addCase(
      activeInactiveAction.pending,
      (state, action) => {
        state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      activeInactiveAction.rejected,
      (state, action) => {
        state.activeInactiveDetails.apiMsg.message = action.error.message;
        state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "error"
        });
      }
    );

    //------------------------------- activeInactiveAction end -------------------------


    //------------ getDynamicBarcodeComparisonAction -----------

    builder.addCase(getDynamicBarcodeComparisonAction.fulfilled, (state, action) => {
      state.selectedDynamicBarcodeComparsion.data.records = action.payload.data;
      state.selectedDynamicBarcodeComparsion.apiMsg.status = action.meta.requestStatus;
      state.selectedDynamicBarcodeComparsion.apiMsg.message = action.payload.message.message;
    });

    builder.addCase(getDynamicBarcodeComparisonAction.pending, (state, action) => {
      //console.log("getDynamicBarcodeComparisonAction pending", action);
      state.selectedDynamicBarcodeComparsion.data.records = null;
      state.selectedDynamicBarcodeComparsion.apiMsg.message = action.meta.requestStatus;;
      state.selectedDynamicBarcodeComparsion.apiMsg.status = action.meta.requestStatus;
    });

    builder.addCase(getDynamicBarcodeComparisonAction.rejected, (state, action) => {
      // console.log("getDynamicBarcodeComparisonAction rejected", action);
      state.selectedDynamicBarcodeComparsion.apiMsg.message = action.error.message;
      state.selectedDynamicBarcodeComparsion.apiMsg.status = action.meta.requestStatus;
    });


    //   get getDynamicBarcodeComparisonAction end



    //------------------------------- dynamicBarcodeApproveAction start -------------------------

    builder.addCase(
      dynamicBarcodeApproveAction.fulfilled,
      (state, action) => {

        state.approveDetails.data = action.payload.data.data;
        state.approveDetails.apiMsg.status = action.meta.requestStatus;
        state.approveDetails.apiMsg.message = action.payload.message.message;

        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "success"
        });

      }
    );
    builder.addCase(
      dynamicBarcodeApproveAction.pending,
      (state, action) => {
        state.approveDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      dynamicBarcodeApproveAction.rejected,
      (state, action) => {
        state.approveDetails.apiMsg.message = action.error.message;
        state.approveDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "error"
        });
      }
    );

    //------------------------------- dynamicBarcodeApproveAction end -------------------------

    //------------------------------- dynamicBarcodeRejectAction start -------------------------

    builder.addCase(
      dynamicBarcodeRejectAction.fulfilled,
      (state, action) => {

        state.rejectDetails.data = action.payload.data.data;
        state.rejectDetails.apiMsg.status = action.meta.requestStatus;
        state.rejectDetails.apiMsg.message = action.payload.message.message;

        toast.success(action.payload.message.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "success"
        });

      }
    );
    builder.addCase(
      dynamicBarcodeRejectAction.pending,
      (state, action) => {
        state.rejectDetails.apiMsg.status = action.meta.requestStatus;
      }
    );
    builder.addCase(
      dynamicBarcodeRejectAction.rejected,
      (state, action) => {
        state.rejectDetails.apiMsg.message = action.error.message;
        state.rejectDetails.apiMsg.status = action.meta.requestStatus;

        toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          toastId: "error"
        });
      }
    );

    //------------------------------- dynamicBarcodeRejectAction end -------------------------



  },
});
export const { RESET, ResetDynamicBarcodeProductDetailsState } = dynamicBarcodeProductReducer.actions;
export default dynamicBarcodeProductReducer.reducer;

