import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";

export const getAllStockInAction = createAsyncThunk(
    "StockIn/GetAllStockIn",
    async (data) => {
        return apiCall("stockin/getall", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);
export const getStockInAction = createAsyncThunk(
    "StockIn/GetStockIn",
    async (data) => {
        return apiCall("stockin/getstockIn", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);
export const insertUpdateStockInAction = createAsyncThunk(
    "StockIn/insertupdatstockin",
    async (data) => {
      return apiCall("stockin/savestockin", "POST", data, false)
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.hasError) {
              let message = response.data.message
              return {
                data: {
                  ...response.data,
                },
                message: {
                  code: response.data.message.code,
                  type: response.data.message.type,
                  message: response.data.message.message
                },
                newRecord: data.id ? false : true
              }
            } else {
              if (!response.data.message && response.data.errors && response.data.errors.length) {
                response.data.message = response.data.errors[0];
              }
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
          else {
            return Promise.reject({
              ...response.data,
            });
          }
        })
        .catch((error) => {
          return Promise.reject({
            ...error,
          });
        });
    }
  );
export const getAllActiveBusinessData = createAsyncThunk(
    "StockIn/businesses",
    async (data) => {
        return apiCall("business/GetActive", "GET", {}, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);
export const getAllPurchaseOrderData = createAsyncThunk(
    "StockIn/order",
    async (data) => {
        return apiCall("purchaseorder/getallpurchaseorder", "POST", {}, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);
export const getStockInProductByBarcodeAction = createAsyncThunk(
    "StockIn/GetStockInProductBarcode",
    async (data) => {
        return apiCall("stockin/getproductstockinbybarcode", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);