import { getMagazinesAction, getFrequencyTypeAction, getPriceGroupProductAction, insertUpdateMagazineAction, activeInactiveAction, getMagazineComparisonAction, magazineApproveAction, magazineRejectAction } from "../../actions/inventory/magazineAction";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';

const INITIAL_STATE = {
    selectedMagazine: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedPriceGroup: {
        data: {
            records: null,
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedFrequencyType: {
        data: {
            records: null,
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    activeInactiveDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    selectedMagazineComparsion: {
        data: {
            records: null,
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    approveDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        }
    },
    rejectDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        }
    }

};


const magazineReducer = createSlice({
    name: "Magazine",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetMagazineDetailsState(state, action) {
            state.selectedMagazine = INITIAL_STATE.selectedMagazine;
            state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
            state.approveDetails = INITIAL_STATE.approveDetails;
            state.rejectDetails = INITIAL_STATE.rejectDetails;
        },
    },
    extraReducers: (builder) => {
        //------------ getMagazinesAction -----------

        builder.addCase(getMagazinesAction.fulfilled, (state, action) => {
            state.selectedMagazine.data = action.payload.data;
            state.selectedMagazine.apiMsg.status = action.meta.requestStatus;
            state.selectedMagazine.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getMagazinesAction.pending, (state, action) => {
            state.selectedMagazine.data = null;
            state.selectedMagazine.apiMsg.message = action.meta.requestStatus;;
            state.selectedMagazine.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getMagazinesAction.rejected, (state, action) => {
            state.selectedMagazine.apiMsg.message = action.error.message;
            state.selectedMagazine.apiMsg.status = action.meta.requestStatus;
        });
        //    getMagazinesAction end

        //------------ getPriceGroupProductAction -----------

        builder.addCase(getPriceGroupProductAction.fulfilled, (state, action) => {
            state.selectedPriceGroup.data.records = action.payload.data;
            state.selectedPriceGroup.apiMsg.status = action.meta.requestStatus;
            state.selectedPriceGroup.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getPriceGroupProductAction.pending, (state, action) => {
            state.selectedPriceGroup.data.records = null;
            state.selectedPriceGroup.apiMsg.message = action.meta.requestStatus;;
            state.selectedPriceGroup.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getPriceGroupProductAction.rejected, (state, action) => {
            state.selectedPriceGroup.apiMsg.message = action.error.message;
            state.selectedPriceGroup.apiMsg.status = action.meta.requestStatus;
        });


        //   get pricegroup end

        //------------ getFrequencyTypeAction -----------

        builder.addCase(getFrequencyTypeAction.fulfilled, (state, action) => {
            state.selectedFrequencyType.data.records = action.payload.data;
            state.selectedFrequencyType.apiMsg.status = action.meta.requestStatus;
            state.selectedFrequencyType.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getFrequencyTypeAction.pending, (state, action) => {
            state.selectedFrequencyType.data.records = null;
            state.selectedFrequencyType.apiMsg.message = action.meta.requestStatus;;
            state.selectedFrequencyType.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getFrequencyTypeAction.rejected, (state, action) => {
            state.selectedFrequencyType.apiMsg.message = action.error.message;
            state.selectedFrequencyType.apiMsg.status = action.meta.requestStatus;
        });
        //   getFrequencyTypeAction end


        // insertUpdateMagazineAction start

        builder.addCase(
            insertUpdateMagazineAction.fulfilled,
            (state, action) => {
                state.insertUpdateDetails.data = action.payload.data.data;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateDetails.apiMsg.message = action.payload.message.message;

                //   if (!action.payload.newRecord) {
                //     let updatedRecord = state.manualPriceProduct.data.records.map(x => {
                //       if (x.id == action.payload.data.data.id) {
                //         return action.payload.data.data;
                //       }
                //       else {
                //         return x;
                //       }
                //     })

                //     state.manualPriceProduct.data.records = updatedRecord;
                //   }
                //   else {
                //     if (state.manualPriceProduct.data.records.length) {
                //       state.manualPriceProduct.data.records.unshift(action.payload.data.data);
                //     }
                //     else {
                //       state.manualPriceProduct.data.records.push(action.payload.data.data);
                //     }
                //   }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );
        builder.addCase(
            insertUpdateMagazineAction.pending,
            (state, action) => {
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateMagazineAction.rejected,
            (state, action) => {
                state.insertUpdateDetails.apiMsg.message = action.error.message;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );


        //   insertUpdateMagazineAction  end


        //------------------------------- activeInactiveAction start -------------------------

        builder.addCase(
            activeInactiveAction.fulfilled,
            (state, action) => {
                state.activeInactiveDetails.data = action.payload.data.data;
                state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
                state.activeInactiveDetails.apiMsg.message = action.payload.message.message;

                // let updatedRecord = state.newspaper.data.records.map(x => {
                //     if (x.id == action.meta.arg.id) {
                //         x.isActive = action.meta.arg.isActive;
                //         return x;
                //     }
                //     else {
                //         return x;
                //     }
                // })
                // state.newspaper.data.records = updatedRecord;

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            activeInactiveAction.pending,
            (state, action) => {
                state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            activeInactiveAction.rejected,
            (state, action) => {
                state.activeInactiveDetails.apiMsg.message = action.error.message;
                state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "error"
                });
            }
        );

        //------------------------------- activeInactiveAction end -------------------------

        //------------ getMagazineComparisonAction -----------

        builder.addCase(getMagazineComparisonAction.fulfilled, (state, action) => {
            state.selectedMagazineComparsion.data.records = action.payload.data;
            state.selectedMagazineComparsion.apiMsg.status = action.meta.requestStatus;
            state.selectedMagazineComparsion.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getMagazineComparisonAction.pending, (state, action) => {
            state.selectedMagazineComparsion.data.records = null;
            state.selectedMagazineComparsion.apiMsg.message = action.meta.requestStatus;;
            state.selectedMagazineComparsion.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getMagazineComparisonAction.rejected, (state, action) => {
            state.selectedMagazineComparsion.apiMsg.message = action.error.message;
            state.selectedMagazineComparsion.apiMsg.status = action.meta.requestStatus;
        });


        //    getMagazineComparisonAction end



        //------------------------------- magazineApproveAction start -------------------------

        builder.addCase(
            magazineApproveAction.fulfilled,
            (state, action) => {

                state.approveDetails.data = action.payload.data.data;
                state.approveDetails.apiMsg.status = action.meta.requestStatus;
                state.approveDetails.apiMsg.message = action.payload.message.message;

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            magazineApproveAction.pending,
            (state, action) => {
                state.approveDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            magazineApproveAction.rejected,
            (state, action) => {
                state.approveDetails.apiMsg.message = action.error.message;
                state.approveDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "error"
                });
            }
        );

        //------------------------------- magazineApproveAction end -------------------------

        //------------------------------- magazineRejectAction start -------------------------

        builder.addCase(
            magazineRejectAction.fulfilled,
            (state, action) => {

                state.rejectDetails.data = action.payload.data.data;
                state.rejectDetails.apiMsg.status = action.meta.requestStatus;
                state.rejectDetails.apiMsg.message = action.payload.message.message;

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            magazineRejectAction.pending,
            (state, action) => {
                state.rejectDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            magazineRejectAction.rejected,
            (state, action) => {
                state.rejectDetails.apiMsg.message = action.error.message;
                state.rejectDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "error"
                });
            }
        );

        //------------------------------- magazineRejectAction end -------------------------

    },
});

export const { RESET, ResetMagazineDetailsState } = magazineReducer.actions;
export default magazineReducer.reducer;
